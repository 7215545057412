<template>
    <div class="order-list-item" @click="$emit('click', item)">
        <div class="order-list-item-info">
            <div class="order-list-item-title">
                {{item.znumber + ' от ' + item.time}}
            </div>
            <div class="order-list-item-status">
                <div :style="{'font-size':'14px','color':item.status_color}">
                    {{item.status_text}}
                </div>
                <div :class="{'text-ok':item.paid==1,'text-err':item.paid==0}"
                style="font-size:14px;margin-top:5px" >
                    {{item.paid == 1 ? 'Оплачен' : 'Не оплачен'}}
                </div>
            </div>
        </div>
        <div class="order-list-item-sum">
            {{item.sum + 'руб'}}
        </div>
    </div>
</template>

<script>
export default {
    props: ['item']
    
}
</script>

<style scoped>
.order-list-item {
    padding: 15px 0 10px;
    border-bottom: 1px solid #C1D0E1;
    margin: 0 10px;
}

.order-list-item-info {
    display: flex;
    flex-direction: row;
}

.order-list-item-title {
    color:#506883;
    font-size:14px;
    margin-bottom:10px;
}

.order-list-item-status {
    text-align: right;
    white-space: nowrap;
}

.order-list-item-sum {
    color:#333;
    font-size:14px; 
}
</style>