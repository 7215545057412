<template>
    <div class="mb-20px">
        <div class="mb-10px" >
            Дата доставки
        </div>
        <div class="checkbox-item mb-10px" @click="onTapDeliveryTime">
            <img src="../assets/icon-date-0.png" />
            <span>
                {{dateText}}
            </span>
        </div>
        <div class="mb-10px" >
            Время доставки
        </div>
        <div class="checkbox-item mb-20px" @click="onTapDeliveryTime">
            <img src="../assets/icon-time_gotov-0.png" />
            <span>
                {{timeText}}
            </span>
        </div>
        <div class="mb-5px">
            Адрес доставки
        </div>
        <div class="mb-10px">
            <input class="input-text-sm" :value="$store.state.curAddress.full" disabled/>
        </div>
        <div class="mb-20px row-grow-1">
            <div style="margin: 0 5px 0 0">
                <div class="text-sm mb-5px">Кв./оф.</div>
                <input type="text" class="input-text-sm" v-model="$store.state.curAddress.room">
            </div>
            <div style="margin: 0 5px">
                <div class="text-sm mb-5px">Домофон</div>
                <input type="text" class="input-text-sm" v-model="$store.state.curAddress.domofon">
            </div>
            <div style="margin: 0 5px">
                <div class="text-sm mb-5px">Подъезд</div>
                <input type="text" class="input-text-sm" v-model="$store.state.curAddress.podjezd">
            </div>
            <div style="margin: 0 0 0 5px">
                <div class="text-sm mb-5px">Этаж</div>
                <input type="text" class="input-text-sm" v-model="$store.state.curAddress.etaz">
            </div>
        </div>

        <!-- <CartPriborCnt v-model="pribors" class="mb-20px"/> -->

        <div class="mb-5px">
            Комментарий к заказу
        </div>
        <div class="mb-10px">
            <textarea class="input-text" v-model="$store.state.curAddress.comment" style="height:100px;padding:10px"></textarea>
        </div>
    </div>
</template>

<script>
// import CartPriborCnt from '../components/CartPriborCnt';
export default {
    // components: {CartPriborCnt},
    data(){
        return {
            pribors: 0
        }
    },
    computed: {
        currentDate(){
            return this.dateFormat(new Date());
        },
        dateText(){
            if(this.currentDate == this.$store.state.deliveryDateTimeProps.deliveryDate
            || !this.$store.state.deliveryDateTimeProps.deliveryDate)
            return 'Сегодня';
            else
            return this.$store.state.deliveryDateTimeProps.deliveryDate;
        },
        timeText(){
            return this.$store.state.deliveryDateTimeProps.deliveryTime?this.$store.state.deliveryDateTimeProps.deliveryTime:'По готовоности';
        },
        resultData(){
            return {
                address: {
                    full: this.$store.state.curAddress.full,
                    short: this.$store.state.curAddress.short,
                    long: this.$store.state.curAddress.long,
                    street: this.$store.state.curAddress.street,
                    house: this.$store.state.curAddress.house,
                    room: this.$store.state.curAddress.room,
                    domofon: this.$store.state.curAddress.domofon,
                    comment: this.$store.state.curAddress.comment,
                    podjezd: this.$store.state.curAddress.podjezd,
                    etaz: this.$store.state.curAddress.etaz,
                    location: {
                        lat: this.$store.state.curAddress.lat,
                        lon: this.$store.state.curAddress.lon
                    }
                },
                deliveryTime: this.$store.state.deliveryDateTimeProps.deliveryTime,
                deliveryDate: this.$store.state.deliveryDateTimeProps.deliveryDate,
                comment: this.$store.state.curAddress.comment,
                pribors: this.pribors
            }
        }
    },
    methods: {
        emitChanges(){
            this.$emit('change',this.resultData)
        },
        onTapDeliveryTime(){
            this.$router.push('/delivery_time')
        }
    },
    created(){

        // console.log('this is a component')
        // this.getCurrentDate();
        // var date = new Date();
        // this.minutes = date.getMinutes() + date.getHours()*60;
        // setInterval(()=>{
        //     var date = new Date();
        //     this.minutes = date.getMinutes() + date.getHours()*60;
        // }, 1000 * 60)

        this.$emit('change',this.resultData)

        this.$watch(()=>this.$store.state.curAddress.room, ()=>this.emitChanges());
        this.$watch(()=>this.$store.state.curAddress.domofon, ()=>this.emitChanges());
        this.$watch(()=>this.$store.state.curAddress.podjezd, ()=>this.emitChanges());
        this.$watch(()=>this.$store.state.curAddress.etaz, ()=>this.emitChanges());
        this.$watch(()=>this.pribors, ()=>this.emitChanges());
        this.$watch(()=>this.resultData, ()=>this.emitChanges());
        this.$watch(()=>this.$store.state.curAddress.comment, ()=>this.emitChanges());
    }
}
</script>