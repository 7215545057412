<template>
    <div class="action-bar">
        <button v-show="showMenu" class="btn--icon-menu" @click="$store.state.showDrawer = true">
            <img src="../assets/icon-menu.png"/>
        </button>
        <button @click="goBack" v-show="showBack && !showMenu" class="btn--icon-back">
            <img src="../assets/icon-back.png" width="12" margin="12" />
        </button>
        <div class="c-1">
			<div class="c-2">
				<img src="../assets/icon-logo.png" v-if="showLogo" @click="onTapLogo" class="c-3" />
				<div class="c-4" @click="onTapFavorite">
                    <i class="far fa-heart" v-show="isFavorite==1"></i>
                    <i class="fas fa-heart" v-show="isFavorite==2"></i>
				</div>
			</div>
			<span :class="{'title-small':showLogo}" @click="onTapLogo" class="c-5">
                {{ title }}
            </span>
		</div>
        <div v-show="rightBtn" @click="onTapRight" style="width:40px;text-align:center;margin-left:auto;">
            <i :class="rightBtn"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        backPath: {
            type: String,
            default: ''
        },
        showMenu: {
            type: Boolean,
            default: false
        },
        showBack: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        showLogo: {
            type: Boolean,
            default: false
        },
        rightBtn: {
            type: String,
            default: ''
        }
    },
    computed:{
        isFavorite(){

            const index = this.$store.state.favorite_restorans.findIndex(item => item.fs_uid == this.$store.state.restoran.fs_uid);
            return index < 0 ? 1 : 2;
            // return 1;
        },
    },
    methods: {
        onTapFavorite(){
            this.$store.commit('toggleFavoriteRestorans',this.$store.state.restoran);
            setTimeout(()=>{
                if(this.isFavorite == 1){
                    this.showDialog({ 
                        title: `Ресторан \n`+this.$store.state.restoran.name,
                        message: 'Удален из любимых',
                        items: ['Ok']
                    })
                }else{
                    this.showDialog({ 
                        title: `Ресторан \n`+this.$store.state.restoran.name,
                        message: 'Добавлен в любимые',
                        items: ['Ok']
                    })
                }
            },300)
        },
        goBack(){
            if(this.backPath.length > 0)
                this.$router.push(this.backPath);
            else
                this.$router.go(-1);
        },
        onTapLogo(){
            this.$router.push('/');
        },
        onTapRight(){
            this.$emit('tapRight')
        }
    }
}
</script>

<style scoped>
.action-bar {
    text-align: center;
    background-color: #0071E5;
    color: #fff;
    height: 60px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.btn--icon-menu {
    width: 42px;
    align-self: stretch;
}

.btn--icon-menu img {
    width: 18px;
}

.btn--icon-back {
    width: 40px;
    align-self: stretch;
}

.title-small {
	font-size: 12px; 
    text-transform: uppercase;
}

.action-bar--logo img {
    height: 30px;
}

.action-bar--logo {
    margin: 0 5px 0 0;
}

.action-bar--title {
    
}

.c-1 {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.c-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 60px 0 0;
}

.c-3 {
    height: 30px;
    margin: 0 10px 7px 32px;
}

.c-4 {
    font-size: 22px;
    color: #fff;
    margin: 0 0 5px 0;
}

.c-5 {
    margin: 0 60px 0 0;
}
</style>