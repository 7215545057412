<template>
    <div>
        <input type="tel" :value="value"
            placeholder="+7 ___ ___ __ __"
            :class="{'input-light':!inputText,'input-text':inputText}"
            v-mask="'+7 ### ### ## ##'"
            masked="true"
            ref="input"
            @input="onInput"
        >
    </div>
</template>

<script>
import {mask} from 'vue-the-mask'

export default {
    directives: {mask},
    props: ['value','input-text'],
    methods: {
        onInput(e){
            var val = e.target.value.match(/\d+/g);
            val = val.join('');
            this.$emit('input', val)

            if(val.length == 11 && val != this.value){
                this.$emit('filled', val)
                this.$refs.input.blur()
            }
        }
    }
}
</script>

<style scoped>
.input-light,
.input-light::placeholder
{
    color: #fff;
}

.input-light
{
    outline: none;
    border: 0!important;
    padding: 0!important;
    text-align: center;

    font-size: 30px;
    display: block;
    width: 100%;
}

.input-text-phone .input-light {

    border: 2px solid #eee;
    border-radius: 10px;
    color:#333;
    background-color: #fff;
    font-size: 16px;
    padding: 0 10px;
    height:50px;
    width: 100%;
}
</style>