<template>
    <div class="page-cart">
        <TopBar :title="cartTitle" :showBack="true"/>
        <div class="scrollview" style="padding:10px 10px 70px 10px">
            <div v-if="$store.state.settings.text_info" class="text-info">{{$store.state.settings.text_info}}</div>

            <template v-if="$store.state.orderType=='self' || isPredzakaz">
                <div class="box-qr-info">
                    <div class="text-bold text-1">
                        {{$store.state.restoran.name}}
                    </div>
                    <div class="text-2">
                        {{$store.state.restoran.address}}
                    </div>
                    <div class="text-bold text-2" style="margin-top:10px"  v-if="isPredzakaz">
                        {{predzakazText}}
                    </div>
                </div>
            </template>

            <template v-if="$store.state.orderType=='inrest' && !isPredzakaz">
                <div class="box-qr-info">
                    <div class="text-bold text-1">
                        {{$store.state.restoran.name}}
                    </div>
                    <div class="text-2">
                        Стол #{{$store.state.tableId}}
                    </div>
                </div>
            </template>
            <CartItems :items="items" />


            <CartPribors 
                :cartItems="items" 
                :orderType="orderType" 
                :restoranId="$store.state.restoran.fs_uid" 
                v-model="pribors" 
                ref="cartPribors"
            />
            <CartRecommended 
                :cartItems="items" 
                :orderType="orderType" 
                :restoranId="$store.state.restoran.fs_uid" 
                v-model="recommendedItems" 
                ref="cartRecommended"
            />

            <CartSelf v-if="$store.state.orderType=='self'" @change="onChangeDeliveryData"/>

            <CartDelivery v-if="$store.state.orderType=='delivery'" @change="onChangeDeliveryData" @canbuy="changeCanBuy"/>

            <CartPaysystem class="mb-20px" v-model="paysystem" />

            <CartTotal :discountSum="discountSum" :discountPercent="discountPercent" :deliverySum="deliverySum" :orderSum="orderSum" :coupon="coupon" @couponInput="onCouponInput" />

            <CartButtonInner :orderSum="orderSum" :minSum="minSum" :canbuy="canbuy" :isAvailByTime="isAvailByTime" :paysystem="paysystem" :deliverySum="deliverySum" @submit="onSubmit" />
        </div>
        <v-overlay :value="proccess">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '/src/components/TopBar';
import CartItems from '/src/components/CartItems';
import CartTotal from '/src/components/CartTotal';
import CartSelf from '/src/components/CartSelf';
import CartDelivery from '/src/components/CartDelivery';
import CartButtonInner from '/src/components/CartButtonInner';
import CartPaysystem from '/src/components/CartPaysystem';
import CartRecommended from '/src/components/CartRecommended';
import CartPribors from '/src/components/CartPribors';

var cpnTimer = false;

export default {
    components: {
        TopBar,
        CartItems,
        CartTotal,
        CartButtonInner,
        CartSelf,
        CartDelivery,
        CartPaysystem,
        CartRecommended,
        CartPribors
    },
    data() {
        return {
            pribors: [],
            recommendedItems: [],
            emailsend: false,
            proccess: false,
            qrcode: '',
            qrcheck: false,
            deliveryData: {},
            discountPercent: 0,
            discountSum: 0,
            deliverySum: 0,
            comment:'',
            coupon:'',
            deliveryUUID:'',
            isShop: false,
            canbuy: true,
            isAvailByTime : '',
            paysystem: 'card',
            paysystems: [
                {
                    value: 'card',
                    name: 'Картой в приложении',
                    icon: '\uf09d',
                    class: 'far'
                }
            ],
            googlePaySupport: false,
            applePaySupport: false,
            loadingPayment: false,
            waitPayment: false,
            bindings: [],
            card: false,
            email: this.$store.state.user.email,
            hasphone: false,
            selectedCard:{}
        };
    },
    created(){
        var comment = '';
        if(this.$store.state.orderType == 'self'){
            comment = 'С собой';
        }

        this.$store.state.cart.forEach(cart => {
            cart.comment = comment;
        });

        this.loadDiscountData();
    },
    computed: {
        predzakazText(){
            return this.$store.state.deliveryDateTimeProps.deliveryDate + ', ' + this.$store.state.deliveryDateTimeProps.deliveryTime;
        },
        isPredzakaz(){
            if(this.$store.state.orderType == 'inrest'
                && this.$store.state.deliveryDateTimeProps 
                && this.$store.state.deliveryDateTimeProps.deliveryTime
            ){
                return true;
            }

            return false;
        },
        items(){
            let result = [];
            var b = null;
            // this.$store.state.cart;

            this.$store.state.cart.forEach(cart => {
                const index = this.$store.state.dishes.findIndex(dish => dish.id == cart.item.id && !!dish.allowed);

                if(index >= 0){
                    cart.item = this.$store.state.dishes[index];
                    if(cart.specs && cart.specs.length){
                            b = true;
                            cart.specs.forEach(spec => {
                                const spec_index = this.$store.state.dishes[index].spec.findIndex(option => option.specid == spec.specid && !option.disabled);
                                if(spec_index < 0){
                                    b = false;
                                }
                            })
                            if(b){
                                result.push(cart);
                            }
                        }
                        else if(cart.spec && cart.spec.specid){
                            console.log('item specs',JSON.stringify(this.$store.state.dishes[index].spec));
                            const spec_index = this.$store.state.dishes[index].spec.findIndex(item => item.specid == cart.spec.specid && !item.disabled);
                            console.log(JSON.stringify(cart))
console.log('spec', spec_index)
                            if(spec_index >= 0){
                                cart.spec = this.$store.state.dishes[index].spec[spec_index];

                                result.push(cart);
                            }
                        }else{
                            result.push(cart);
                        }
                }
            });

            return result; 
        },
        cartTitle(){
            var result = '';
            switch(this.$store.state.orderType)
            {
                case 'self':
                    result = 'Самовывоз';
                    break;
                case 'delivery':
                    result = 'Доставка курьером';
                    break;
                case 'inrest':
                    if(this.isPredzakaz){
                        result = 'Предзаказ в заведении';
                    }else{
                        result = 'Заказ в заведении';
                    }
            }

            return result;
        },
        minSum(){
            var r = 0;
            if(this.isShop)
            {
                if(this.$store.state.orderType == 'delivery')
                    r = this.$store.state.curZone.minordersum_shop;
                else
                    r = this.$store.state.restoran.minOrderSumShop[this.$store.state.orderType];
            }
            else
            {
                if(this.$store.state.orderType == 'delivery')
                    r =  this.$store.state.curZone.minordersum_rest;
                else
                    r = this.$store.state.restoran.minOrderSum[this.$store.state.orderType];
            }

            if(this.$store.state.orderType == 'inrest' 
                && !!this.$store.state.booking_num
                && this.$store.state.booking_min_sum > r)
            {
                r = this.$store.state.booking_min_sum;
            }

            return r;
        },
        orderType(){
            return this.$store.state.orderType;
        },
        orderSum(){
            var sum = this.computeOrderPrice(this.items);

            console.log('orderSum: ' + sum);
            return sum;
        },
        selectedCardString : function(){
            if(this.selectedCard.item.maskedPan == 'undefined'){
                return null;
            }
            return this.selectedCard.item.bindingId !==-1 ? `Картой ${this.selectedCard.item.maskedPan}` : this.selectedCard.item.maskedPan ;
        }
    },
    methods: {
        updateCartData(){
            this.$refs.cartRecommended.loadData();
            this.$refs.cartPribors.loadData();
        },
        onCouponInput(v){
            this.coupon = v;

            if(cpnTimer)
            {
                clearTimeout(cpnTimer);
                cpnTimer = false;
            }
            cpnTimer = setTimeout(() => {
                this.loadDiscountData();
            }, 1000);
        },
        onSubmit(){
            this.sendOrder(this.deliveryData);
        },
        sendOrder(props){
            if(this.proccess) return;
            this.proccess = true;
            this.$store.commit('saveState');
            fetch(this.$serverUrl + '/api/v2/order/create', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    source: this.$store.state.kiosk?'kiosk':'web',
                    v: 3,
                    user: this.$store.state.user,
                    // cart: this.$store.state.cart,
                    cart: this.items,
                    pribors: this.pribors,
                    recommendedItems: this.recommendedItems,
                    cart_raw: this.$store.state.cart,
                    order: {
                        table: this.$store.state.tableId,
                        type: this.$store.state.orderType,
                        comment: this.comment,
                        // package: this.package,
                        props,
                        booking_num: this.$store.state.booking_num
                    },
                    deliveryDateTimeProps: this.$store.state.deliveryDateTimeProps,
                    phone: this.$store.state.user.phone,
                    emailsend: this.emailsend,
                    email: this.$store.state.user.email,
                    coupon:this.coupon,
                    restoran: this.$store.state.restoran,
                    pushToken: this.$store.state.pushToken
                })
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.success != true)
                {
                    this.proccess = false;
                    this.showDialog({
                        title: "Внимание",
                        message: data.message,
                        items: ['Ok']
                    })
                }
                else if(data.delayed)
                {
                    this.proccess = false;
                    this.showDialog({
                        title: "Внимание",
                        message: data.message,
                        items: ['Ok']
                    }).then(() => {
                        console.log('then')
                        this.clearNotification();
                        this.$store.commit('emptyCart');
                        setTimeout(() => {
                            this.$router.push('/');
                        }, 100)
                    })
                }
                else
                {
                    this.clearNotification();
                    this.$store.commit('emptyCart');
                    
                    this.$router.push({name:'Payment', params: {
                        order: data,
                        orderId: data.id,
                        orderNum: data.number,
                        orderSum: data.sum,
                        paysystems: this.paysystems,
                        paysystem: this.paysystem,
                        card: this.card,
                        bindings: this.bindings,
                        navback: 'Orders'
                    }});
                }
            }).catch(() => {
                this.proccess = false;
            })
        },
        onChangeDeliveryData(args){
            console.log('onChangeDeliveryData',JSON.parse(JSON.stringify(args)))
            if(args.comment){
                this.comment = args.comment
            }
            this.deliveryData = args;
            this.loadDeliveryData();
        },
        changeCanBuy(){

        },
        computeOrderPrice(items){
            console.log(items.length);
            var sum = 0;
                this.isShop = false;
var strSum = "";
                this.pribors.forEach(item => {
                    if(item.free_cnt){
                        if(item.cnt > item.free_cnt){
                            sum += (item.cnt - item.free_cnt) * item.price;
                        }
                    }else{
                        sum += item.cnt * item.price;
                    }
                    strSum += "pribors:"+(item.cnt * item.price)+";";
                })

                this.recommendedItems.forEach(item => {
                    if(item.free_cnt){
                        if(item.cnt > item.free_cnt){
                            sum += (item.cnt - item.free_cnt) * item.price;
                        }
                    }else{
                        sum += item.cnt * item.price;
                    }
                    console.log('ritems:'+JSON.stringify(item));
                    strSum += "recommendedItems:"+(item.cnt * item.price)+";";
                })


                this.$store.state.cart.forEach(cart => {
                    const index = this.$store.state.dishes.findIndex(item => item.id == cart.item.id && !!item.allowed);

                    if(index >=0){
                        if(this.$store.state.dishes[index].is_shop == true)
                        {
                            this.isShop = true;
                        }

                        sum += this.$store.state.dishes[index].price * cart.cnt;
                        strSum += "item:"+(this.$store.state.dishes[index].price * cart.cnt)+";";

                        if(cart.specs && cart.specs.length){
                            cart.specs.forEach(spec => {
                                const spec_index = this.$store.state.dishes[index].spec.findIndex(option => option.specid == spec.specid && !option.disabled);
                             
                                if(spec_index >= 0){
                                    sum += spec.price * cart.cnt * spec.cnt;
                                    strSum += "specPrice_1:"+(spec.price * cart.cnt * spec.cnt)+";";
                                }
                            })
                        }
                        else if(cart.spec && cart.spec.specid){
                            const spec_index = this.$store.state.dishes[index].spec.findIndex(item => item.specid == cart.spec.specid && !item.disabled);

                            if(spec_index >= 0){
                                sum += this.$store.state.dishes[index].spec[spec_index].price * cart.cnt;
                                strSum += "specPrice_2:"+(this.$store.state.dishes[index].spec[spec_index].price * cart.cnt)+";";
                            }
                        }
                    }
                })

                this.discountSum = sum * this.discountPercent / 100;
                sum = sum * (100 - this.discountPercent) / 100;
                console.log("strSum",strSum);
                return sum;
        },
        loadDiscountData()
        {
            var loadData = {
                coupon: this.coupon,
                order_type: this.$store.state.orderType,
                fs_uid: this.$store.state.restoran.fs_uid,
                location:{
                    lon: this.$store.state.curAddress.lon,
                    lat: this.$store.state.curAddress.lat
                },
                user_id: this.$store.state.user.id,
                token: this.$store.state.user.password
            };
            
            if(this.$store.state.user.card)
            {
                loadData.card = this.$store.state.user.card;
            }
            

            fetch(this.$serverUrl + '/api/v1/order/discount',{
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(loadData)
            })
            .then(res => res.json())
            .then(data => {
                if(data.success)
                {
                    this.discountPercent = data.discountPercent;
                }
                this.loadDeliveryData();
            }).catch(()=>{
                this.loadDeliveryData();
            })
        },
        loadDeliveryData(){

            if(this.$store.state.orderType != 'delivery') 
                return;
            this.deliveryUUID = this.create_UUID();
            var loadData = {
                order_sum: this.orderSum,
                order_type: this.$store.state.orderType,
                fs_uid: this.$store.state.restoran.fs_uid,
                user: this.$store.state.user,
                // cart: this.$store.state.cart,
                cart: this.items,
                table: this.$store.state.tableId,
                delivery_data: this.deliveryData,
                coupon:this.coupon,
                uuid:this.deliveryUUID,
                zone:this.$store.state.curZone
            };
            
            fetch(this.$serverUrl + '/api/v2/order/delivery',{
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(loadData)
            })
            .then(res => res.json())
            .then(data => {
                if(data.uuid != this.deliveryUUID)
                {
                    return;
                }
                // alert(res.content.toString())
                if(data.isAvailByTime)
                {
                    this.isAvailByTime = data.isAvailByTime;
                }else{
                    this.isAvailByTime = '';
                }
                if(data.success)
                {
                    this.deliverySum = data.deliverySum;
                }
            })
        }
    }
}
</script>

<style scoped>
.text-info {
    font-size: 16px;
    background: #eee;
    color: #000;

    margin: 0 0 15px 0;
    border-radius:10px; 
    padding: 20px;
    text-align: center;
}
</style>