<template>
    <div class="cart-item-list mb-20px">
        <div v-for="(cart, key) in items" :key="key" class="cart-item-card">
            <div class="cart-item-info">
                <div class="cart-item-name">
                    {{cart.item.name}}
                </div>
                <div v-if="cart.spec.specid" class="cart-item-spec">
                    {{'('+cart.spec.specname+', '+priceFormat(cart.spec.price)+' руб.)'}}
                </div>
                <div v-for="spec in cart.specs" :key="spec.specid" class="cart-item-spec" >
                    {{'('+spec.specname+', '+priceFormat(spec.price)+' руб. x '+spec.cnt+')'}}
                </div>
            </div>
            <div class="cart-item-row">
                <div class="cart-item-del">
                    <img src="../assets/btn-cart-delete.png" alt="" @click="onDelete(cart)"/>
                </div>
                <div class="cart-item-dec">
                    <img src="../assets/btn-dec.png" alt="" @click="setCnt(cart,-1)"/>
                </div>
                <div class="cart-item-cnt">
                    {{cart.cnt}}
                </div>
                <div class="cart-item-inc">
                    <img src="../assets/btn-inc.png" alt="" @click="setCnt(cart,1)"/>
                </div>
                <div class="cart-item-comment-btn" v-if="false">
                    <img src="../assets/btn-bag-1.png" alt="" @click="togglePackage(cart)" v-if="$store.state.orderType != 'delivery' && cart.comment.length>0"/>
                    <img src="../assets/btn-bag-0.png" alt="" @click="togglePackage(cart)" v-if="$store.state.orderType != 'delivery' && cart.comment.length==0"/>
                </div>
                <div class="cart-item-price">
                    {{cartItemSum(cart)}}
                </div>
            </div>
            <div  v-show="cart.comment" class="cart-item-comment">
                {{cart.comment}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items'],
    methods: {
        cartItemSum(cart){
                // return priceFormat(cart.item.price*1+cart.spec.price*1) + ' ₽/' + cart.item.measure;
                var result = cart.item.price * cart.cnt;

                if(cart.spec){
                    result += cart.spec.price * cart.cnt;
                }

                if(cart.specs){
                    cart.specs.forEach(spec => {
                        result += spec.cnt * spec.price * cart.cnt;
                    })
                }

                return this.priceFormat(result) + ' ₽';
            },
        onDelete(cart){
            this.$store.commit('deleteFromCart', cart);
        },
        setCnt(cart, cnt){
            this.$store.commit('cartCnt', {item:cart.item,cnt,spec:cart.spec,specs:cart.specs, token:cart.token});
        },
        togglePackage(cart){
            if(cart.comment.length > 0)
            {
                cart.comment = '';
            }
            else
            {
                cart.comment = 'С собой';
            }
        }
    }
}
</script>

<style scoped>
.cart-item-list {

}

.cart-item-card {
    border: 0px solid #C1D0E1;
    border-bottom-width: 1px;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
}

.cart-item-info {
    margin: 0 0 15px 0;
}

.cart-item-name {
    color:#506883;
    font-size:14px; 
}

.cart-item-spec {
    margin:5px 0 0 0;
    font-size:12px;
}

.cart-item-row {
    display: flex;
    flex-direction: row;
    height: 38px;
    align-items: center;
}

.cart-item-row img {
    height: 38px;
}

.cart-item-del {
    margin: 0 10px 0 0;
}

.cart-item-cnt {
    width: 46px;
    text-align: center;
}

.cart-item-comment-btn {
    margin: 0 0 0 10px;
}

.cart-item-price {
    font-size:14px;
    color:#333;

    flex: 1 1 auto;
    text-align: right;
}

.cart-item-comment {
    margin:5px 0 0 0;
    font-size:12px;
}
</style>