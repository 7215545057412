<template>
    <v-dialog v-model="visible">
        <div class="modal-select">
            <div class="modal-info">
                <div class="modal-title">
                    Адрес доставки
                </div>
                <div class="modal-message">
                    {{value.full}}
                </div>
            </div>
            <div>
                <div class="address-row">
                    <div class="address-input">
                        <label>Кв/офис</label>
                        <input type="text" class="input-text" v-model="value.room">
                    </div>
                    <div class="address-input">
                        <label>Подъезд</label>
                        <input type="text" class="input-text" v-model="value.podjezd">
                    </div>
                </div>
                <div class="address-row">
                    <div class="address-input">
                        <label>Домофон</label>
                        <input type="text" class="input-text" v-model="value.domofon">
                    </div>
                    <div class="address-input">
                        <label>Этаж</label>
                        <input type="text" class="input-text" v-model="value.etaz">
                    </div>
                </div>
                <div class="address-row">
                    <div class="address-input">
                        <label>Комментарий</label>
                        <textarea class="input-text" v-model="value.comment" />
                    </div>
                </div>
            </div>
            <div class="button active last" @click="save">Сохранить</div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: ['modalVisible','value'],
    computed:{
        visible:{
            set(v){
                if(!v){
                    this.$emit('hide');
                }
            },
            get(){
                return this.modalVisible
            }
        }
    },
    methods: {
        save(){
            this.$emit('input', this.value);
        }
    }
}
</script>

<style scoped>
.address-row {
    display: flex;
    flex-direction: row;
    margin: 10px 5px 0 5px;
}

.address-input {
    flex: 1 1 100%;
    margin: 0 5px;
}

.address-input label {
    font-size: 12px;
    margin: 0 0 2px 0;
}

.address-input .input-text {
    border:1px solid #eee;
    border-radius: 5px;
    margin: 0;
    padding: 5px;
    font-size: 14px;
    height: 30px;
}

.address-input textarea.input-text {
    height: 100px;
}
</style>