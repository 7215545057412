<template>
    <div class="rest-list">
        <div v-for="item in items" :key="item.id" class="rest-list--item" @click="onRestTap(item)">
            <div class="mm-icon">
                <img :src="$assetsUrl + '/assets/icon_rest.png'" alt="">
                <i v-if="item.active==1" class="worknow" :class="{active: item.worknow}"></i>
                <i v-if="item.active==1 && item.winecard == 1" class="fas fa-wine-glass wine"></i>
            </div>
            <div class="descr">
                <div class="item-name">{{ item.name }}</div>
                <div v-if="item.address" class="item-address">{{ item.address }}</div>
                <div v-if="item.congestion">{{ item.congestion.text }}</div>
            </div>
            <div style="font-size:22px;color:#d50000;" v-if="item.isFavorite >= 0">
                <i class="far fa-heart"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items'],
    methods: {
        onRestTap(item){
            this.$emit('input', item );
        }
    }
}
</script>

<style scoped>
.mm-icon {
    width:50px;
    height:50px;
    border: 1px solid #C1D0E1;
    background: #fff;
    border-radius: 10px;
    margin-top: 7px;
    line-height: 50px;
    text-align: center;
    position: relative;

    flex: 0 0 50px;
}

.mm-icon img {
    width: 27px;
    display: inline-block;
    vertical-align: middle;
}

.mm-icon .worknow {
	width: 16px;
	height: 16px;
	background-color: #FF725F;
	border-radius: 8px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.mm-icon .worknow.active
{
	background-color: #02FB39;
}

.mm-icon .wine {
    display: block;
    bottom: 0;
    right: 0;
    position: absolute;
}

.rest-list--item {
    display: flex;
    flex-direction: row;

	padding:3px 10px 10px 10px;
    border: 0px solid #C4C4C4;
	border-bottom-width: 1px;
}

.rest-list--item .descr {
    margin: 7px 0 0 10px;
    flex-grow: 1;
}

.rest-list--item .item-name {
    color: #333;
    font-size:16px;
    margin-right:10px;
}

.rest-list--item .item-address {
    font-size:14px;
    margin-top:5px;
    color:#506883;
}
</style>