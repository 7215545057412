<template>
    <div @click="onTap" class="checkbox-input" :class="{active:value}">
        <span />
    </div>
</template>

<script>
export default {
    props: ['value'],
    methods: {
        onTap(){
            this.$emit('input',!this.value)
        }
    }
}
</script>

<style scoped>
.checkbox-input {
    height:40px;
    width: 80px;
    border-radius: 20px;
    border: 2px solid #C1D0E1;
    text-align: left;
}

.checkbox-input span {
    display: inline-block;
    height:32px;
    width: 32px;
    border-radius: 16px;
    background: #aaa;
    margin: 2px;
}

.checkbox-input.active {
    border-color: #0071E5;
    background-color: #22226a21;
    text-align: right;
}

.checkbox-input.active span {
    background: #0071E5;
    margin: 2px 2px 2px 40px;
}
</style>