<template>
    <div>
        <CartDatePicker v-if="$store.state.restoran.deliveryDays > 0"
            v-model="deliveryDate" class="mb-20px"
        />
        <CartTimePicker title="Время самовывоза" v-model="deliveryTime" :allowGotov="!deliveryDate.length || deliveryDate == currentDate" class="mb-20px"/>

        <!-- <CartPriborCnt v-model="pribors" class="mb-20px"/> -->
    </div>
</template>


<script>
import CartDatePicker from './CartDatePicker';
import CartTimePicker from './CartTimePicker';
// import CartPriborCnt from './CartPriborCnt';
export default {
    components: {
        CartDatePicker,
        CartTimePicker,
        // CartPriborCnt
    },
    data(){
        return {
            pribors: 0,
            deliveryTime: '',
            deliveryDate: '',
            comment: '',
            currentDate: ''
        }
    },
    created(){
        this.currentDate = this.dateFormat(new Date());
    },
    watch: {
        deliveryTime(){
            this.$emit('change',this.resultData)
        },
        deliveryDate(){
            this.$emit('change',this.resultData)
        },
        comment(){
            this.$emit('change',this.resultData)
        },
        pribors(){
            this.$emit('change',this.resultData)
        }
    },
    computed:{
        resultData(){
            return {
                deliveryTime: this.deliveryTime,
                deliveryDate: this.deliveryDate,
                comment: this.comment,
                pribors: this.pribors
            }
        }
    }
}
</script>