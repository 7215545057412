<template>
    <div class="page-address-detail">
        <TopBar :showBack="true" title="Редактирование адреса" :rightBtn="item?'fas fa-trash':''" @tapRight="deleteItem" />
        <div v-if="item" class="scrollview" style="padding:0 0 70px 0;">
            <yandex-map :coords="[item.lat, item.lon]" :controls="[]" style="height:300px;"
                :zoom="16" @map-was-initialized="onMapInit"></yandex-map>
            <div style="margin:10px">
                <div  class="mb-10px">
                    <div class="mb-5px">Адрес</div>
                    <textarea disabled class="input-text-sm" v-model="item.full" style="height:auto;padding:10px"></textarea>
                </div>
                <div class="row-grow-1 mb-10px">
                    <div style="margin:0 5px 0 0;">
                        <div class="mb-5px">Кв/офис</div>
                        <input type="text" class="input-text-sm" v-model="item.room">
                    </div>
                    <div style="margin:0 0 0 5px;">
                        <div class="mb-5px">Подъезд</div>
                        <input type="text" class="input-text-sm" v-model="item.podjezd">
                    </div>
                </div>
                <div class="row-grow-1 mb-10px">
                    <div style="margin:0 5px 0 0;">
                        <div class="mb-5px">Домофон</div>
                        <input type="text" class="input-text-sm" v-model="item.domofon">
                    </div>
                    <div style="margin:0 0 0 5px;">
                        <div class="mb-5px">Этаж</div>
                        <input type="text" class="input-text-sm" v-model="item.etaz">
                    </div>
                </div>
                <div  class="mb-10px">
                    <div class="mb-5px">Комментарий</div>
                    <textarea class="input-text-sm" v-model="item.comment" style="height:100px;padding:10px"></textarea>
                </div>
            </div>
        </div>
        <div class="btn btn-primary" style="position:fixed;bottom:10px;left:10px;right:10px;" @click="onSubmit">
            Сохранить
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue'
var deliveryPoint = null;
export default {
    components: {TopBar},
    data(){
        return {
            proccessing: false,
            item: null
        }
    },
    created(){
        this.loadItem();
    },
    methods: {
        loadItem(){
            if(this.proccessing) return;
            this.proccessing = true;

            fetch(this.$serverUrl + '/api/v2/user/address/list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.$route.params.id,
                    user_id: this.$store.state.user.id,
                    user_token: this.$store.state.user.password
                })
            })
            .then(res => res.json())
            .then(data => {
                if(data.success && data.result.length){
                    this.item = data.result[0];
                }
                this.proccessing = false
            })
            .catch(()=>{
                this.proccessing = false
            })
        },
        onMapInit(map){
            deliveryPoint = new window.ymaps.GeoObject({
                geometry: {type: 'Point',
                    coordinates: [this.item.lat,this.item.lon]
                },
                // properties: {iconCaption: 'Адрес'}
            }, {
                preset: 'islands#blackDotIconWithCaption',
                // draggable: true,
                iconCaptionMaxWidth: '215'
            })

            map.geoObjects.add(deliveryPoint);
        },
        onSubmit(){
            if(this.proccessing) return;
            this.proccessing = true;

            fetch(this.$serverUrl + '/api/v2/user/address/save',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    address: this.item,
                    user_id: this.$store.state.user.id,
                    user_token: this.$store.state.user.password
                })
            }).then(() => {
                this.$router.go(-1);
            }).catch(() => {
                alert('Ошибка сохранения адреса');
            })
        },
        deleteItem(){
            if(confirm('Удалить адрес?')){
                fetch(this.$serverUrl + '/api/v2/user/address/delete',{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: this.item.id,
                        user_id: this.$store.state.user.id,
                        user_token: this.$store.state.user.password
                    })
                }).then(() => {
                    this.$router.go(-1);
                }).catch(() => {
                    alert('Ошибка удаления адреса');
                })
            }
        }
    }
}
</script>