<template>
    <div v-show="items.length > 0" class="cart-item-list mb-20px">
        <div style="margin-bottom:10px">Также рекомендуем:</div>

        <div v-show="show_separated" style="margin-bottom:10px;align-items:center" class="d-flex">
            <div style="flex-grow:1;">Настроить по отдельности</div>

            <CheckboxInput v-model="check_separated" />
        </div>

        <div v-for="item in items" :key="item.id" class="cart-item-card">
            <div class="cart-item-info">
                <div class="cart-item-name">
                    {{item.name }}
                </div>
            </div>
            <div class="cart-item-row">
                <div class="cart-item-dec">
                    <img src="../assets/btn-dec.png" alt="" @click="cntInc(-1,item)"/>
                </div>
                <div class="cart-item-cnt">
                    {{item.cnt}}
                </div>
                <div class="cart-item-inc">
                    <img src="../assets/btn-inc.png" alt="" @click="cntInc(1,item)"/>
                </div>
                <div class="cart-item-price">
                    {{sumItem(item)}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckboxInput from './CheckboxInput.vue'
export default {
  components: { CheckboxInput },
    props: {
        value: {
            // type: Array,
            default: []
        },
        cartItems: {
            // type: Array,
            default: []
        },
        orderType: {
            type: String,
            default: ''
        },
        restoranId: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            items: [],
            check_separated: false,
            show_separated: false
        }
    },
    created(){
        this.loadData()
    },
    watch: {
        check_separated(){
            this.loadData();
        }
    },
    methods: {
        emitInput(){
            this.$emit('input', this.items)
        },
        cntInc(cnt, item){
            if(item.cnt + cnt >= 0){
                item.cnt += cnt;
                this.emitInput();
            }
        },
        sumItem(item){
            var price = 0;
            if(item.cnt > item.free_cnt){
                price = item.price * (item.cnt - item.free_cnt);
            }
            return this.priceFormat(price) + ' ₽';
        },
        loadData(){

            console.log('cartRecommends', JSON.parse(JSON.stringify({order_type: this.orderType,
                    restoran_id: this.restoranId,
                    cart_items: this.cartItems,
                    separated: this.check_separated,
                    items: this.items})))
            this.request({
                action: 'cartRecommends',
                fields: {
                    order_type: this.orderType,
                    restoran_id: this.restoranId,
                    cart_items: this.cartItems,
                    separated: this.check_separated,
                    items: this.items
                }
            })
            .then(r => {
                if(r.items){
                    this.items = r.items;
                    this.show_separated = r.show_separated;
                    this.emitInput();
                }
            })
        }
    }
}
</script>


<style scoped>
.cart-item-list {

}

.cart-item-card {
    border: 0px solid #C1D0E1;
    border-bottom-width: 1px;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
}

.cart-item-info {
    margin: 0 0 15px 0;
}

.cart-item-name {
    color:#506883;
    font-size:14px; 
}

.cart-item-spec {
    margin:5px 0 0 0;
    font-size:12px;
}

.cart-item-row {
    display: flex;
    flex-direction: row;
    height: 38px;
    align-items: center;
}

.cart-item-row img {
    height: 38px;
}

.cart-item-del {
    margin: 0 10px 0 0;
}

.cart-item-cnt {
    width: 46px;
    text-align: center;
}

.cart-item-comment-btn {
    margin: 0 0 0 10px;
}

.cart-item-price {
    font-size:14px;
    color:#333;

    flex: 1 1 auto;
    text-align: right;
}

.cart-item-comment {
    margin:5px 0 0 0;
    font-size:12px;
}
</style>