<template>
    <div class="page-order-list">
        <TopBar :showMenu="true" title="Мои заказы"/>
        <div class="order-list scrollview" @scroll="handleScroll">
            <OrderListItem v-for="item in items" :key="item.id" :item="item" @click="onTapItem(item)" />
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import OrderListItem from '../components/OrderListItem.vue';

export default {
    components: {
        TopBar,
        OrderListItem
    },
    data(){
        return {
            items: [],
            proccessing: false,
            allowMore: false
        }
    },
    created(){
        this.loadOrders()
    },
    methods: {
        loadMore(){
            if(this.allowMore)
            this.loadOrders();
        },
        loadOrders(){
            if(this.proccessing) return;
            
            this.proccessing = true;
            fetch(this.$serverUrl + '/api/v1/order/listUser/'+this.$store.state.user.id,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    user_token: this.$store.state.user.password,
                    limit: 20,
                    offset: this.items.length
                })
            })
            .then(r=>r.json())
            .then(r=>{
                if(!r.length) 
                    this.allowMore = false;
                else
                    this.allowMore = true;
                this.items = this.items.concat(r);
                this.proccessing = false;
            }).catch(()=>{
                this.proccessing = false;
            });
        },
        handleScroll(e){
            if((e.target.offsetHeight + e.target.scrollTop) >= e.target.scrollHeight) {
                this.loadMore();
            }
        },
        onTapItem(item){
            this.$router.push('/order_detail/'+item.id);
        }
    }
}
</script>