import { render, staticRenderFns } from "./ModalRating.vue?vue&type=template&id=f76bda54&scoped=true&"
import script from "./ModalRating.vue?vue&type=script&lang=js&"
export * from "./ModalRating.vue?vue&type=script&lang=js&"
import style0 from "./ModalRating.vue?vue&type=style&index=0&id=f76bda54&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f76bda54",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VDialog,VOverlay,VProgressCircular})
