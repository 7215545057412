<template>
    <div class="page-from">
        <TopBar :showBack="true" :title="title" :showMap="true" />
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <RestoranList :items="items" class="scrollview" @input="onRestTap">
        </RestoranList>
    </div>
</template>

<script>
import TopBar from '/src/components/TopBar';
import RestoranList from '/src/components/RestoranList';

export default {
    components: {
        TopBar,
        RestoranList
    },
    props: {
        orderType: {
            type: String,
            default: 'self'
        }
    },
    data(){
        return {
            proccessing: true,
        }
    },
    computed: {
        title(){
            return 'Самовывоз';
        },
        items(){
            var result = [];

            this.$store.state.restorans.forEach(item => {
                item.isFavorite = this.$store.state.favorite_restorans.findIndex((rest)=>{
					return item.fs_uid == rest.fs_uid
				});

				item.worknow = this.isWorkNow(item.shedule);
                if(item.deliveryRest == '1' && this.orderType == 'inrest'){
                    result.push(item);
				}
				if(item.deliverySelf == '1' && this.orderType == 'self'){
					result.push(item);
				}
			})

            result.sort((a,b) => b.isFavorite - a.isFavorite)

            return result;
        }
    },
    created(){
        this.getRestorans().then(() => {
            this.proccessing = false;
        })
    },
    methods: {
        onRestTap(item){
            this.$store.commit('setRestoran', item)
            // this.$store.state.orderType = this.orderType;
            // this.$router.push({name:'MenuList'})
            
            this.$router.push('/menu/'+item.id+'/self')
        }
    }
}
</script>

<style scoped>
</style>