<template>
    <v-dialog v-model="visible">
        <div class="modal-select">
            <div class="modal-info">
                <div class="modal-title">
                    {{title}}
                </div>
                <div class="modal-message" v-show="$store.state.dialogItems.message">
                    {{$store.state.dialogItems.message}}
                </div>
            </div>
            <div  v-for="(item, index) in items" :key="index"
             class="button" :class="{last:index==items.length-1,active:index==$store.state.dialogItems.active}" @click="onTapItem(item,index)">{{item.name?item.name:item}}</div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    computed: {
        visible: {
            set(v){
                this.$store.state.dialogItems.show = v;
            },
            get(){
                return this.$store.state.dialogItems.show;
            }
        },
        items(){
            return this.$store.state.dialogItems.items;
        },
        title(){
            return this.$store.state.dialogItems.title;
        }
    },
    methods: {
        onTapItem(item,index){
            console.log('item', item)
            this.$store.state.dialogItems.result = index;
            // this.$store.state.dialogItems.result = item;
            this.$store.state.dialogItems.show = false;
        }
    }
}
</script>