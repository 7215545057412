<template>
    <div class="page-menu">
        <TopBar :showMenu="!$store.state.kiosk" :showBack="$store.state.kiosk" :title="restoranName" :showSearch="true" :showLogo="true" 
            v-if="$store.state.orderType!='self' || $store.state.kiosk"
        />
        <TopBarSelf :title="$store.state.restoran.nameShort" :showSearch="true" :showLogo="true" :showMenu="true"
            v-if="$store.state.orderType=='self' && !$store.state.kiosk"
             />
        <MenuListView 
            :categories="categories" 
            :items="dishesFiltered"
            v-show="loaded">
        </MenuListView>
        <CartButton />
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '/src/components/TopBar';
import TopBarSelf from '/src/components/TopBarSelf';
import MenuListView from '/src/components/MenuListView';
import CartButton from '/src/components/CartButton';

export default {
    components: {
        TopBar,
        TopBarSelf,
        MenuListView,
        CartButton
    },
    data(){
        return {
            proccessing: false,
            items: [],
            curtab: 'new',
            cntToLoad: 3,
            itemWidth: 0,
            itemHeight: 0,
            loaded: false,
            categories: [],
            dishes: [],
            isCategorySelected : false
        }
    },
    computed: {
        restoranName(){
            var result = '';
            if(this.$store.state.restoran.name){
                result = this.$store.state.restoran.nameShort;
            }else{
                result = '..';
            }

            if(this.$store.state.kiosk){
                result += ', стол #'+this.$store.state.tableId;
            }

            return result;
        },
        dishesFiltered(){
            return this.dishes.filter(item => item.categoryId != 0)
        }
    },
    created(){
        this.proccessing = true;
        this.$watch(() => this.$store.state.loaded, v => {
            if(v){
                this.loadMenu();
            }
        }, {immediate: true})

        this.$watch(()=>this.$store.state.is_shop,()=>{
            this.loadMenu();
        })
    },
    methods: {
        checkDelayed(){
                if(!this.$store.state.settings.allow_delayed){
                    this.loaded = true;
                    return;
                }

                this.request({
                    action: 'checkDelayed',
                    fields: {
                        fs_uid: this.$store.state.restoran.fs_uid,
                        order_type: this.$store.state.orderType,
                        delivery_datetime_props: this.$store.state.deliveryDateTimeProps
                    }
                })
                .then(r => {
                    this.loaded = true;
                    if(r.order){
                        if(confirm('У вас есть отложенный заказ от '+r.order.time+'. Хотите завершить его оформление?'))
                        {
                            // this.request({
                            //     action: 'cancelDelayed',
                            //     fields: {
                            //         id: r.order.id
                            //     }
                            // })
                              

                            var b = [];
                            r.order.cart.forEach(cart => {
                                var cartItem = this.dishes.filter(item => item.id == cart.item.id);

                                if(cartItem){
                                    cartItem = cartItem[0];
                                }
                                
                                if(!cartItem.allowed){
                                    b.push(cart.item.name);
                                }
                            })

                            if(b.length > 0){
                                alert('Следующие блюда недоступны к заказу: ' + b.join(', '));
                            }
                            
                            this.$store.state.cart = r.order.cart;
                            this.$router.push('/cart')
                        }
                        // else{

                        //     this.request({
                        //         action: 'cancelDelayed',
                        //         fields: {
                        //             id: r.order.id
                        //         }
                        //     })
                        // }
                    }else{
                        this.loaded = true;
                    }
                })
                .catch(() => {
                    this.loaded = true;
                })
            },
        loadMenu(){
            this.loaded = false;
            this.proccessing = true;
            fetch(this.$serverUrl + '/api/v2/menu/load_v30003', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    is_shop: this.$store.state.is_shop,
                    fs_uid: this.$store.state.restoran.fs_uid,
                    order_type: this.$store.state.orderType,
                    user_id: this.$store.state.user.id,
                    lock_code: this.$store.state.lockCode,
                    delivery_datetime_props : this.$store.state.deliveryDateTimeProps, 
                    ver: 4,
                    booking_num: this.$store.state.booking_num
                })
            })
            .then(res => res.json())
            .then(data => {
                this.categories = data.categories;
                data.dishes.push({
                    space: true
                });
                this.dishes = data.dishes;
                this.$store.state.dishes = data.dishes;
                this.$store.state.stories = data.stories;
                this.proccessing = false;
                this.$store.state.lockCode = data.lock_code;
                // this.loaded = true;


                    this.checkDelayed()
            }).catch((e)=>{
                // reject(e);
                this.proccessing = false;
                console.log('error',e);
            });
        }
    }
}
</script>