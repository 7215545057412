<template>

        <div>
            <input type="tel" :text="value"
                placeholder="_ _ _ _"
                class="input-light"
                v-mask="'# # # #'"
                masked="true"
                ref="input"
                @input="onInput"
            >
        </div>
</template>

<script>
import {mask} from 'vue-the-mask'

export default {
    directives: {mask},
    props: ['value'],
    methods: {
        onInput(e){
            var val = e.target.value.match(/\d+/g);
            val = val.join('');
            this.$emit('input', val)

            if(val.length == 4 && val != this.value){
                this.$emit('filled', val)
                this.$refs.input.blur()
            }
        },
        focus(){
            this.$refs.input.focus()
        }
    },
    mounted(){
        this.$nextTick(() => this.focus())
    }
}
</script>

<style scoped>
.input-light,
.input-light::placeholder
{
    color: #fff;
}

.input-light
{
    outline: none;
    border: 0!important;
    padding: 0!important;
    text-align: center;

    font-size: 30px;
    display: block;
    width: 100%;
}
</style>