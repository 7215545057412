<template>
    <div class="cart-button-inner">
        <div v-show="orderSum > 0 && orderSum >= minSum && canbuy && !isAvailByTime">
            <div class="btn btn-big btn-primary" v-show="paysystem=='card'" @click="onSubmit">
                Оплатить картой
            </div>
            <div class="btn-pay" v-show="paysystem=='applepay'" @click="onSubmit">
                <img src="../assets/applepay.png"/>
            </div>
            <div class="btn-pay" v-show="paysystem=='googlepay'" @click="onSubmit">
                <img src="../assets/googlepay.png"/>
            </div>
            <div class="btn-pay" v-show="paysystem=='sbp'" @click="onSubmit">
                <img src="../assets/sbp.png"/>
            </div>
        </div>

        <div v-show="isAvailByTime" class="cart-button-error">
            {{isAvailByTime}}
        </div>

        <div v-show="orderSum < minSum && canbuy && !isAvailByTime" class="cart-button-error">
            {{(deliverySum>0?'Минимальная сумма заказа без учета стоимости доставки: ':'Минимальная сумма заказа: ')+minSum+' руб.'}}
        </div>

        <div v-show="orderSum > 0 && !canbuy && !isAvailByTime" class="cart-button-error">
            {{$store.state.settings.textNoTime}}
        </div>
    </div>
</template>

<script>
export default {
    props: ['orderSum','minSum','canbuy','isAvailByTime','paysystem','deliverySum'],
    methods: {
        onSubmit(){
            this.$emit('submit')
        }
    }
}
</script>

<style scoped>
.cart-button-inner {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.cart-button-error {
    padding: 0 10px;
    background: #eee;
    height:60px;
    border-radius:10px;
    border:1px solid #777;
    display: flex;
    align-items: center;
}
</style>