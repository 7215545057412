<template>
    <div>
        <top-bar title="Подтверждение бронирования" :showBack="true" />
        <div class="scrollview" style="padding:10px 0 70px">
            <div class="booking-info">
                <div class="booking-info-label">
                    Дата:
                </div>
                <div class="booking-info-value">
                    {{curDate}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">
                    Количество гостей:
                </div>
                <div class="booking-info-value">
                    {{cntpersons + ' человек'}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">
                    Длительность:
                </div>
                <div class="booking-info-value">
                    {{cntminutes + ' минут'}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">
                    Время:
                </div>
                <div class="booking-info-value">
                    {{timeFormat(periodfrom)}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">
                    Заведение:
                </div>
                <div class="booking-info-value">
                    {{restoran.name}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">
                    Бронь:
                </div>
                <div class="booking-info-value">
                    {{$store.state.user.name + ' ' + $store.state.user.phone}}
                </div>
            </div>

            <div class="hr-line"></div>

            <div style="margin:0 10px 10px">
                Пожелания к бронированию:
            </div>
            <div style="margin: 0 10px 10px">
                <textarea v-model="comment" class="input-text" style="height:100px;padding:10px;"></textarea>
            </div>

            <div style="margin:15px 10px 10px;font-size:12px;white-space:break-spaces;">
                {{$store.state.settings.booking_text_step2}}
            </div>
        </div>
        <div style="position:fixed;bottom:10px;left:10px;right:10px;">
            <!-- <GridLayout columns="30,10,*" rows="auto" margin="0 10" @tap="agree=!agree">
                <Label backgroundColor="#eee" width="30" height="30" borderRadius="3"/>
                <Label :text="'\uf00c'" class="fas" verticalAlignment="center" horizontalAlignment="center" v-show="agree"/>
                <Label text="Я согласен с правилами бронирования" col="2" textWrap="true" verticalAlignment="center" />
            </GridLayout> -->
            <v-checkbox
            v-model="agree"
            label="Я согласен с правилами бронирования"
            ></v-checkbox>
            <div class="btn-submit" @click="tapBooking" :class="{'--secondary':!agree}">
                Подтверждаю бронирование
            </div>
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';

export default {
    components: {
        TopBar
    },
    props: ['cntpersons', 'periodfrom', 'restoran', 'date', 'cntminutes'],
    data(){
        return {
            proccessing: false,
            comment: '',
            agree: true
        }
    },
    computed: {
        curDate(){
            return this.dateFormatFull(new Date(this.date));
        }
    },
    methods:{
        tapBooking(){
            if(!this.agree) return;
            if(this.proccessing) return;

            this.proccessing = true;

            this.request({
                action: 'bookingSave',
                fields: {
                    cntpersons: this.cntpersons,
                    cntminutes: this.cntminutes,
                    periodfrom: this.periodfrom,
                    date: this.date/1000,
                    timeZoneOffset: new Date().getTimezoneOffset()*60,
                    restoran: this.restoran,
                    comment: this.comment
                }
            }).then(r => {
                if(r.booking)
                {
                    this.$router.push({
                        name: 'BookingResult',
                        params: {
                            booking: r.booking
                        }
                    });
                    // this.$navigateTo(this.$routes.BookingResult, {
                    //     props: {
                    //         booking: r.booking
                    //     }
                    // });
                    return;
                }
                if(r.error)
                {
                    alert(r.error);
                }
                this.proccessing = false;
            }).catch(() => {
                alert(this.$error502);
                this.proccessing = false;
            })
        }
    }
}
</script>

<style scoped>
.booking-info
{
    padding: 7px 10px;
    color: #33404D;
    display: flex;
    justify-content: space-between;
}

.booking-info-label
{
    color: #999FA6;
}

.booking-info-value
{
    
}

.hr-line
{
    height: 1px;
    background:#C1D0E1;
    margin: 20px 10px 27px;
}

.btn-submit {
    height: 55px;
    color: #fff;
    background-color:#0071E5;
    border-radius: 40px;
    margin: 0px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-submit.--secondary
{
    background-color: gray;
}

.btn-submit.--error
{
    background-color: #d50000;
}
</style>