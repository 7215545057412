import Vue from 'vue'
import Vuex from 'vuex'
import { loadSettings } from '../actions/load_settings'
import {loadState} from '../actions/load_state'

var md5 = require('md5');
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      kiosk: false,
      kioskIdleVisible: false,
      cache:{
          booking: []
      },
        stories: [],
        dishes: [],
        menuEnabled: true,
        showDrawer: false,
        restoran: {
            fs_uid: ''
        },
        is_shop: 0,
        allowGestures: false,
        restorans: [],
        cart: [],
        curZone: {},
        curLocation: {
            lat: 0,
            lon: 0,
        },
        curAddress: {},
        appleSignUser: '',
        navBottom: false,
        navBottomIndex: 1,
        user: {},
        curPage: '',
        orderType: '',
        tableId: '',
        favorites: [],
        favorite_restorans: [],
        address: {
            region: {
                id: 0,
                name: '',
                full: ''
            },
            city: {
                id: 0,
                name: '',
                full: ''
            },
            street: {
                id: 0,
                name: '',
                full: ''
            },
            house: '',
            podjezd: '',
            domofon: '',
            etaz: '',
            room: '',
        },
        addresses: [],
        checkout: {},
        settings: {
            delivery: false
        },
        prevPaymentType: 0,
        lockCode: '',
        pushToken: '',
        pushData: {},
        ready: false,
        showModal: {},
        modalResult: false,
        takePhoto: false,
        photoResult: false,
        loaded: false,
        loading: false,
        booking_num: '',
        booking_min_sum: 0,
        deliveryDateTimeProps : {
            deliveryDate: '',
            deliveryTime: '',
            isTimePeriods : null
        },
        specModal: {
            show: false,
            item: null
        },
        dialogItems: {
            show: false,
            title: '',
            items: [],
            active: null,
            result: null
        }
  },
  mutations: {
    authUser(state, user){
      console.log('authUser', user)
      user.authorized = true;
      state.user = user;
      this.commit('saveState');
    },
    saveState(state){
      // let documents = knownFolders.documents();
      // let folder = documents.getFolder("storage");
      // let file = folder.getFile("state.json");

      const stateObj = {
          kiosk: state.kiosk,
          user: state.user,
          cart: state.cart,
          address: state.address,
          favorites: state.favorites,
          favorite_restorans: state.favorite_restorans,
          checkout: state.checkout,
          orderType: state.orderType,
          restoran: state.restoran,
          lockCode: state.lockCode
      };
      
      localStorage.setItem('state', JSON.stringify(stateObj));
      // file.writeText(JSON.stringify(stateObj));
    },
    loadState(state, params = null){
      if(state.loaded || state.loading) 
        return;

        state.loading = true;

      var p1 = loadState(state, params);
      var p2 = loadSettings(state);
      Promise.all([
        p1, p2
      ]).then(() => {
          state.loaded = true;
      })
    },
    logout(state){
        state.user.authorized = false;

        state.user.card = '';
        state.user.phone = '';
        state.user.email = '';
        state.user.password = '';
        state.user.id = '';
        this.commit('saveState');
    },
    toggleFavoriteRestorans(state, item)
        {
            const index = state.favorite_restorans.findIndex(row => row.fs_uid==item.fs_uid);
            if(index >= 0)
            {
                state.favorite_restorans.splice(index,1);
            }
            else
            {
                state.favorite_restorans.push(item);
            }
            this.commit('saveState');
        },
    tableId(state, id){
        state.tableId = id;
    },
    orderType(state,type){
        state.orderType = type;
    },
    saveDeliveryDateTimeProps(state, payload){
        state.deliveryDateTimeProps = payload;
    },
    setRestoran(state, restoran){
        if(typeof state.restoran != 'undefined' && state.restoran.fs_uid != restoran.fs_uid)
        {
            this.commit('emptyCart');
        }
        state.restoran = restoran;
        this.commit('saveState');
    },
    emptyCart(state){
        state.cart = [];
        this.commit('saveState');
    },
    cartCnt(state, {item,cnt,spec={},specs=[],token=''}){
        var index = -1;

        if(!token){
            token = JSON.stringify({
                item_id: item.id,
                specid: spec.specid,
                specs
            });

            token = md5(token);
        }

        // if(spec.specid == '')
        // {
        //     index = state.cart.findIndex(row => row.item.id == item.id);
        // }
        // else
        // {
        //     index = state.cart.findIndex(row => row.item.id == item.id && row.spec.specid == spec.specid);
        // }

        index = state.cart.findIndex(row => row.token == token);

        if(index == -1){
            index = state.cart.findIndex(row => row.item.id == item.id);
        }
        
        if(index >= 0){
            if(state.cart[index].cnt + cnt > 0)
                state.cart[index].cnt += cnt;
            else
                state.cart.splice(index,1)
        }
        this.commit('saveState');
    },
    addToCart(state, {item, cnt}){
        // var comment = state.orderType=='self' ? 'С собой' : '';
        var comment = '';
        var specs = [];

            var token = JSON.stringify({
                item_id: item.id,
                specid: '',
                specs
            });

            token = md5(token);

            const index = state.cart.findIndex(row => row.token == token);
            if(index == -1){
                state.cart.push({token, item, cnt, specs, comment, spec:{specid:'',price:0}});
            }
            else
            {
                state.cart[index].cnt += cnt;
            }
        this.commit('saveState');
    },
    addToCartSpec(state, {item, cnt, spec}){
        // var comment = state.orderType=='self' ? 'С собой' : '';
        var comment = '';
        const index = state.cart.findIndex(row => row.item.id == item.id && row.spec.specid == spec.specid);
        if(index == -1){
            state.cart.push({item,cnt, spec,comment});
        }
        else
        {
            state.cart[index].cnt += cnt;
        }
        this.commit('saveState');
    },
    addToCart_v2(state, {item,cnt,specs}){
        var comment = '';

        specs.sort((a,b)=>(a.specid>b.specid)?1:(a.specid<b.specid)?-1:0);

        var token = JSON.stringify({
            item_id: item.id,
            specid: '',
            specs
        });

        token = md5(token);

        const index = state.cart.findIndex(row => row.token == token);
        if(index == -1){
            state.cart.push({token, item, cnt, specs, comment, spec:{specid:'',price:0}});
        }
        else
        {
            state.cart[index].cnt += cnt;
        }
        this.commit('saveState');
    },
    toggleCart(state, item){
        // var comment = state.orderType=='self' ? 'С собой' : '';
        var comment = '';
        const index = state.cart.findIndex(row => row.item.id == item.id);
        if(index >= 0){
            state.cart.splice(index, 1);
        }
        else
        {
            state.cart.push({item,cnt:1,spec:{specid:'',price:0},comment});
        }
        this.commit('saveState');
    },
    deleteFromCart(state,cart){
        const index = state.cart.findIndex(row => row.item.id == cart.item.id && row.spec.specid == cart.spec.specid);
        if(index >= 0){
            state.cart.splice(index, 1);
        }
        this.commit('saveState');
    },
    toggleFavorite(state, item)
    {
        const index = state.favorites.findIndex(row => row.id==item.id);
        if(index >= 0)
        {
            state.favorites.splice(index,1);
        }
        else
        {
            state.favorites.push(item);
        }
        this.commit('saveState');
    }
  },
  actions: {
  },
  modules: {
  }
})
