<template>
    <v-dialog v-model="dialogVisible" fullscreen>
        <EasyCamera @close="onCloseCamera" ref="camera" v-model="picture" />
    </v-dialog>
</template>

<script>
import EasyCamera from 'easy-vue-camera';

export default {
    props: ['visible'],
    components: {EasyCamera},
    computed: {
        picture:{
            set(v){
                this.$refs.camera.stop();
                this.$emit('input',v);
            },
            get(){
                return null;
            }
        },
        dialogVisible: {
            set(v){
                if(!v){
                    this.$refs.camera.stop();
                    this.$emit('hide');
                }
            },
            get(){
                return this.visible;
            }
        }
    },
    methods: {
        onCloseCamera(){
            this.$refs.camera.stop();
            this.$emit('hide');
        }
    }
}
</script>