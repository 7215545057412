<template>
    <div class="page-profile">
        <TopBar :showMenu="true" title="Профиль" />
        <div class="scrollview" style="padding:10px 10px 70px 10px">
            <input type="text" class="input-text mb-10px" placeholder="Имя и Фамилия" v-model="user.name" />
            <InputPhone v-model="user.phone" class="mb-10px" :input-text="true" />
            <input class="input-text mb-10px" placeholder="E-mail" type="email" v-model="user.email"/>
            <input class="input-text mb-10px" placeholder="Карта лояльности" type="text" v-model="user.card"/>
            <input class="input-text mb-10px" placeholder="Новый пароль" type="password" v-model="user.passwordNew"/>

            <div class="btn btn-drop-account mb-10px" @click="onDropAccount">
                Удалить мой аккаунт и перестать хранить персональные данные 
            </div>
            <div class="btn btn-primary" @click="onSubmit">
                Сохранить
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import InputPhone from '../components/InputPhone';
export default {
    components: {TopBar, InputPhone},
    data(){
        return {
            user: this.$store.state.user
        }
    },
    methods: {
        onDropAccount(){
            if(confirm('Вы подтверждаете удаление Вашего профиля?')){
                fetch(this.$serverUrl + '/api/v1/user/drop_account',{
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        id: this.user.id,
                        password: this.user.password,
                    })
                })
                .then(res => res.json())
                .then(data => {
                    if(data.user)
                    {
                        this.$store.commit('dropAccount');
                        this.$router.push('/');
                    }
                    else
                    {
                        alert('Что то пошло не так');
                    }
                })
            }
            return;
        },
        onSubmit(){
            if(!this.validateEmail(this.user.email)){
                alert('Неверный формат email');
                return;
            }
            fetch(this.$serverUrl + '/api/v1/user/update', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: this.user.name,
                    id: this.user.id,
                    password: this.user.password,
                    passwordNew: this.user.passwordNew,
                    phone: this.user.phone,
                    card: this.user.card,
                    city: this.user.city,
                    email: this.user.email,
                    street: this.user.street,
                    house: this.user.house,
                    room: this.user.room
                })
            })
            .then(res => res.json())
            .then(data => {
                if(data.err)
                {
                    alert('Данные не сохранены')
                    return;
                }
                if(data.user)
                {
                    alert('Данные сохранены')
                    this.$store.commit('authUser', data.user)
                }
            })
        }
    }
}
</script>

<style scoped>
.btn-drop-account {
    background: linear-gradient(#eeeeee,#bbbbbb);
    text-transform: none;
    border: 1px solid #eee;
    line-height: normal;
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
}
</style>