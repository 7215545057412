<template>
    <v-dialog v-model="visibleC">
        <div class="modal-select">
            <div class="modal-info">
                <div class="modal-title">
                    Введите код с картинки
                </div>
                <div class="modal-message">
                    <img :src="captchaImageUrl" v-if="captchaImageUrl" />
                    <br>
                    <input class="input-text text-center" v-model="captcha" />
                </div>
            </div>
            <div class="button active last" @click="onTapSubmit">Ок</div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: ['visible','items','phone'],
    data(){
        return {
            captcha: ''
        }
    },
    computed:{
        captchaImageUrl(){
            if(!this.visible) return '';
            console.log('captchaImageUrl')
            return this.$serverUrl + '/captcha/' + this.phone+'?'+Date.now();
        },
        visibleC:{
            set(v){
                if(!v){
                    this.$emit('hide');
                }
            },
            get(){
                return this.visible
            }
        }
    },
    methods: {
        onTapSubmit(){
            this.$emit('input',{
                captcha: this.captcha
                // id: 0
            })
        }
    }
}
</script>

<style scoped>
.address-list {
    max-height: 300px;
    overflow: auto;
    border-top: 1px solid #eee;
}

.address-list-item {
    padding: 10px 10px;
    border-bottom: 1px solid #eee;
}
</style>