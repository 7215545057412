import Vue from 'vue'

var loadSettings = function(state){
    return new Promise((resolve, reject) => {
        fetch(Vue.prototype.$assetsUrl + '/settings.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(data => {
            state.settings = data;
            resolve(true);
        }).catch((e)=>{
            reject(e);
        });
    });
    // getJSON(this.$serverUrl + '/settings.php').then(r=>{
    //     this.$store.state.settings = r;
    //   });
}

export {
    loadSettings
}