<template>
    <v-dialog v-model="$store.state.kioskIdleVisible">
        <div class="modal-select">
            <div class="modal-info">
                <div class="modal-title">
                    Вернуться на главный экран?
                </div>
            </div>
            <div class="button" @click="onTapContinue">
                Продолжить заказ
            </div>
            <div class="button active" @click="onTapMain">
                Вернуться на главный экран ({{sec}})
            </div>
            <!-- <div  v-for="(item, index) in items" :key="index" -->
             <!-- class="button" :class="{last:index==items.length-1}" @click="onTapItem(item)">{{item.name?item.name:item}}</div> -->
        </div>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            sec: 0
        }
    },
    created(){
        this.$watch(
            () => this.$store.state.kioskIdleVisible,
            (v) => {
                if(v){
                    this.startTimer()
                }
                else
                {
                    this.stopTimer()
                }
            }
        )
    },
    methods: {
        onTapMain(){
            this.stopTimer();
            this.$router.push('/');
        },
        onTapContinue(){
            this.stopTimer();
            this.$store.state.kioskIdleVisible = false;
        },
        startTimer(){
            this.stopTimer();
            this.sec = 10;
            window.idleTimer = setInterval(()=>{
                if(this.sec > 1)
                {
                    this.sec = this.sec - 1;
                }else{
                    this.stopTimer();
                    this.onTapMain();
                }
            }, 1000)
        },
        stopTimer(){
            if(window.idleTimer){
                clearInterval(window.idleTimer);
                window.idleTimer = false;
            }
        }
    }
}
</script>