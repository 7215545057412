<template>
    <div v-show="cartsum > 0" class="btn btn-big btn-primary cart-button" @click="tapcart">
        {{text}}
    </div>
</template>

<script>
export default {
    computed: {
        cartsum(){
            return this.calcCartSum();
        },
        text(){
            return 'Ваш заказ на '+this.cartsum+' ₽';
        }
    },
    methods: {
        tapcart(){
            this.$router.push('/cart');
        }
    }
}
</script>

<style scoped>
.cart-button {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
</style>