<template>
    <div class="page-rate-list">
        <TopBar :showMenu="true" title="Мои оценки"/>
        <div class="scrollview">
            <div class="rate-list">
                <div v-for="item in items" :key="item.id" class="rate-list-item">
                    <div style="font-size:12px;text-align:right;">
                        {{item.time}}
                    </div>
                    <div style="font-weight:bold;">
                        {{item.name}}
                    </div>
                    <div v-if="item.spec_name" style="font-size:12px;margin-top:5px;">
                        {{'+ '+item.spec_name}}
                    </div>

                    <div class="mb-5px d-flex" style="align-items:center;margin:10px 0;">
                        <div style="width:90px">Вкус:</div>
                        <StarRating :active="false" :value="item.rate_taste" />
                    </div>
                    <div class="mb-5px d-flex" style="align-items:center;margin:10px 0;">
                        <div style="width:90px">Свежесть:</div>
                        <StarRating :active="false" :value="item.rate_fresh" />
                    </div>
                    <div class="mb-5px d-flex" style="align-items:center;margin:10px 0;">
                        <div style="width:90px">Скорость:</div>
                        <StarRating :active="false" :value="item.rate_speed" />
                    </div>
                    <div>
                        {{item.comment}}
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue'
import StarRating from '../components/StarRating';
export default {
    components: {TopBar,StarRating},
    created(){
        this.loadItems();
    },
    data(){
        return {
            items: [],
            proccessing: false
        }
    },
    methods: {
        loadItems(){
            if(this.proccessing) return;
            this.proccessing = true;
            fetch(this.$serverUrl + '/api/v1/rate/list',{
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    token: this.$store.state.user.password,
                })
            })
            .then(r=>r.json())
            .then(r => {
                this.proccessing = false;
                this.items = r.items;
            })
        }
    }
}
</script>

<style scoped>
.rate-list-item {
    border-bottom:1px solid #0071E5;
    padding: 10px;
}
</style>