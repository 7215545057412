<template>
    <div style="min-height:100vh" class="px-5 bg-inversed flex-column d-flex align-center justify-center overflow-auto">
        <Logo style="height:45px" class="my-5"/>
        <div v-show="!smsSended && authType=='inbound'">
            <div class="text-center text-light text-subtitle mb-5">
                Пожалуйста укажите номер вашего телефона.<br>
                Для подтверждения номера необходимо совершить исходящий звонок на специальный номер в течение 1 минуты.
            </div>
            <InputPhone v-model="phone" class="mb-5" @filled="onPhoneInput" />
        </div>
        <div v-show="smsSended && authType=='inbound'">
            <div class="text-center text-light text-subtitle mb-5">
                Пожалуйста, совершите дозвон с вашего телефона ({{ phoneFormat(phone) }}) по указанному телефону в течение 1 минуты:
            </div>
            <div  class="text-center" style="margin-bottom:20px"  v-if="inboundQr">
                <img :src="inboundQr" width="100px" class="mb-20" />
            </div>
            <div class="text-center" >
                <a class="inbound-phone  mb-20" :href="'tel://+'+inboundPhone" style="text-decoration:none;">
                    {{phoneFormat(inboundPhone)}}
                </a>
            </div>
            <div class="text-center" >
                <a class="text-center text-white text-h3 mb-20 btn-call" :href="'tel://+'+inboundPhone">Позвонить</a>
            </div>
            <div class="text-center text-white text-h3" @click="tapResend"
                >Запросить новый номер</div>
            <div class="text-center text-white text-h3" @click="changePhone"
                >Изменить номер телефона</div>
        </div>
        <div v-show="!smsSended && authType=='email'">
            <div class="text-center text-light text-subtitle mb-5">
                Укажите e-mail,<br>На него придет проверочный код.
            </div>
            <div style="margin:0 0 20px 0">
                <input type="text" v-model="email" placeholder="E-mail" class="input-text" >
            </div>
        </div>
        <div v-show="smsSended && authType=='email'">
            <div class="text-center text-light text-subtitle mb-5">
                Введите проверочный код,<br>отправленный на email:<br>{{email}}
            </div>
            <InputCode v-model="code" ref="code" class="mb-5" @filled="onCodeInput" />
            

<!-- <Label :text="'Введите проверочный код,\nотправленный на email:\n'+email" textWrap="true" class="text-center text-h3 text-white mb-20" @tap="blurTextfield" />
                        <MaskedTextField keyboardType="phone" mask="0000" hint="____" 
                            ref="code_email" class="phone-textfield mb-20" @textChange="onChangeCode" :editable="!proccessing"
                            @returnPress="onReturnCode" />
                        <Label :text="errorText" v-show="!!errorText" class="text-center text-yellow mb-20" textWrap="true" />
                        <Label text="Изменить email" class="text-center text-white text-h3" @tap="changeEmail"
                            marginTop="20"
                        /> -->
        </div>
        <div v-show="!smsSended && authType=='phone'">
            <div class="text-center text-light text-subtitle mb-5">
                Укажите номер вашего телефона,<br>
                На него поступит звонок от робота, можете не отвечать на него, запомните последние 4 цифры.
            </div>
            <InputPhone v-model="phone" class="mb-5" @filled="onPhoneInput" />
        </div>
        <div v-if="smsSended && authType=='phone'">
            <div class="text-center text-subtitle text-white mb-5">
                Введите 4 последних символа<br>номера входящего звонка на телефон:<br> {{ phoneFormat(phone) }}
            </div>
            <InputCode v-model="code" ref="code" class="mb-5" @filled="onCodeInput" />
        </div>
        <div v-show="errorText" class="text-center text-yellow mb-5">
            {{errorText}}
        </div>
        <div v-show="!smsSended && authType=='email'" @click="submitEmail" class="text-center text-white text-h3 mb-20">
            Готово
        </div>
        <div v-show="smsSended && authType=='email'" @click="changeEmail" class="text-center text-white text-h3 mb-20">
            Изменить email
        </div>
        <div v-show="errorText && !smsSended" @click="onPhoneInput" class="text-center text-white text-h3 mb-20">
            Отправить повторно
        </div>
        <div v-show="$store.state.settings.allow_email_auth && authType != 'email'" @click="tapAuthEmail" class="text-center text-white text-h3 mb-20">
            Авторизация по E-mail
        </div>
        <div v-show="authType != 'phone'" @click="tapAuthPhone" class="text-center text-white text-h3 mb-20">
            Авторизация по входящему звонку
        </div>
        <div v-show="$store.state.settings.allow_inbound_auth && authType != 'inbound'" @click="tapAuthInbound" class="text-center text-white text-h3 mb-20">
            Авторизация по исходящему звонку
        </div>
        <div class="text-center conditions">
            При входе или регистрации вы принимаете условия <strong><a href="https://docs.google.com/document/d/e/2PACX-1vRykhFzvF39jzgfJKZyHxzY5aIdVTVQtBMT6HxkKRZtaVjNXvAb10EfZFmonPTehY_Z0Lq6is6hcJQ-/pub" target="_blank">пользовательского соглашения</a></strong>.
        </div>
        <CaptchaInputDialog :visible="captchaModalVisible" :phone="authType=='email'?this.email:this.phone" 
            @hide="captchaModalVisible=false" @input="onCaptchaInput" />
    </div>
</template>

<script>
import Logo from '../components/Logo';
import InputPhone from '../components/InputPhone';
import InputCode from '../components/InputCode';
import CaptchaInputDialog from '../components/CaptchaInputDialog.vue';
var inboundTimer = null;
export default {
    components: {Logo, InputPhone, InputCode, CaptchaInputDialog},
    data(){
        return {
            phone: '',
            proccessing: false,

            code: '',
            errorText: '',
            message: '',
            smsSended: false,
            ready: false,
            authType: 'inbound',
            email: '',
            emailCode: '',
            password: '',
            newPassword: '',
            forgot: false,
            captchaModalVisible: false,
            captcha: '',
            inboundPhone: '',
            inboundQr: '',
            inboundCallId: ''
        }
    },
    methods: {
        changeEmail()
        {
            this.emailSended = false;
            this.smsSended = false;
            this.code = '';
            this.errorText = '';
            this.email = '';
        },
        submitEmail(){
            var emailValid = this.email.length > 3;

            if(emailValid && !this.proccessing)
            {
                this.clearInboundTimer();

                if(!this.captcha.length){
                    this.captchaModalVisible = true;
                    return;
                }

                this.proccessing = true;

                fetch(this.$serverUrl + '/api/v2/user/sendEmailCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        captcha: this.captcha,
                        email: this.email,
                        pushToken: this.$store.state.pushToken
                    })
                })
                .then(res => res.json())
                .then(data => {
                    this.captcha = '';
                    if(data.error)
                    {
                        this.errorText = data.error;
                    }
                    else
                    {
                        this.smsSended = true;
                        this.errorText = '';
                    }
                    this.proccessing = false;
                }).catch(() => {
                    this.errorText = 'Ошибка запроса. Проверьте подключение к интернету или попробуйте позже.';
                    this.proccessing = false;
                })
            }
            
            if(!emailValid)
            {
                this.errorText = 'Введите верный email.';
            }
            
            if(this.proccessing)
            {
                this.errorText = 'Идет обработка запроса, подождите...';
            }
        },
        changePhone()
        {
            this.clearInboundTimer();
            this.smsSended = false;
            this.code = '';
            this.errorText = '';
            this.phone = '';
            // this.$refs.phone.nativeView.text = '';
            // this.$refs.phone_2.nativeView.text = '';
            // this.$refs.phoneInbound.nativeView.text = '';
        },
        tapResend()
        {
            this.clearInboundTimer();
            this.smsSended = false;
            this.code = '';
            this.errorText = '';
            this.onPhoneInput();
        },
        tapAuthEmail(){
            this.clearInboundTimer();
            this.errorText = '';
            this.message = '';
            this.proccessing = false;

            this.authType = 'email';
            this.forgot = false;
            this.smsSended = false;
            this.email = '';
        },
        tapAuthPhone(){
            this.clearInboundTimer();
            this.errorText = '';
            this.message = '';
            this.proccessing = false;

            this.authType = 'phone';
            this.forgot = false;
            this.smsSended = false;
            this.phone = '';
        },
        tapAuthInbound(){
            this.errorText = '';
            this.message = '';
            this.proccessing = false;
            this.authType = 'inbound';
            this.forgot = false;
            this.smsSended = false;
            this.phone = '';
        },
        checkInbound(){
            this.clearInboundTimer();
            fetch(this.$serverUrl + '/api/v2/user/check_inbound',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    callId: this.inboundCallId,
                    phone: this.phone
                })
            })
            .then(r => r.json())
            .then(data => {
                console.log('check_inbound  data',data);
                this.clearInboundTimer();

                if(data.success){
                    this.$store.commit('authUser', data.user)
                    this.$router.push({name:'OrderType'})
                    
                    this.errorText = '';
                }else{
                    inboundTimer = setTimeout(()=>{
                        this.checkInbound();
                    }, 10 * 1000);
                }
            });
        },
        clearInboundTimer(){
            if(inboundTimer){
                clearTimeout(inboundTimer);
                inboundTimer = null;
            }
        },
        onCaptchaInput(data){
            console.log('onCaptchaInput', data)
            if(!data.captcha){
                alert('Код не введен!');
                return;
            }
            this.captchaModalVisible = false;
            this.captcha = data.captcha;
            if(this.authType == 'email'){
                this.submitEmail();
            }else{
                this.submitPhone();
            }
        },
        phoneFormat(phone)
        {
            return '+' + phone[0] + ' ' + phone[1]+''+phone[2]+''+phone[3] + ' ' + phone[4]+''+phone[5]+''+phone[6] + ' ' + phone[7]+''+phone[8] + ' ' + phone[9]+''+phone[10];
        },
        onPhoneInput(){

            this.submitPhone();
        },
        onCodeInput(){
            if(this.authType == 'email'){
                this.submitCodeEmail();
            }else{
                this.submitCode();
            }
        },
        submitAuthInbound(){
            this.inboundPhone = '';
            this.inboundQr = '';
            this.errorText = '';
            fetch(this.$serverUrl + '/api/v2/user/auth_inbound', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    phone: this.phone,
                    captcha: this.captcha,
                    authType: this.authType,
                    pushToken: this.$store.state.pushToken
                })
            })
            .then(r => r.json())
            .then(data => {
                console.log('auth_inbound data',data);
                if(data.error)
                {
                    this.errorText = data.error;
                }
                else
                {
                    this.smsSended = true;
                    this.errorText = '';

                    this.inboundPhone = data.number;
                    this.inboundQr = data.qr;
                    this.inboundCallId = data.uid;

                    this.checkInbound();
                    
                    setTimeout(()=>{
                        // if(this.authType == 'phone'){
                        //     this.$refs.code.nativeView.focus();
                        // }else{
                        //     this.$refs.code_2.nativeView.focus();
                        // }
                    },200)
                }
                this.proccessing = false;
            }).catch(() => {
                this.errorText = 'Ошибка запроса. Проверьте подключение к интернету или попробуйте позже.';
                this.proccessing = false;
            })
        },
        submitPhone(){
            this.clearInboundTimer();
            if(this.phone.length == 11 && !this.proccessing)
            {
                if(this.authType == 'inbound'){
                    this.proccessing = true;
                    this.submitAuthInbound();
                    return;
                }

                if(!this.captcha.length){
                    this.captchaModalVisible = true;
                    return;
                }
                
                this.proccessing = true;
            
                fetch(this.$serverUrl + '/api/v1/user/sendSms', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        captcha: this.captcha,
                        phone: this.phone,
                        authType: this.authType,
                        pushToken: this.$store.state.pushToken
                    })
                })
                .then(res => res.json())
                .then(data => {
                    this.captcha = '';
                    if(data.error)
                    {
                        this.errorText = data.error;
                    }
                    else
                    {
                        this.smsSended = true;
                        this.errorText = '';
                    }
                    this.proccessing = false;
                }).catch(() => {
                    this.errorText = 'Ошибка запроса. Проверьте подключение к интернету или попробуйте позже.';
                    this.proccessing = false;
                })
            }
            
            if(this.phone.length != 11)
            {
                this.errorText = 'Проверьте правильность ввода телефона.';
            }
            
            if(this.proccessing)
            {
                this.errorText = 'Идет обработка запроса, подождите...';
            }
        },
        submitCodeEmail(){
            if(this.code.length == 4 && !this.proccessing)
            {
                this.proccessing = true;
            
                fetch(this.$serverUrl + '/api/v2/user/checkEmailCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: this.email,
                        code: this.code,
                        pushToken: this.$store.state.pushToken
                    })
                })
                .then(res => res.json())
                .then(data => {
                    if(data.error)
                    {
                        this.errorText = data.error;
                    }
                    else
                    {
                        this.$store.commit('authUser', data.user)
                        this.$router.push({name:'OrderType'})
                        // this.$navigateTo(this.$routes.OrderType,{
                        //     clearHistory: true
                        // })
                        
                        this.errorText = '';
                    }
                    this.proccessing = false;
                }).catch(() => {
                    this.errorText = 'Ошибка запроса. Проверьте подключение к интернету или попробуйте позже.';
                    this.proccessing = false;
                })
            }
            
            if(this.code.length != 6)
            {
                this.errorText = 'Проверьте правильность ввода кода.';
            }
            
            if(this.proccessing)
            {
                this.errorText = 'Идет обработка запроса, подождите...';
            }
        },
        submitCode()
        {
            if(this.code.length == 4 && !this.proccessing)
            {
                this.proccessing = true;
            
                fetch(this.$serverUrl + '/api/v1/user/checkSms', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        phone: this.phone,
                        authType: this.authType,
                        code: this.code,
                        pushToken: this.$store.state.pushToken
                    })
                })
                .then(res => res.json())
                .then(data => {
                    if(data.error)
                    {
                        this.errorText = data.error;
                    }
                    else
                    {
                        this.$store.commit('authUser', data.user)
                        this.$router.push({name:'OrderType'})
                        // this.$navigateTo(this.$routes.OrderType,{
                        //     clearHistory: true
                        // })
                        
                        this.errorText = '';
                    }
                    this.proccessing = false;
                }).catch(() => {
                    this.errorText = 'Ошибка запроса. Проверьте подключение к интернету или попробуйте позже.';
                    this.proccessing = false;
                })
            }
            
            if(this.code.length != 6)
            {
                this.errorText = 'Проверьте правильность ввода кода.';
            }
            
            if(this.proccessing)
            {
                this.errorText = 'Идет обработка запроса, подождите...';
            }
        },
    }
}
</script>

<style scoped>
.btn-call {
    color: #0071E5;
    background-color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    padding:7px 20px;
    text-decoration: none;
}

.inbound-phone {
    font-size: 24px;
    color: #fff;
    text-decoration: none;
}

.conditions {
    position: absolute;
    bottom: 80px;
    left: 16px;
    right: 16px;
    color: #fff;
}

.conditions a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
}
</style>