<template>
    <div class="page-address-list">
        <TopBar :showMenu="true" title="Мои адреса" />

        <div class="scrollview" style="padding:20px 10px;">
            <div v-for="item in items" :key="item.id" class="address-list-item"  @click="editAddress(item)">
                <div>
                    {{item.full}}
                </div>
                <div>
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue'
export default {
    components: {TopBar},
    data(){
        return {
            items: [],
            proccessing: false
        }
    },
    methods: {
        loadItems(){
            this.proccessing = true;
            this.getUserAddresses().then(res => {
                this.items = res.result;
                this.proccessing = false;
            }).catch(()=>{
                this.proccessing = false;
            })
        },
        editAddress(item){
            this.$router.push('/address_list/'+item.id)
        }
    },
    created(){
        this.loadItems()
    }
}
</script>

<style scoped>
.address-list-item {
    margin-bottom:15px;
    padding-bottom:15px;
    border-bottom:1px solid #C1D0E1;

    display: flex;
    justify-content: space-between;
}
</style>