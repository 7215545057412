<template>
    <div class="btn btn-small mt-5px" @click="$emit('click')">
        <div class="text-center">
            {{sum + text}}
        </div>
    </div>
</template>

<script>
export default {
    props: ['text', 'sum']
}
</script>