import createActivityDetector from 'activity-detector';

export default{
    methods: {
        stopActivitiDetector(){
            this.$store.state.kioskIdleVisible = false;
            if(window.activityDetector){
                window.activityDetector.stop();
            }
        },
        initActivityDetector(){
            window.activityDetector = createActivityDetector({
                timeToIdle: 15000
            });
            window.activityDetector.on('idle', ()=>{
                if(this.$route.name != 'OrderType'){
                    this.$store.state.kioskIdleVisible = true;
                }
            })
        },
        getRestorans(){
            return new Promise((resolve, reject) => {
                if(this.$store.state.restorans.length > 0){
                    resolve(this.$store.state.restorans);
                }else{
                    // this.request()
                    fetch(this.$serverUrl + '/api/v2/restoran/list', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(res => res.json())
                    .then(data => {
                        this.$store.state.restorans = data;
                        resolve(this.$store.state.restorans)
                    }).catch((e)=>{
                        reject(e);
                    })
                }
            });
        },
        calcCartSum(){
            var sum = 0;
            this.$store.state.cart.forEach(cart => {
                // console.log('cartItem', JSON.stringify(cart))
                const index = this.$store.state.dishes.findIndex(item => item.id == cart.item.id && !!item.allowed);
                if(index >=0){
                    sum += this.$store.state.dishes[index].price * cart.cnt;
                    // if(!!cart.spec && !!cart.spec.specid){
                    //     const spec_index = this.$store.state.dishes[index].spec.findIndex(item => item.specid == cart.spec.specid && !item.disabled);

                    //     if(spec_index >= 0){
                    //         sum += this.$store.state.dishes[index].spec[spec_index].price * cart.cnt;
                    //     }
                    // }
                    if(cart.spec && cart.spec.specid){
                        const spec_index = this.$store.state.dishes[index].spec.findIndex(item => item.specid == cart.spec.specid && !item.disabled);

                        if(spec_index >= 0){
                            sum += this.$store.state.dishes[index].spec[spec_index].price * cart.cnt;
                        }
                    }
                    if(cart.specs && cart.specs.length > 0){
                        cart.specs.forEach(spec => {
                            const spec_index = this.$store.state.dishes[index].spec.findIndex(option => option.specid == spec.specid && !option.disabled);

                            if(spec_index >= 0){
                                sum += this.$store.state.dishes[index].spec[spec_index].price * spec.cnt * cart.cnt;
                            }
                        })
                    }
                }
            })

            return sum;
        },
        number_format( number, decimals, dec_point, thousands_sep ) {	// Format a number with grouped thousands
            // 
            // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
            // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
            // +	 bugfix by: Michael White (http://crestidg.com)
        
            var i, j, kw, kd, km;
        
            // input sanitation & defaults
            if( isNaN(decimals = Math.abs(decimals)) ){
                decimals = 2;
            }
            if( dec_point == undefined ){
                dec_point = ",";
            }
            if( thousands_sep == undefined ){
                thousands_sep = ".";
            }
        
            i = parseInt(number = (+number || 0).toFixed(decimals)) + "";
        
            if( (j = i.length) > 3 ){
                j = j % 3;
            } else{
                j = 0;
            }
        
            km = (j ? i.substr(0, j) + thousands_sep : "");
            kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
            //kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : "");
            kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");
    
            return km + kw + kd;
        },
        priceFormat(price){
            return this.number_format(price, 2, ',', '');
        },
        create_UUID(){
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (dt + Math.random()*16)%16 | 0;
                dt = Math.floor(dt/16);
                return (c=='x' ? r :(r&0x3|0x8)).toString(16);
            });
            return uuid;
        },
        showDialog(props){
            if(props.items && props.items.length >= 1){
                this.$store.state.dialogItems = {
                    show: true,
                    title: props.title?props.title:'',
                    message: props.message?props.message:'',
                    items: props.items,
                    active: props.active>=0?props.active:null,
                    result: null
                }
                return new Promise(resolve => {
                    this.$watch(
                        ()=>this.$store.state.dialogItems.show,
                        ()=>{
                            resolve(this.$store.state.dialogItems.result)
                        }
                    )
                })
            }
            var msg = '';
            if(props.title) msg = props.title;

            if(props.message) {
                if(msg) {
                    msg += '\n';
                }

                msg += props.message;
            }
            alert(msg)
        },
        clearNotification(){

        },
        isWorkNow(workTime)
        {
            var today = new Date();
            var curHour = today.getHours();
            var curMinute = today.getMinutes();
            var curDay = today.getDay();
            if(curDay==0) 
                curDay = 7;
            if(curHour < 6)
            {
                curHour += 24;
                curDay -= 1;
                if(curDay==0) 
                    curDay = 7;
            }

            curMinute += curHour * 60;

            var worknow = false;

            if(!workTime || !workTime[curDay - 1])
                return false;
                
            const day  = workTime[curDay - 1];
            if(curMinute >= day.fromMinute && curMinute < day.toMinute)
            {
                worknow = true;
            }

            return worknow;
        },
        checkLockCode() {
            return new Promise((resolve, reject) => {
                if(this.$store.state.lockCode){
                    resolve(true);
                    return;
                }
                reject();
                // this.showModal(LockedCode,{
                //     fullscreen: false,
                //     animated: false
                // }).then(result=>{
                //     if(!!result && !!result.success)
                //     {
                //         resolve(true);
                //     }
                //     else
                //     {
                //         resolve(false);
                //     }
                // }).catch(e => {
                //     resolve(false);
                // })
            })
        },
        showModalSpec(item){
            this.$store.state.specModal.item = item;
            this.$store.state.specModal.show = true;
        },
        timeFormat(time){
            var m = Math.ceil((time % 3600) / 60);
            var h = (time - (time % 3600)) / 3600;
            if(m < 10) m = '0' + m;
            if(h < 10) h = '0' + h;

            return h+':'+m;
        },
        dateFormatFull(date){
            var wkday = date.getDay();
            var day = date.getDate();
            var month = date.getMonth();
            switch(wkday){
                case 0:
                    wkday = 'Воскресенье';
                    break;
                case 1:
                    wkday = 'Понедельник';
                    break;
                case 2:
                    wkday = 'Вторник';
                    break;
                case 3:
                    wkday = 'Среда';
                    break;
                case 4:
                    wkday = 'Четверг';
                    break;
                case 5:
                    wkday = 'Пятница';
                    break;
                case 6:
                    wkday = 'Суббота';
                    break;
            }

            switch(month){
                case 0: month = 'января'; break;
                case 1: month = 'февраля'; break;
                case 2: month = 'марта'; break;
                case 3: month = 'апреля'; break;
                case 4: month = 'мая'; break;
                case 5: month = 'июня'; break;
                case 6: month = 'июля'; break;
                case 7: month = 'августа'; break;
                case 8: month = 'сентября'; break;
                case 9: month = 'октября'; break;
                case 10: month = 'ноября'; break;
                case 11: month = 'декабря'; break;
            }

            return wkday + ', ' + day + ' ' + month + ' ' + date.getFullYear();
        },
        dateFormat(date){
            var result = '';
            var day = date.getDate();
            var month = date.getMonth()+1;
            var year = date.getFullYear();
            if(day<10){
                result += '0'+day;
            }else{
                result += day;
            }

            if(month<10){
                result += '.0'+month;
            }else{
                result += '.'+month;
            }

            result += '.'+year;

            return result;
        },

        validatePhone(phone){
            return /^\d{11}$/.test(phone);
        },
        validateEmail(email) {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


            return String(email).toLowerCase().match(validRegex);
        },
        getUserAddresses(){
            return fetch(this.$serverUrl + '/api/v2/user/address/list',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    user_token: this.$store.state.user.password
                })
            }).then(res => res.json())
        },
        formatColor(color){
            if(color.length == 9){
                return '#'+color.substring(3)+color.substring(1,3);
            }else{
                return color;
            }
        },
        request({url='/rest/',action,fields}){
            return fetch(this.$serverUrl + url,{
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    token: this.$store.state.user.password,
                    action,
                    fields,
                    version : this.$appVersion
                })
            })
            .then(res => res.json())
        },
        inside(point, vs) {
            var x = parseFloat(point[1]), y = parseFloat(point[0]);
    
            var inside = false;
            for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
                var xi = parseFloat(vs[i][0]), yi = parseFloat(vs[i][1]);
                var xj = parseFloat(vs[j][0]), yj = parseFloat(vs[j][1]);
    
                var intersect = ((yi > y) != (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                if (intersect) inside = !inside;
            }
    
            return inside;
        },
        getLocationZone(lon, lat){
            let resultRestoran = null;
            let resultZone = null;

            const restorans = this.$store.state.restorans.filter(item => item.deliveryCourier == '1');

            restorans.forEach(restoran => {
                restoran.deliveryZone.forEach(zone => {
                    const coords = JSON.parse(zone.polygon);

                    if(this.inside([lon,lat],coords[0])){
                        resultRestoran = restoran;
                        resultZone = zone;
                    }
                })
            })

            return {
                restoran: resultRestoran,
                zone: resultZone
            }
        },
        getLocation(){
            return new Promise((resolve,reject)=>{
                this.$getLocation()
                    .then(coordinates => {
                        console.log(coordinates);
                        resolve(coordinates)
                    })
                    .catch(e => {
                        console.log(e)
                        reject(e)
                    })
            })
        }
    }
}