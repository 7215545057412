<template>
    <div class="cart-paysystem">
        <div class="mb-10px">
            Способ оплаты
        </div>
        <div class="checkbox-item mb-10px" :class="{active:value=='sbp'}" @click="setPS('sbp')">
            <img src="../assets/icon-sbp-0.png" v-show="value!='sbp'">
            <img src="../assets/icon-sbp-1.png" v-show="value=='sbp'">
            <span>
                СБП
            </span>
            <img src="../assets/icon-check-blue.png" v-show="value=='sbp'" />
        </div>
        <div class="checkbox-item mb-10px" :class="{active:value=='card'}" @click="setPS('card')" v-show="!$store.state.kiosk">
            <i class="far fa-credit-card"></i>
            <span>
                Картой онлайн
            </span>
            <img src="../assets/icon-check-blue.png" v-show="value=='card'" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    data(){
        return {
            paysystems: [
                {
                    value: 'sbp',
                    name: 'СБП',
                    img: '../assets/icon-sbp-0.png',
                    img_active: '../assets/icon-sbp-1.png'
                }
            ],
            bindings: []
        }
    },
    created(){
        this.$emit('input', 'sbp');
        this.loadBindings();
    },
    methods: {
        setPS(ps){
            this.$emit('input', ps)

            if(ps == 'card'){
                this.selectBinding();
            }
        },
        loadBindings(){
            fetch(this.$serverUrl + '/api/v1/order/payment/bindings',{
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    token: this.$store.state.user.password,
                    fs_uid: this.$store.state.restoran.fs_uid
                })
            })
            .then(r => r.json())
            .then(result => {
                this.bindings = result.bindings;
            });
        },
        selectBinding(){
            // console.log('selectBinding', JSON.stringify(this.bindings))
        }
    }
}
</script>