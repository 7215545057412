<template>
    <div class="menu-categories-slider">
        <div class="menu-categories-btn" @click="onTapInc(-1)">
            <i class="fas fa-chevron-left"></i>
        </div>
        <div class="menu-categories-list scrollbar-hidden">
            <div v-for="(item,index) in items"
                class="menu-item"
                :class="{active: item.id==active, inactive: item.id!=active}"
                :key="item.id"  @click="onTap(item,index)" :id="'categoryItem' + item.id"
                :ref="'categoryItem' + item.id"
                >
                <span :class="{'font-europeext-bold': item.id==active, 'font-europeext': item.id!=active}"
                    class="menu-item-text">
                    {{item.name}}
                </span>
                <span  class="menu-item-flag" v-show="item.id==active" ></span>
            </div>
        </div>
        <div class="menu-categories-btn" @click="onTapInc(+1)">
            <i class="fas fa-chevron-right"></i>
        </div>
    </div>
</template>

<script>
export default {
    props:  {
        items: {
            type: Array,
            default: ()=>[]
        },
        active: {
            type: Number,
            default: 0
        }
    },
    watch: {
        active(v){
            let [obj] = this.$refs['categoryItem' + v];

            // console.log('active',v, obj)

            obj.scrollIntoView()
        }
    },
    methods: {
        onTap(item){
            this.$emit('change',item.id)
        },
        onTapInc(cnt){
            const index = this.items.findIndex(el => el.id == this.active);
            if(index + cnt >= 0 && this.items[index + cnt]){
                this.$emit('change',this.items[index + cnt].id)
            }
        }
    }
}
</script>

<style scoped>
.menu-categories-slider {
position: fixed;
    top: 103px;
    background: #fff;
    box-shadow: 0 0 5px #333;
    z-index: +1;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
}

.menu-categories-list {
    height: 35px;
    overflow: auto;
    white-space: nowrap;
    border-bottom:2px solid #01315d;
    flex-grow: 1;
}

.menu-item {
    display: inline-block;
    position: relative;
}

.menu-categories-btn {
    width: 30px;
    height: 30px;
    font-size:23px;
    color:#000;

    border: 1px solid #000;
    border-radius: 50%;
    margin: 5px 10px 5px 10px;
    text-align:center;
    line-height: 30px;
    flex: 0 0 30px;
}

.menu-categories-btn i {
    line-height: 28px;
}

.menu-item-text
{
    font-size: 14px;
    padding: 10px 15px;
    display: inline-block;
}
.menu-item.active .menu-item-text
{
    color: #f75d19;
}
.menu-item.inactive .menu-item-text
{
    color: #01315d;
}

.menu-item.active .menu-item-flag
{
    background-color: #f75d19;
    height: 5px;
    border-radius: 5px 5px 0 0;
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
}
</style>