<template>
    <v-dialog v-model="visibleC" content-class="bottom-dialog"
            transition="dialog-bottom-transition">
        <div class="modal-window">
            <div class="text-t1 text-center mb-5px">
                {{'Заказ '+order.znumber}}
            </div>
            <StarRating class="mb-10px" align="center" v-model="stars"/>
            <div class="rate-feature-list">
                <div class="rate-feature-item" @click="rate_taste=!rate_taste" 
                    :class="{active: rate_taste}">
                    <i class="far fa-smile-beam"></i>
                    <label>Вкус</label>
                </div>
                <div class="rate-feature-item" @click="rate_fresh=!rate_fresh" 
                    :class="{active: rate_fresh}">
                    <i class="fas fa-sparkles"></i>
                    <label>Чистота</label>
                </div>
                <div class="rate-feature-item" @click="rate_speed=!rate_speed" 
                    :class="{active: rate_speed}">
                    <i class="fas fa-running"></i>
                    <label>Скорость</label>
                </div>
                <div class="rate-feature-item" @click="rate_quality=!rate_quality" 
                    :class="{active: rate_quality}">
                    <i class="fas fa-crown"></i>
                    <label>Качество блюд</label>
                </div>
            </div>

            <div class="attach-list">
                <!-- <img src="../assets/qr_img.jpeg" alt="" class="attach-item"/>
                <img src="../assets/im1.png" alt="" class="attach-item"/> -->
                <img :src="item" v-for="(item, index) in photos" :key="index" class="attach-item"/>
                <div class="camera-btn" @click="onTapCamera">
                    <i class="fas fa-camera"></i>
                </div>
                <div class="attach-btn" @click="onTapAttach">
                    <i class="fas fa-paperclip"></i>
                    <input type="file" @change="onFileAttach" accept="image/jpeg,image/png,image/gif">
                </div>
            </div>

            <RatingTypeSelect v-if="!!types && stars<5" :types="types" v-model="type" key="RatingTypeSelect" />

            <textarea placeholder="Комментарий" class="input-text mb-15px" style="height:100px;padding:10px" v-model="comment"></textarea>
            <div class="btn btn-primary mb-15px" @click="onSubmit">Оставить оценку</div>
        </div>
        <CameraInput :visible="cameraVisible" @hide="cameraVisible = false" v-if="cameraVisible" @input="onCameraInput" />
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
import StarRating from '../components/StarRating.vue';
import CameraInput from '../components/CameraInput.vue';
import RatingTypeSelect from '../components/RatingTypeSelect.vue';
export default {
    props: ['visible','order','order_rating'],
    components: {
        StarRating,
        CameraInput,
        RatingTypeSelect
    },
    data(){
        return {
            stars: 5,
            rate_taste: false,
            rate_fresh: false,
            rate_speed: false,
            rate_quality: false,
            photos: [],
            comment: '',
            proccessing: false,
            types: null,
            type: null,
            cameraVisible: false
        }
    },
    computed:{
        visibleC:{
            set(v){
                if(!v){
                    this.$emit('hide');
                }
            },
            get(){
                return this.visible
            }
        }
    },
    created(){
        this.loadTypes()
    },
    watch: {
        visible(v){
            if(v){
                this.init();
            }
        }
    },
    methods: {
        loadTypes(){
            if(this.types != null) return;
            
            this.request({
                action: 'rateGetTypes'
            }).then(r => {
                if(r && r.sections && r.items)
                {
                    this.types = r;
                }
            })
        },
        onTapAttach(){

        },
        onFileAttach(e){
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload =  () => {
                this.photos.push(reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        },
        onTapCamera(){
            this.cameraVisible = true;
        },
        onCameraInput(v){
            this.photos.push(v);
            this.cameraVisible = false;
        },
        init(){
            if(this.order && this.order.rate){
                this.stars = this.order.rate.stars;
                this.comment = this.order.rate.comment;
                this.rate_taste = this.order.rate.rate_taste;
                this.rate_fresh = this.order.rate.rate_fresh;
                this.rate_speed = this.order.rate.rate_speed;
                this.rate_quality = this.order.rate.rate_quality;
            }
            if(this.order_rating){
                this.stars = this.order_rating;
            }
        },
        onSubmit(){
            if(this.proccessing) return;
            this.proccessing = true;
            fetch(this.$serverUrl + '/api/v1/rate/add',{
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    order_id: this.order.id,
                    item_id: '',
                    spec_id: '',
                    user_id: this.$store.state.user.id,
                    token: this.$store.state.user.password,
                    stars: this.stars,
                    rate_taste: this.rate_taste,
                    rate_speed: this.rate_speed,
                    rate_fresh: this.rate_fresh,
                    rate_quality: this.rate_quality,
                    comment: this.comment,
                    photos: this.photos,
                    type: this.type
                })
            })
            .then(r => r.json())
            .then(result => {
                if(result.error)
                {
                    this.proccessing = false;
                    console.log(result.error.text);
                    this.showDialog({ 
                        title: 'Ошибка',
                        message: 'Что-то пошло не так',
                        items: ['Ok']
                    })
                }
                else
                {
                    this.proccessing = false;
                    this.$emit('input',{
                        success: true,
                        stars: this.stars,
                        rate_taste: this.rate_taste,
                        rate_speed: this.rate_speed,
                        rate_fresh: this.rate_fresh,
                        rate_quality: this.rate_quality,
                        comment: this.comment
                    });
                }
            }).catch(err => {
                this.proccessing = false;
                console.log(err)
                this.showDialog({
                    title: 'Ошибка',
                    message: 'Что-то пошло не так',
                    items: ['Ok']
                })
            })
            // this.$emit('hide')
        }
    }
}
</script>


<style scoped>
.modal-window {
    background: #fff;
    padding: 10px;
}

.rate-feature-list {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px 0;
}

.rate-feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;
    text-align: center;
    color: #ddd;
}

.rate-feature-item i {
    display: inline-block;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
}

.rate-feature-item label {
    display: inline-block;
}

.rate-feature-item.active i {
    color: #0071E5;
}

.rate-feature-item.active label {
    color: #333;
}

.attach-list
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.attach-item {
    height: 50px;
    margin: 5px;
}

.attach-btn,
.camera-btn
{
    font-size: 40px;
    color: #aaa;
    width: 50px;
    height: 50px;
    margin: 5px;
    text-align: center;
}

.attach-btn i, 
.camera-btn i
{
    line-height: 50px;
}

.attach-btn
{
    position: relative;
}
.attach-btn input {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    opacity: 0;
}
</style>