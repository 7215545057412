<template>
    <div>
        <select v-model="section" class="input-text mb-10px">
            <option value="">Выберите категорию обращения</option>
            <option :value="type.id" v-for="(type, index) in types.sections" :key="index">{{type.name}}</option>
        </select>
        <select v-model="item" class="input-text mb-10px" v-if="section">
            <option value="">Выберите суть обращения</option>
            <option :value="el.id" v-for="(el, index) in items" :key="index">{{el.name}}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: ['types','value'],
    data(){
        return {
            section: '',
            item: ''
        }
    },
    watch: {
        section(){
            this.item = '';
            this.$emit('input','')
        },
        item(v){
            this.$emit('input',v)
        }
    },
    computed: {
        items(){
            if(!this.section) 
                return [];

            return this.types.items.filter(el => el.section_id == this.section);
        }
    }
}
</script>