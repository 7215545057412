<template>
    <div >
        <ShopMenuToggler />
        <MenuCategoriesSlider 
            :items="categories"
            :active="activeCategory"
            @change="onTapCategory"
        />
        <div class="scrollview" style="top:143px" ref="menuscroll">
            <div v-for="item in items" :key="item.id">
                <div v-if="item.stories">
                    
                </div>
                <CategoryTitle :item="item" v-else-if="item.categoryTitle" :ref="'category-'+item.categoryId"/>
                
                <div style="height:80px" v-else-if="item.space"/>
                
                <CardProduct :item="item"  v-else @onTap="showProductModal" />
            </div>
        </div>
        <v-dialog 
            persistent 
            v-model="productModalVisible" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <ProductModal :item="productModal" @close="productModalVisible = false" v-if="productModalVisible"/>
        </v-dialog>
        
        <SpecModal />
    </div>
</template>

<script>
import ShopMenuToggler from '/src/components/ShopMenuToggler';
import CategoryTitle from '/src/components/CategoryTitle';
import CardProduct from '/src/components/CardProduct';
import MenuCategoriesSlider from '/src/components/MenuCategoriesSlider';
import ProductModal from '/src/pages/Product';
import SpecModal from '/src/components/SpecModal';

export default {
    props: ['items','categories'],
    components: {
        SpecModal,
        CategoryTitle,
        CardProduct,
        ShopMenuToggler,
        MenuCategoriesSlider,
        ProductModal
    },
    data() {
        return {
            firstItemIndex: undefined,
            activeCategory: 0,
            productModalVisible: false,
            productModal: null
        };
    },
    methods: {
        showProductModal(item){
            this.productModal = item;
            this.productModalVisible = true;
        },
        onTapCategory(id){
            this.activeCategory = id;
            let [obj] = this.$refs['category-'+id];
            obj.$el.scrollIntoView();
        },
        initScroll(){
            this.activeCategory = this.categories[0].id;
            this.$refs.menuscroll.addEventListener('scroll', this.onScroll)
        },
        onScroll(){
            var firstVisible = null;
            this.categories.every(category => {
                let [obj] = this.$refs['category-'+category.id];
                var top = obj.$el.getBoundingClientRect().top;
                if(!firstVisible){
                    firstVisible = category;
                }
                if(top <= 143){
                    firstVisible = category;
                    return true;
                }
            })

            if(firstVisible){
                this.activeCategory = firstVisible.id
            }
        }
    },
    created(){
        this.$watch(()=>this.categories,(v)=>{
            if(v[0]){
                this.$nextTick().then(this.initScroll);
            }
        })
    },
    mounted(){
        
    }
}
</script>