require('@/assets/styles/app.scss')
require('@/assets/fonts/fonts.css')
require('@/assets/fontawesome5/css/all.min.css')

import Vue from 'vue'
import YmapPlugin from 'vue-yandex-maps'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import mixins from './mixins';

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);


Vue.config.productionTip = false

if(process.env.NODE_ENV == 'development'){
  Vue.prototype.$serverUrl = 'http://localhost:8080';
}else{
  Vue.prototype.$serverUrl = 'https://mobile.more-mania.com';
}
Vue.prototype.$assetsUrl = 'https://mobile.more-mania.com';

const yMapSettings = {
  apiKey: 'bfb68608-a193-4817-b78d-5826fa4b41b0',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}


Vue.use(YmapPlugin, yMapSettings)

Vue.mixin(mixins);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
  mounted() {
    this.$store.commit('loadState');
  }
}).$mount('#app')
