<template>
    <div class="page-kiosk">
        <img src="../assets/icon-moremania-blue.png" class="mb-20px" alt="">
        <div style="font-weight:bold;font-size:30px;text-align:center;margin:0 0 20px 0">
            Запустить приложение в режиме киоска?
        </div>
        <div style="width:300px">
            <div class="btn btn-primary mb-20px" @click="startKiosk">Да</div>
            <div class="btn btn-secondary" @click="startMobile">Нет</div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        startKiosk(){
            this.$store.state.kiosk = true;
            this.$store.commit('logout');
            this.$router.push('/')
        },
        startMobile(){
            this.$store.state.kiosk = false;
            this.$store.commit('saveState');
            this.$router.push('/')
        },
    }
}
</script>

<style scoped>
.page-kiosk {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}
</style>