<template>
    <div class="toggle-type">
        <div class="toggle-type-item" 
            :class="{checked:item==value||item.value==value}" 
            v-for="(item,index) in items" 
            :key="index"
            @click="setItem(item)">
            {{item.name?item.name:(item.text?item.text:item)}}
        </div>
    </div>
</template>

<script>
export default {
    props: ['value','items'],
    created(){
        this.initValue();
    },
    methods: {
        initValue(){
            if(!this.value){
                this.$emit('input', this.items[0].value)
            }
        },
        setItem(item){
            this.$emit('input', item.value);
        }
    }
}
</script>

<style scoped>

.toggle-type
{
    height: 48px;
    margin: 20px 10px;
    border: 2px solid #0071E5;
    background-color: #ffffff;
    border-radius: 45px;
    display: flex;
}

.toggle-type-item {
    color:#0071E5;
    background-color: transparent;
    text-align:center;
    
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
}

.toggle-type-item.checked {
    background-color: #0071E5;
    border-radius:45px;
    color:#fff;
}

</style>