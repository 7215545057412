<template>
    <div>
        <CartDatePicker v-if="$store.state.restoran.deliveryDaysTrue > 0"
            v-model="deliveryDate" class="mb-20px" :maxDays="$store.state.restoran.deliveryDaysTrue"
        />

        <CartTimePicker title="Время доставки" v-model="deliveryTime" :allowGotov="!deliveryDate.length || deliveryDate == currentDate" class="mb-20px" v-if="!periods.length" :today="deliveryDate == currentDate" />

        <CartPeriodPicker title="Время доставки" v-model="deliveryTime" v-if="periods.length" :items="periods" />
    </div>
</template>

<script>
import CartDatePicker from '../components/CartDatePicker.vue';
import CartTimePicker from './CartTimePicker.vue';
import CartPeriodPicker from './CartPeriodPicker.vue';

export default {
    components: {
        CartDatePicker,
        CartTimePicker,
        CartPeriodPicker
    },
    data(){
        return {
            deliveryDate: '',
            deliveryTime: '',
            minutes: 0,
        }
    },
    created(){
        var date = new Date();
        this.minutes = date.getMinutes() + date.getHours()*60;
        setInterval(()=>{
            var date = new Date();
            this.minutes = date.getMinutes() + date.getHours()*60;
        }, 1000 * 60)
    },
    computed: {
        currentDate(){
            return this.dateFormat(new Date());
        },
        periods(){
            const selectedDate = (this.deliveryDate.length>0) ? this.deliveryDate : this.currentDate;
            const dateArr = selectedDate.split(".");
            const date = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);
            const dayOfWeek = (date.getDay() > 0) ? date.getDay() : 7;
            let zone = this.$store.state.curZone;
            var periods = JSON.parse(zone.periods);
            var result = [];
            var today = !this.deliveryDate.length || this.deliveryDate == this.currentDate;
            
            // var cnt_disabled = 0;
            periods.forEach(item => {
                let from = item.from.split(':');
                from = from[0]*60+from[1]*1;
                item.disabled = today && (from - zone.time_limit*1 < this.minutes);
                item.active = this.deliveryTime == item.from+' - '+item.to;

                // if(item.disabled) cnt_disabled++;

                if(item.disabled && item.active)
                {
                    this.deliveryTime = '';
                }
                if(item.days[dayOfWeek-1]){
                    result.push(item)
                }
            });
            return result.sort(compare);
        },
        worktime(){
            let restoran = this.$store.state.restoran;
            let worktimeObj = {
                startHour : 0,
                endHour : 0
            };
            if("workTime" in restoran){
                let workdaysArr = restoran.workTime.split(";");
                const parseDate = this.deliveryDate.split('.');
                const date = new Date(`${parseDate[2]}-${parseDate[1]}-${parseDate[0]}`);
                let weekDate = date.getDay();
                weekDate = (weekDate) ? weekDate : 7;
                const currDateWorkTimeStr =  workdaysArr[weekDate-1];
                const arr = currDateWorkTimeStr.split(":")
                const minMaxHourStr = arr[1];
                const minMaxHourArr = minMaxHourStr.split("-");
                worktimeObj.startHour = parseInt(minMaxHourArr[0]);
                worktimeObj.endHour = parseInt(minMaxHourArr[1]);
            }
            return worktimeObj;
        },
        resultData(){
            return {
                deliveryDate: this.deliveryDate,
                deliveryTime: this.deliveryTime,
                currentDate : this.currentDate,
                isTimePeriods: (this.periods.length>0) ? true : false
            }
        }
    },
    watch: {
        deliveryDate(){
            this.$emit('change', this.resultData);
        },
        deliveryTime(){
            this.$emit('change', this.resultData);
        }
    }
}
// метод сортировки массива объектов по атрибуту 
function compare( a, b ) {
    const aArr = a.from.split(":");
    const aMinutes = aArr[0]*60 + aArr[1]*1;
    const bArr = b.from.split(":");
    const bMinutes = bArr[0]*60 + bArr[1]*1;
  if ( aMinutes < bMinutes ){
    return -1;
  }
  if ( aMinutes > bMinutes ){
    return 1;
  }
  return 0;
}
</script>