<template>
    <div class="spec-toggler-multi">
        <div class="spec-item" v-for="item in options" :key="item.specid"
            @click="ontapToggle(item)" :opacity="item.disabled?0.5:1">
            <span class="spec-check"  v-if="!item.multicheck">
                <i class="fas fa-check" v-show="itemSelected(item)"></i>
            </span>
            <span class="spec-name">
                {{item.specname}} ({{item.price}} ₽)
            </span>
            <!-- <span class="spec-price">
                {{'+' + item.price + ' руб.'}}
            </span> -->
            <div v-if="item.multicheck">
                <span class="card-product-inc" :class="{'bg-grey':item.disabled}" @click="cntInc(-1,item)">
                    <i class="fas fa-minus text-center" color="#fff" 
                        verticalAlignment="center" horizontalAlignment="center"></i>
                </span>
                <span textWrap="true" verticalAlignment="center" width="30" class="font-europec-bold card-product-cnt text-center">
                    {{ itemCnt(item) }}
                </span>
                <span class="card-product-inc" @click="cntInc(1,item)"
                :class="{'bg-grey':item.disabled}">
                    <i class="fas fa-plus text-center" color="#fff" 
                        verticalAlignment="center" horizontalAlignment="center"></i>
                </span>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: ['options','value'],
    methods: {
        cntInc(inc,item){
            var val = JSON.parse(JSON.stringify(this.value));

            var index = val.findIndex(el => el.specid == item.specid);

            if(index >= 0){
                if(val[index].cnt + inc > 0){
                    val[index].cnt += inc;
                }else{
                    val.splice(index, 1)
                }
            }else if(inc > 0){
                val.push({...item,cnt:inc});
            }

            this.$emit('input', val);
        },
        itemCnt(item){
            var result = 0
            
            var index = this.value.findIndex(el => el.specid == item.specid);

            if(index >= 0){
                result = this.value[index].cnt;
            }

            return result?result+'':'0';
        },
        ontapToggle(item){
            if(item.disabled || item.multicheck) return;

            var val = JSON.parse(JSON.stringify(this.value));

            var notMuldiIndex = val.findIndex(el => !el.multicheck);

            if(notMuldiIndex >= 0){
                val.splice(notMuldiIndex,1);
            }

            var valNotMulti = {
                ...item,
                cnt: 1
            };
            val.push(valNotMulti);
            this.$emit('input', val);
            // {
            // }
        },
        itemSelected(item){
            var index = this.value.findIndex(el => el.specid == item.specid);
            return index >= 0;
        }
    },
    created(){
        var val = JSON.parse(JSON.stringify(this.value));

        var valNotMulti = false;

        var notMuldiIndex = val.findIndex(el => !el.multicheck);

        if(notMuldiIndex >= 0){
            valNotMulti = val[notMuldiIndex];
        }

        this.options.forEach(item => {
            if(!item.multicheck && !item.disabled){
                if(!valNotMulti){
                    valNotMulti = {
                        ...item,
                        cnt: 1
                    };
                    val.push(valNotMulti);
                }
            }
        })

        this.$emit('input', val);
    }
}
</script>

<style scoped>
.spec-item {
    margin:0 10px 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spec-check{
    display: block;
    width:30px;
    height:30px;
    background-color:#eee;
    border-radius:3px; 
    margin: 0 10px 0 0;
    text-align: center;
}

.spec-check i {
    line-height: 30px;
}

.spec-name {
    flex: 1 1 auto;
}

.spec-price {
    flex: 0 1 auto;
}


.card-product-inc
{
    width:30;
    height:30;
    border-radius: 15;
    background-color: #ccc;
    border-color: #c4c1c1;
    border-width: 1;
}

.card-product-cnt
{
    color: #181818;
}
</style>