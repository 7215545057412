<template>
    <v-dialog v-model="$store.state.specModal.show">
        <div class="modal-select" v-if="$store.state.specModal.item">
            <div class="modal-info">
                <div class="modal-title">
                    {{$store.state.specModal.item.name}}
                </div>
                <div class="modal-message">
                    Как приготовить:
                </div>
            </div>
            <div>
                <div v-for="spec in $store.state.specModal.item.spec" class="spec-item" :key="spec.specid"
                     @click="setCurrent(spec)" :class="{active: currentSpec.specid==spec.specid,disabled: !!spec.disabled}">
                     <div class="spec-item-check">
                         <i class="far fa-check-circle" v-show="currentSpec.specid==spec.specid"></i>
                         <i class="far fa-circle" v-show="currentSpec.specid!=spec.specid"></i>
                     </div>
                     <div class="spec-item-name">
                         {{spec.specname}}
                     </div>
                     <div class="spec-item-price">
                         {{spec.price+' руб.'}}
                     </div>
                </div>
            </div>
            <div class="button active last" @click="addToCart">Ok</div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            currentSpec: {
                specid: false
            }
        }
    },
    methods: {
        setCurrent(spec){
            if(spec.disabled) return;
            this.currentSpec = spec;
        },
        addToCart(){
            if(!this.currentSpec.specid) 
                return;
            
            this.$store.commit('addToCartSpec', {
                item: this.$store.state.specModal.item,
                cnt: 1,
                spec: this.currentSpec
            })
            
            this.$store.state.specModal.show = false;
        }
    }
}
</script>