<template>
    <v-dialog v-model="visibleC">
        <div class="modal-select">
            <div class="modal-info">
                <div class="modal-title">
                    Адрес доставки
                </div>
                <div class="modal-message">
                    Внимание! При смене адреса доставки текущее содержимое корзины будет удалено!
                </div>
            </div>
            <div class="address-list">
                <div v-for="item in items" :key="item.id" class="address-list-item" @click="onTapItem(item)">
                    {{item.full}}
                </div>
            </div>
            <div class="button active last" @click="newAddress">Новый адрес</div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: ['visible','value','items'],
    computed:{
        visibleC:{
            set(v){
                if(!v){
                    this.$emit('hide');
                }
            },
            get(){
                return this.visible
            }
        }
    },
    methods: {
        newAddress(){
            this.$emit('input',{
                id: 0
            })
        },
        onTapItem(item){
            this.$emit('input',item)
        }
    }
}
</script>

<style scoped>
.address-list {
    max-height: 300px;
    overflow: auto;
    border-top: 1px solid #eee;
}

.address-list-item {
    padding: 10px 10px;
    border-bottom: 1px solid #eee;
}
</style>