<template>
    <div class="cart-total-wrap">
        <div v-show="!$store.state.kiosk">
            <div class="cart-total-title mb-10px">
                E-mail для чека
            </div>
            <div class="mb-20px">
                <input type="email" placeholder="E-mail" class="textfield" v-model="$store.state.user.email">
            </div>

            <div class="cart-total-title mb-10px">
                Купон на скидку
            </div>
            <div class="mb-20px">
                <input type="coupon" placeholder="" class="textfield" v-model="couponInput">
            </div>
        </div>

        <div class="cart-total-price mb-10px"  v-show="discountSum>0" >
            {{'Ваша скидка: '+ discountPercent +'% ('+ priceFormat(discountSum) +' ₽)'}}
        </div>
        <div class="cart-total-price mb-10px"  v-show="discountSum<0" >
            {{'Наценка: '+ (0-discountPercent) +'% ('+ priceFormat(0-discountSum) +' ₽)'}}
        </div>
        <div class="cart-total-price mb-10px"  v-show="deliverySum>0" >
            {{'Доставка: '+ priceFormat(deliverySum) +' ₽'}}
        </div>

        <div class="cart-total-price-full mb-5px" v-show="deliverySum>0" >
            ИТОГО К ОПЛАТЕ:
        </div>

        <div class="cart-total-price-big mb-10px">
            {{priceFormat(orderSum + deliverySum) + ' ₽'}}
        </div>
    </div>
</template>

<script>
export default {
    props: ['discountSum', 'discountPercent', 'deliverySum', 'orderSum', 'coupon'],
    computed: {
        couponInput: {
            set(v){
                this.$emit('couponInput',v);
            },
            get(){
                return this.coupon?this.coupon:''
            }
        }
    }
}
</script>

<style scoped>
.cart-total-wrap {
    padding: 0;
}

.cart-total-title {
    
}

.cart-total-price {
    color: #d50000;
}

.cart-total-price-full {
    font-size: 13px;
    color:#333;
}

.cart-total-price-big {
    color: #333;
    font-size:40px;
}
</style>