<template>
    <div class="star-list" :class="{center:align=='center'}">
        <div v-for="index in max" :key="index" class="star-list-item" @click="onTap(index)">
            <img src="../assets/icon-star-blue-1.png" v-show="index <= value" style="width:22px;height:22px;" />
            <img src="../assets/icon-star-blue-0.png" v-show="index > value" style="width:22px;height:22px;" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: 5,
            type: Number
        },
        max: {
            default: 5,
            type: Number
        },
        active: {
            default: true,
            type: Boolean
        },
        align: {
            default: '',
            type: String
        }
    },
    methods:{
        onTap(index){
            if(this.active == true){
                this.$emit('input', index)
            }
        }
    }
}
</script>

<style scoped>
.star-list {
    display: flex;
}

.star-list.center {
    justify-content: center;
}

.star-list-item {

}
</style>