<template>
    <div class="input-row">
        <label>Количество гостей</label>
        <div>
            <span class="card-product-inc" @click="cntPersons(-1)">
                <i class="fas fa-minus"></i>
            </span>
            <span class="font-europec-bold card-product-cnt text-center">{{value}}</span>
            <span class="card-product-inc" @click="cntPersons(1)">
                <i class="fas fa-plus"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    methods: {
        cntPersons(v){
            if(this.value + v > 0){
                this.$emit('input', this.value + v)
            }
        }
    }
}
</script>

<style scoped>
.card-product-inc
{
    display: inline-block;
    width:30px;
    height:30px;
    border-radius: 15px;
    background-color: #ccc;
    border: 1px solid #c4c1c1;
    color: #fff;
    text-align: center;
}

.card-product-inc i {
    font-size: 14px;
    line-height: 30px;
}

.card-product-cnt
{
    color: #181818;
    display: inline-block;
    width: 30px;
}
</style>