<template>
    <div class="cart-item-list mb-20px">
        <div v-for="item in items" :key="item.id" class="cart-item-card">
            <div class="cart-item-info">
                <div class="cart-item-name">
                    {{item.name}}
                </div>
            </div>
            <div class="cart-item-row">
                <div class="cart-item-dec">
                    <img src="../assets/btn-dec.png" alt="" @click="setCntPribors(item,-1)"/>
                </div>
                <div class="cart-item-cnt">
                    {{item.cnt.toString()}}
                </div>
                <div class="cart-item-inc">
                    <img src="../assets/btn-inc.png" alt="" @click="setCntPribors(item,1)"/>
                </div>
                <div class="cart-item-price">
                    {{sumPribors(item)}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            // type: Array,
            default: []
        },
        cartItems: {
            // type: Array,
            default: [],
        },
        orderType: {
            type: String,
            default: ''
        },
        restoranId: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            items: []
        }
    },
    created(){
        this.loadData()
    },
    methods: {
        setCntPribors(item, cnt){
            if(item.cnt + cnt >= 0 && item.cnt + cnt < item.max){
                item.cnt += cnt;
                this.emitInput();
            }
        },
        sumPribors(item){
            return this.priceFormat(item.price * item.cnt) + ' ₽';
        },
        loadData(){
            this.request({
                action: 'cartPribors',
                fields: {
                    order_type: this.orderType,
                    restoran_id: this.restoranId,
                    cart_items: this.cartItems
                }
            })
            .then(r => {
                if(r.items){
                    this.items = r.items;
                    this.emitInput();
                }
            })
        },
        emitInput(){
            this.$emit('input', this.items)
        }
    }
}
</script>


<style scoped>
.cart-item-list {

}

.cart-item-card {
    border: 0px solid #C1D0E1;
    border-bottom-width: 1px;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
}

.cart-item-info {
    margin: 0 0 15px 0;
}

.cart-item-name {
    color:#506883;
    font-size:14px; 
}

.cart-item-spec {
    margin:5px 0 0 0;
    font-size:12px;
}

.cart-item-row {
    display: flex;
    flex-direction: row;
    height: 38px;
    align-items: center;
}

.cart-item-row img {
    height: 38px;
}

.cart-item-del {
    margin: 0 10px 0 0;
}

.cart-item-cnt {
    width: 46px;
    text-align: center;
}

.cart-item-comment-btn {
    margin: 0 0 0 10px;
}

.cart-item-price {
    font-size:14px;
    color:#333;

    flex: 1 1 auto;
    text-align: right;
}

.cart-item-comment {
    margin:5px 0 0 0;
    font-size:12px;
}
</style>