<template>
    <div class="action-bar">
        <button v-show="showMenu" class="btn--icon-menu" @click="$store.state.showDrawer = true">
            <img src="../assets/icon-menu.png"/>
        </button>
        <button @click="goBack" v-show="showBack && !showMenu" class="btn--icon-back">
            <img src="../assets/icon-back.png" width="12" margin="12" />
        </button>
        <div v-if="showLogo" class="action-bar--logo" @click="onTapLogo">
            <img src="../assets/icon-logo.png" alt="">
        </div>
        <div v-if="title" :class="{'title-small':showLogo}" class="action-bar--title">
            {{ title }}
        </div>
        <div v-show="rightBtn" @click="onTapRight" style="width:40px;text-align:center;margin-left:auto;">
            <i :class="rightBtn"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        backPath: {
            type: String,
            default: ''
        },
        showMenu: {
            type: Boolean,
            default: false
        },
        showBack: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        showLogo: {
            type: Boolean,
            default: false
        },
        rightBtn: {
            type: String,
            default: ''
        }
    },
    methods: {
        goBack(){
            if(this.backPath.length > 0)
                this.$router.push(this.backPath);
            else
                this.$router.go(-1);
        },
        onTapLogo(){
            this.$router.push('/');
        },
        onTapRight(){
            this.$emit('tapRight')
        }
    }
}
</script>

<style scoped>
.action-bar {
    text-align: center;
    background-color: #0071E5;
    color: #fff;
    height: 60px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.btn--icon-menu {
    width: 42px;
    align-self: stretch;
}

.btn--icon-menu img {
    width: 18px;
}

.btn--icon-back {
    width: 40px;
    align-self: stretch;
}

.title-small {
	font-size: 12px; 
    text-transform: uppercase;
}

.action-bar--logo img {
    height: 30px;
}

.action-bar--logo {
    margin: 0 5px 0 0;
}

.action-bar--title {
    
}
</style>