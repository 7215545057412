<template>
    <div>
        <div class="input-title">
            {{title}}
        </div>
        <div>
            <div v-for="(item, index) in items" :key="index" class="restoran-item" 
                @click="setItem(item)" :class="{checked:item.id==value.id}">
                <img src="../assets/icon-moremania-white.png" v-show="item.id==value.id">
                <img src="../assets/icon-moremania-blue.png" v-show="item.id!=value.id">
                <div class="restoran-item-title">
                    {{item.name}}
                </div>
                <div class="restoran-item-address">
                    {{item.address}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title','items','value'],
    methods: {
        setItem(item){
            this.$emit('input',item)
        }
    }
}
</script>

<style scoped>

.input-title
{
    color: #8A90AF;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 15px 5px;
}


.restoran-item
{
    border-radius: 25px;
    margin: 0 10px 10px;
    background-color: #F1F4FD;
    color: #33404D;
    padding: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.restoran-item img
{
    vertical-align: center;
    margin-right: 15px;
    width: 25px;
    align-self: center;
}

.restoran-item label
{
    vertical-align: center;
}

.restoran-item-title
{
    font-weight:bold;
    font-size: 14px;
    align-self: center;
}

.restoran-item-address
{
    font-size: 12px;
    margin-top:10px;
    flex: 1 0 100%;
}

.restoran-item.checked
{
    color: #ffffff;
    background-color: #8A90AF;
}
</style>