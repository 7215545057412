<template>
    <div class="side-drawer">
        <div class="side-drawer-top">
            <div class="side-drawer-back" @click="onTapClose">
                <i class="fas fa-arrow-left"></i>
            </div>
            <div style="flex-grow:1">

            </div>
            <div class="side-drawer-phone" @click="onTapHotline" v-show="!$store.state.kiosk">
                <i class="fas fa-phone"></i>
            </div>
            <div class="side-drawer-msg" @click="onTapMsg" v-show="!$store.state.kiosk">
                <i class="far fa-comment-dots"></i>
            </div>
        </div>
        <div style="padding:10px 6px 0 12px" v-show="$store.state.user.authorized">
            <div style="font-size:24px;color:#fff">
                {{$store.state.user.name}}
            </div>
            <div style="margin: 2px 0 0 0;color:#ccc">
                {{$store.state.user.phone}}
            </div>
        </div>
        <div class="side-menu-item" @click="onClickOrders"  v-show="$store.state.user.authorized">
            <i class="fas fa-th-list side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Мои заказы
            </span>
        </div>
        <div class="side-menu-item" @click="onClickProfile"  v-show="$store.state.user.authorized">
            <i class="fas fa-user-circle side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Мой профиль
            </span>
        </div>
        <div class="side-menu-item" @click="onClickLiked" v-show="$store.state.dishes.length>0 && $store.state.user.authorized">
            <i class="far fa-heart side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Моё любимое
            </span>
        </div>
        <div class="side-menu-item" @click="onClickAddress" v-show="$store.state.user.authorized">
            <i class="fas fa-map-marked-alt side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Мои адреса
            </span>
        </div>
        <div class="side-menu-item" @click="onClickRate" v-show="$store.state.user.authorized">
            <i class="fas fa-clipboard-check side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Мои оценки
            </span>
        </div>
        <div class="side-menu-divider" v-show="$store.state.user.authorized"></div>
        <div class="side-menu-item" @click="onClickMain">
            <i class="fas fa-home side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Главная
            </span>
        </div>
        <div class="side-menu-item" @click="onClickAbout" v-show="$store.state.orderType && ($store.state.orderType=='delivery' || $store.state.orderType=='self')">
            <i class="fas fa-utensils side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                О кафе
            </span>
        </div>
        <div class="side-menu-item" @click="onClickAboutDelivery">
            <i class="fas fa-truck side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                О доставке
            </span>
        </div>
        <div class="side-menu-item" @click="onClickOferta">
            <i class="fas fa-align-left side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Оферта
            </span>
        </div>

        <div class="side-menu-item" @click="onClickLogout" style="margin-top:auto;" v-show="$store.state.user.authorized">
            <i class="fas fa-sign-out side-menu-item--icon"></i>
            <span class="side-menu-item--text">
                Выход
            </span>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        onTapClose(){
            this.$store.state.showDrawer = false
        },
        onTapHotline(){
            window.location.href = 'tel://' + this.$store.state.settings.hotline;
        },
        onTapMsg(){
            this.$router.push('/feedback');
        },
        onClickOrders(){
            this.$router.push('/order_list');
        },
        onClickProfile(){
            this.$router.push('/profile');
        },
        onClickLiked(){
            this.$router.push('/liked');
        },
        onClickAddress(){
            this.$router.push('/address_list');
        },
        onClickRate(){
            this.$router.push('/rate_list');
        },
        onClickMain(){
            this.$router.push('/')
        },
        onClickAbout(){
            this.$router.push('/about_restoran/'+this.$store.state.restoran.id);
        },
        onClickAboutDelivery(){
            this.$router.push('/about_delivery');
        },
        onClickOferta(){
            this.$router.push('/about_oferta');
        },
        onClickLogout(){
            this.$store.commit('logout');
            this.$router.push('/auth')
        }
    }
}
</script>

<style scoped>
.side-drawer {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.side-drawer-top {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
}

.side-drawer-top i {
    line-height: 30px;
    font-size: 16px;
    color:#fff;
}

.side-drawer-back {
    width:30px;
    height:30px;
    text-align: center;
}

.side-drawer-phone {
    width:30px;
    height:30px;
    border-radius:15px;
    background-color:#274F7D;
    margin:0 13px 0 0;
    text-align: center;
}

.side-drawer-msg {
    width:30px;
    height:30px;
    border-radius:15px;
    background-color:#F37021;
    margin:0 13px 0 0;
    text-align: center;
}


.side-menu-item
{
    padding: 10px 0;
}

.side-menu-item--icon
{
    font-size:22px;
    color:#fff;
    margin:0 0 0 0;
    display: inline-block;
    width: 50px;
    text-align: center;
    vertical-align: middle;
}

.side-menu-item--text
{
    font-size:18px;
    color:#fff;
    vertical-align: middle;
}

.side-menu-divider {
    margin: 10px 0;
    height: 1px;
    background: #0C71A9;
}
</style>