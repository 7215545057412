<template>
    <div>
        <top-bar :showMenu="true" :title="title" />
        <div class="scrollview" style="padding:10px 0 70px">
            <div class="booking-info">
                <div class="booking-info-label">Дата:</div>
                <div class="booking-info-value">
                    {{booking.dateFormatted}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">Количество гостей:</div>
                <div class="booking-info-value">
                    {{booking.cntpersons}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">Время:</div>
                <div class="booking-info-value">
                    {{timeFormat(booking.periodfrom)}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">Длительность:</div>
                <div class="booking-info-value">
                    {{booking.cntminutes}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">Заведение:</div>
                <div class="booking-info-value">
                    {{booking.restoran.name}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">Бронь:</div>
                <div class="booking-info-value">
                    {{booking.username}}
                </div>
            </div>
            <div class="booking-info">
                <div class="booking-info-label">Минимальная сумма заказа:</div>
                <div class="booking-info-value">
                    {{booking.min_sum}}
                </div>
            </div>


            <div class="hr-line"></div>

            <div class="booking-result-numtitle">
                Номер бронирования:
            </div>
            <div class="booking-result-numvalue">
                {{booking.booking_num}}
            </div>

            <div class="booking-result-icon" v-show="booking.img">
                <img :src="booking_img" />
            </div>
            <div class="booking-result-message" v-show="booking.message" >
                {{booking.message}}
            </div>

        </div>
        <div style="position:fixed;bottom:10px;left:10px;right:10px;">
            <div v-if="booking.isnew">
                <div class="btn-submit" style="margin-bottom:5px" @click="tapOrder" v-show="booking.allowOrder">
                    Заказать блюда
                </div>
                
                <div class="btn-submit --error" style="margin-bottom:5px" @click="tapCancel" v-show="booking.allowCancel">
                    Отменить бронь
                </div>
            </div>
            <div v-else>
                <div class="text-center" style="font-size:20px" >
                    {{timeLessFormatted}}
                </div>

                <div class="btn-submit" style="margin-bottom:5px" @click="tapOrder" v-show="booking.allowOrder&&!!timeLess">
                    Продлить бронирование
                </div>

                <div style="margin:10px" v-show="booking.textContinue&&!!timeLess" class="text-center">
                    {{booking.textContinue}}
                </div>
                
                <div class="btn-submit --error" style="margin-bottom:5px" @click="tapCancel" v-show="booking.allowCancel&&!!timeLess">
                    Завершить бронирование
                </div>
            </div>
            
            <div class="btn-submit --secondary" @click="tapGoMain" v-show="booking.allowMain">
                Вернуться на главную
            </div>
        </div>

        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';

export default {
    props: ['booking'],
    components: {
        TopBar
    },
    computed: {
        title(){
            return 'Бронь ' + this.booking.booking_num;
        },
        booking_img(){
            // return require(this.booking.img.replace('~', '@/src'));
            return require('../assets/icon-booking-success.png');
        },
        timeLessFormatted(){
            var h = Math.floor(this.timeLess / 3600);
            var m = Math.floor((this.timeLess % 3600) / 60);
            var s = (this.timeLess % 3600) % 60;
            // console.log(h, m, s);x
            return h+' ч. '+m+' мин. '+s+' сек.';
        }
    },
    data(){
        return {
            proccessing: false,
            timeLess: 0
        }
    },
    created(){
        console.log('interval', global.bookinginterval)
        if(global.bookinginterval){
            clearInterval(global.bookinginterval);
        }
        global.bookinginterval = setInterval(()=>{
            var date = new Date();
            var time = Math.floor(date.getTime()/1000);
            
            if(time > this.booking.timefrom)
            {
                if(this.booking.timeto - time > 0)
                {
                    this.timeLess = this.booking.timeto - time;
                }
                else
                {
                    this.timeLess = 0;
                }
            }
            else
            {
                this.timeLess = this.booking.timeto - this.booking.timefrom;
            }
        },1000)
    },
    methods: {
        onNavTo(){
            this.proccessing = false;
        },
        tapCancel(){
            if(this.proccessing) return;

            this.proccessing = true;

            this.request({
                action: this.booking.isnew?'bookingCancel':'bookingEnd',
                fields: {
                    booking_num: this.booking.booking_num
                }
            }).then(r => {
                if(r.success){
                    this.$router.push('/')
                    return;
                }
                if(r.error){
                    alert(r.error);
                }
                this.proccessing = false;
            }).catch(() => {
                this.proccessing = false;
                alert(this.$error502)
            })
        },
        tapOrder(){
            this.proccessing = true;
            this.getRestorans().then(()=>{
                this.proccessing = false;
                const restoran = this.$store.state.restorans.filter(item => item.fs_uid == this.booking.restoran.id)[0];
                
                this.$store.state.tableId = this.booking.table_id;
                this.$store.state.booking_num = this.booking.booking_num;
                this.$store.state.booking_min_sum = this.booking.min_sum;
                this.$store.commit('setRestoran', restoran);
                this.$store.commit('orderType', 'inrest');
                this.$router.push('/menu/'+restoran.id+'/inrest')
                // this.goto(this.$routes.Main, {
                //     booking_num: this.booking.booking_num
                // })

            })
        },
        tapGoMain(){
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>

.booking-info
{
    padding: 7px 10px;
    color: #33404D;
    display: flex;
    justify-content: space-between;
}

.booking-info-label
{
    color: #999FA6;
}

.booking-info-value
{
    
}

.hr-line
{
    height: 1px;
    background:#C1D0E1;
    margin: 20px 10px 27px;
}


.booking-result-numtitle
{
    text-align:center;
    font-size: 18px;
    color: #0071E5;
    margin-bottom: 6px;
}

.booking-result-numvalue
{
    text-align:center;
    font-size: 28px;
    color: #33404D;
    margin-bottom: 17px;
}

.booking-result-message
{
    text-align: center;
    font-size: 14px;
    color: #33404D;
    margin-bottom: 10px;
}

.booking-result-icon
{
    margin-bottom: 34px;
    text-align: center;
}

.booking-result-icon img {
    width: 168px;
}


.btn-submit {
    height: 55px;
    color: #fff;
    background-color:#0071E5;
    border-radius: 40px;
    margin: 0px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-submit.--secondary
{
    background-color: gray;
}

.btn-submit.--error
{
    background-color: #d50000;
}
</style>