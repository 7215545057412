<template>
    <div>
        <div class="mb-10px" >
            {{title}}
        </div>
        <div class="checkbox-item mb-5px"  @click="onTapItem(item)"  :class="{active:item.active,disabled:item.disabled}" v-for="(item, index) in items" :key="index" style="height:40px;" >
            <img src="../assets/icon-time_select-0.png" v-show="!item.active" />
            <img src="../assets/icon-time_select-1.png" v-show="item.active" />
            <span>
                {{'С '+item.from+' до '+item.to}}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value','items','title'],
    methods: {
        onTapItem(item){
            if(!item.disabled)
            {
                this.$emit('input', item.from+' - '+item.to);
            }
        }
    },
    created(){
        var selected = false;
        if(!this.value){
            this.items.forEach(el => {
                if(!el.disabled && !selected){
                    selected = true;
                    this.onTapItem(el)
                }
            })
        }
    }
}
</script>