<template>
  <v-app>
    <v-navigation-drawer 
      v-if="$store.state.menuEnabled"
      v-model="$store.state.showDrawer" 
      absolute
      left
      temporary 
      class="bg-inversed"
      width="75%">
      <NavDrawer />
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
    <dialog-items/>
    <kiosk-idle-dialog />
  </v-app>
</template>

<script>
import DialogItems from './components/DialogItems.vue';
import NavDrawer from './components/NavDrawer';
import KioskIdleDialog from './components/KioskIdleDialog.vue';

export default {
  components: {
    DialogItems,
    NavDrawer,
    KioskIdleDialog
  }
}
</script>