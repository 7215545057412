<template>
    <div class="page-delivery-time">
        <TopBar :showBack="true" title="Дата/время доставки" />
        <div class="scrollview" style="padding:10px 10px 70px 10px;">
            <DeliveryDateTime @change="onChangeDeliveryData" />
        </div>
        <div class="page-delivery-submit">
            <div class="btn btn-primary" @click="onSubmit">
                Применить
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import DeliveryDateTime from '../components/DeliveryDateTime.vue';

export default {
    components: {
        TopBar,
        DeliveryDateTime
    },
    data() {
        return {
            deliveryDate: null,
            deliveryTime : null,
            currentDate: null,
            isTimePeriods : null
        };
    },
    methods: {
        onChangeDeliveryData(args){
            this.deliveryDate = args.deliveryDate;
            this.deliveryTime = args.deliveryTime;
            this.currentDate = args.currentDate;
            this.isTimePeriods = args.isTimePeriods;
        },
        onSubmit(){
            if(this.isTimePeriods && !this.deliveryTime){
                alert(`Просьба выбрать время доставки`);
                return;
            }
            const payload = {
                deliveryDate : this.deliveryDate ? this.deliveryDate : this.currentDate,
                deliveryTime : this.deliveryTime,
                isTimePeriods : this.isTimePeriods
            };
            this.$store.commit('saveDeliveryDateTimeProps',payload)
            this.$router.push('/menu/'+this.$store.state.restoran.id+'/delivery');
        }
    }
}
</script>

<style scoped>
.page-delivery-submit {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
</style>