<template>
    <div class="page-booking">
        <TopBar :showBack="true" title="Бронирование стола" />

        <div class="scrollview" style="padding-bottom:70px">
            <booking-date-input v-model="date" />
            <booking-person-cnt-input v-model="cntpersons" />
            <toggle-input v-model="logictype" :items="logicTypes" />

            <list-buttons title="Выбрать время:" v-model="periodfrom" :items="periodsList" v-show="periodsList.length && logictype=='p2r'" />
            
            <booking-restoran-input title="Ресторан:" v-model="restoran" :items="restorans" v-show="restorans.length"/>

            <list-buttons title="Выбрать время:" v-model="periodfrom" :items="periodsList" v-show="periodsList.length && logictype=='r2p'" />
        </div>
        <div class="btn-submit" v-show="allowbooking" @click="tapBooking">
            Продолжить бронирование
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import BookingDateInput from '../components/BookingDateInput.vue';
import BookingPersonCntInput from '../components/BookingPersonCntInput.vue';
import ToggleInput from '../components/ToggleInput.vue';
import ListButtons from '../components/ListButtons.vue';
import BookingRestoranInput from '../components/BookingRestoranInput.vue';

export default {
    components: {
        TopBar,
        BookingDateInput,
        BookingPersonCntInput,
        ToggleInput,
        ListButtons,
        BookingRestoranInput
    },
    data(){
        return {
            date: 0,
            cntpersons: 2,
            cntminutes: 60,
            logictype: '',
            logicTypes: [
                {
                    name: 'По времени',
                    value: 'p2r'
                },
                {
                    name: 'По заведению',
                    value: 'r2p'
                }
            ],
            periodfrom: 0,
            periods: [],
            restorans: [],
            restoran: false,
            allowbooking: false,
            proccessing: false
        }
    },
    watch: {
        date(){
            this.loadData()
        },
        logictype(){
            this.loadData()
        },
        cntpersons(){
            this.loadData()
        },
        periodfrom(){
            this.loadData()
        },
        restoran(){
            this.loadData()
        }
    },
    computed: {
        periodsList(){
            var result = [];
            this.periods.forEach(el => {
                result.push({
                    name: this.timeFormat(el),
                    value: el
                })
            });
            return result;
        }
    },
    methods: {
        tapBooking(){
            if(this.proccessing) return;

            this.$router.push({
                name:'BookingStep2', 
                params: {
                    restoran: this.restoran,
                    cntpersons: this.cntpersons,
                    cntminutes: this.cntminutes,
                    periodfrom: this.periodfrom,
                    date: this.date
                }
            });
        },
        loadData(){
            if(this.proccessing) return;
            this.proccessing = true;
            
            this.request({
                action: 'bookingPeriods',
                fields: {
                    logictype: this.logictype,
                    periodfrom: this.periodfrom,
                    restoranid: this.restoran.id,
                    cntpersons: this.cntpersons,
                    cntminutes: this.cntminutes,
                    date: this.date/1000,
                    timeZoneOffset: new Date().getTimezoneOffset()*60,
                }
            }).then(r => {
                if(r.periods)
                    this.periods = r.periods;
                
                if(r.restorans)
                    this.restorans = r.restorans;
                
                if(typeof r.allowbooking != 'undefined')
                    this.allowbooking = r.allowbooking;
                
                this.proccessing = false;
            }).catch(() => {
                this.allowbooking = false;
                this.proccessing = false;
                alert(this.$error502);
            })
        }
    }
}
</script>

<style scoped>
.btn-submit {
    height: 55px;
    color: #fff;
    background-color:#0071E5;
    border-radius: 40px;
    margin: 0px;
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>