<template>
    <div class="page-zagruzka">
        <TopBar title="Загруженность ресторанов" :showBack="true" class="action-bar" />
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <div v-show="!proccessing">
            <ToggleInput :items="inputTypes" style="margin:20px" v-model="timeType" :editable="true" />
            <TimeSlidePicker v-if="timeType=='future'" :items="periods" v-model="time" />
            <span class="list-title-sm">Сортировка:</span>
            <ToggleInput :items="sortTypes" style="margin:0 20px 10px 20px" v-model="sortType" :editable="true" />
            <div style="display: flex;justify-content: space-between;align-items: baseline;">
                <span class="list-title">Выберите заведение</span>
                <span class="list-title-sm">Загруженность</span>
            </div>
        </div>
        <div v-show="!proccessing" class="restoran-item-list scrollbar-hidden">
            <div >
                <div v-for="(item, index) in items" :key="index" rows="auto" columns="60,*,auto" class="restoran-item"
                iosOverflowSafeArea="false" @click="onTapItem(item)">
                    <img 
                        width="26" col="0"
                        :src="$assetsUrl+'/assets/icon_rest.png'" class="restoran-item-img"/>
                    <div col="1" class="restoran-item-info">
                        <span class="restoran-item-name">{{item.name}}</span>
                        <span class="restoran-item-address" >{{item.address}}</span>
                        <span class="restoran-item-dist" v-if="item.dist != null">{{item.distStr}}</span>
                    </div>
                    <div v-if="item.isFavorite >= 0" class="restoran-item-heart">
                        <i class="far fa-heart"></i>
                    </div>
                    <img :src="item.img" v-if="item.img" width="40px" height="40px" style="margin:5px"
                            horizontalAlignment="right" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import TopBar from '/src/components/TopBar';
import ToggleInput from '/src/components/ToggleInput';
import TimeSlidePicker from '/src/components/TimeSlidePicker';

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

const inputTypes = [
    {
        value: 'now',
        text: 'Сейчас'
    },
    {
        value: 'future',
        text: 'Прогноз'
    },
];

const sortTypes = [
    {
        value: 'zagruzka',
        text: 'По загруженности'
    },
    {
        value: 'location',
        text: 'По удаленности'
    },
];


export default {
    components: {
        TopBar,
        ToggleInput, 
        TimeSlidePicker
    },
    data(){
        return {
            inputTypes,
            timeType: '',
            sortTypes,
            sortType: '',
            periods: [],
            proccessing: false,
            time: '',
            showTimeDialog: false,
            restorans: [],
            location: {lat:null,lon:null}
        }
    },
    watch: {
        timeType(v){
            if(v == 'now'){
                this.time = '';
            }
        },
        sortType(v){
            if(v == 'location' && this.location.lat == null){
                this.proccessing = true;
                this.getLocation()
                    .then(location => {
                        this.location.lat = location.lat;
                        this.location.lon = location.lng;
                        this.proccessing = false;
                    })
                    .catch(e=>{
                        console.log('getlocation catch',e)
                        this.proccessing = false;
                    })
            }
        }
    },
    computed: {
        items(){
            var result = [];
            this.restorans.forEach(item => {
                item.isFavorite = this.$store.state.favorite_restorans.findIndex((rest)=>{
					return item.fs_uid == rest.fs_uid
				});


                if(this.sortType == 'location' && this.location.lat != null && item.location != null){
                    item.dist = getDistanceFromLatLonInKm(item.location[0], item.location[1], this.location.lat, this.location.lon);
                    item.distStr = ' (' + (Math.round(item.dist * 10) / 10) + ' км)';
                }else{
                    item.dist = null;
                    item.distStr = ''
                }

                result.push(item);
            });

            if(this.sortType == 'location'){
                result.sort((a,b) => b.isFavorite - a.isFavorite || a.dist - b.dist)
            }else{
                result.sort((a,b) => b.isFavorite - a.isFavorite || a.zagruzkaPerc - b.zagruzkaPerc)
            }
			
            return result;
        }
    },
    created(){
        this.loadData();
    },
    methods: {
        onTapItem(item){
            this.$router.push(`/zagruzka/detail/${item.fs_uid}/${this.time}`);
            // this.goto(this.$routes.ZagruzkaDetail, {
            //     restoran: item,
            //     time: this.time
            // });
        },
        loadData(){
            if(this.proccessing) return;
            this.proccessing = false;

            this.periods = [];
            this.restorans = [];

            this.request({
                action: 'zagruzkaLoadPeriods'
            }).then(r => {
                this.periods = r;
                this.request({
                    action: 'zagruzkaLoadRestList'
                }).then(r => {
                    this.restorans = r;
                    this.proccessing = false;
                }).catch(() => {
                    alert('Ошибка загрузки данных')
                    this.proccessing = false
                })
            }).catch(() => {
                alert('Ошибка загрузки данных')
                this.proccessing = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.list-title-sm {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    color: #8A90AF;
    font-family:sans-serif;
    margin: 0 26px 5px;
}

.page-zagruzka {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.list-title {
    color: #8A90AF;
    font-size: 14px;
    text-transform: uppercase;
    font-family:sans-serif;
    margin: 0 26px 14px;
    display: block;
}

.restoran-item-img {
    margin: 10px 17px;
    height: auto;
    align-self: center;
}

.restoran-item-info {
    margin: 0 15px 0 0;
    flex-grow: 1;
    align-self: center;
}

.restoran-item {
    margin: 0 20px 10px 20px;
    border-width: 1px;
    border-color: #C1D0E1;
    border-radius: 5px;
    border-style: solid;
    display: flex;
}

.restoran-item-name {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin: 5px 0 3px 0;
    text-transform: capitalize;
    display: block;
}

.restoran-item-address {
    font-size: 12px;
    font-weight: 400;
    color: #506883;
    display: block;
    margin: 0 0 5px 0;
}

.restoran-item-heart {
    font-size:22px;
    color:#d50000;
    margin: 0 15px 0 0;
    align-self: center;
}

.restoran-item-list {
    overflow: auto;
}

.action-bar {
    flex: 0 0 60px;
}

.restoran-item-dist {
    font-size: 14px;
    font-weight: 400;
    color: #506883;
    margin: 0 0 5px 0;
    display: block;
}

</style>