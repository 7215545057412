<template>
    <div class="page-delivery-map">
        <TopBar :showBack="true" title="Адрес доставки"/>
        <yandex-map :coords="coords" :controls="controls"
                :zoom="zoom" @map-was-initialized="onMapInit"></yandex-map>
        <div class="address-info-top">
            <div class="address-full" v-show="address.full">
                {{address.full}}
            </div>
        </div>
        <div class="address-info">
            <div v-show="textPeriods" class="address-periods">
                {{textPeriods}}
            </div>
            <div class="address-error" v-show="address_error">{{address_error}}</div>
            <div class="btn btn-primary" v-show="zone_id" @click="onTapImHere">Я ЗДЕСЬ</div>
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <AddressInputDialog :value="address" :modalVisible="modalVisible" @hide="modalVisible=false" @input="onAddressInput" />
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import AddressInputDialog from '../components/AddressInputDialog';

// var placemarkObj = null;
var deliveryPoint = null;
var zones = [];
// var customControl = null;

export default {
    components: {
        TopBar,
        AddressInputDialog
    },
    data(){
        return {
            coords: [55.755864, 37.617698],
            zoom: 10,
            controls: [
                'zoomControl','geolocationControl','searchControl'
            ],
            map: null,
            proccessing: true,
            zone_id: null,
            restoran_id: null,
            address_full: null,
            delivery_coords: null,
            address_error: null,
            address: {
                full: '',
                room: '',
                podjezd: '',
                domofon: '',
                etaz: '',
                comment: '',

                id: 0,
                short: '',
                long: '',
                house: '',
                street: '',
                lat: 0,
                lon: 0
            },
            modalVisible: false
        }
    },
    computed: {
        curRestoran(){
            if(!this.restoran_id) return null;
            return this.$store.state.restorans.filter(el => el.id == this.restoran_id)[0];
        },
        curZone(){
            if(!this.zone_id || !this.restoran_id) return null;
            return this.curRestoran.deliveryZone.filter(el => el.id == this.zone_id)[0];
        },
        textPeriods(){
            if(!this.zone_id) return '';
            if(this.curZone.periods)
            {
                var periods = JSON.parse(this.curZone.periods);
                var result = [];
                var date = new Date();
                var minutes = date.getMinutes() + date.getHours()*60;
                var today = true;

                periods.forEach(item => {
                    let from = item.from.split(':');
                    from = from[0]*60+from[1]*1;
                    item.disabled = today && (from - this.curZone.time_limit*1 < minutes);
                    if(!item.disabled)
                    {
                        result.push('с ' + item.from + ' до ' + item.to);
                    }
                });

                if(result.length)
                {
                    return 'Доступное время доставки сегодня: ' + result.join(', ');
                }
                
                if(periods.length)
                {
                    return 'На сегодня нет доступного времени доставки в выбранную зону';
                }
            }

            return '';
        }
    },
    methods: {
        onAddressInput(v){
            this.modalVisible = false;
            this.address = v;

            fetch(this.$serverUrl + '/api/v2/user/address/save',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    address: this.address,
                    user_id: this.$store.state.user.id,
                    user_token: this.$store.state.user.password
                })
            })
            .then(r => r.json())
            .then(data => {
                this.address.id = data.result;

                this.$store.state.curAddress = this.address;
                this.$store.state.curZone = this.curZone;
                this.$store.commit('setRestoran', this.curRestoran)
                this.$store.commit('orderType', 'delivery');
                this.$router.push('/delivery_time')
            }).catch(() => {
                alert('Ошибка сохранения адреса, проверьте подключение или попробуйте еще раз.');
            })
        },
        onTapImHere(){
            // console.log('imhere', JSON.stringify(this.address))
            this.modalVisible = true;
        },
        onMapInit(map){
            this.map = map;
            this.clearAddress();

            var CustomControlClass = function (options) {
                CustomControlClass.superclass.constructor.call(this, options);
                this._$content = null;
                this._geocoderDeferred = null;
            };

            window.ymaps.util.augment(CustomControlClass, window.ymaps.collection.Item, {
                onAddToMap: function (map) {
                    CustomControlClass.superclass.onAddToMap.call(this, map);
                    this.getParent().getChildElement(this).then(this._onGetChildElement, this);
                },
                _onGetChildElement: function (parentDomContainer) {
                    // Создаем HTML-элемент с текстом.
                    this._$content = document.createElement('div');
                    this._$content.id = 'customControl';
                    this._$content.className = 'customControl address-info-top address-full';
                    // this._$content.innerHTML = '{{address_full}}';
                    parentDomContainer.appendChild(this._$content);
                    // $('<div class="customControl">123</div>').appendTo(parentDomContainer);
                    // this._mapEventGroup = this.getMap().events.group();
                },
                onRemoveFromMap: function (oldMap) {
                    if (this._$content) {
                        this._$content.remove();
                        this._mapEventGroup.removeAll();
                    }
                    CustomControlClass.superclass.onRemoveFromMap.call(this, oldMap);
                }
            });

            // customControl = new CustomControlClass();
            // map.controls.add(customControl, {
            //     float: 'none',
            //     position: {
            //         top: 10,
            //         left: 10
            //     }
            // });

            map.options.set({
                searchControlSize: 'large',
                searchControlNoPlacemark: true,
                searchControlPlaceholderContent: 'Укажите адрес доставки',
                geolocationControlNoPlacemark: true
            })

            deliveryPoint = new window.ymaps.GeoObject({
                geometry: {type: 'Point'},
                // properties: {iconCaption: 'Адрес'}
            }, {
                preset: 'islands#blackDotIconWithCaption',
                draggable: true,
                iconCaptionMaxWidth: '215'
            })

            map.geoObjects.add(deliveryPoint);

            var searchControl = map.controls.get('searchControl');
            searchControl.events.add('resultshow', e => {
                var coords = searchControl.getResultsArray()[e.get('index')].geometry.getCoordinates();
                this.setLocation(coords);
            });

            map.controls.get('geolocationControl').events.add('locationchange', e => {
                var coords = e.get('geoObjects').get(0).geometry.getCoordinates();
                this.setLocation(coords);
            });

            deliveryPoint.events.add('dragstart', () => {
                this.clearAddress();
                // deliveryPoint.properties.set({iconCaption: '', balloonContent: ''});
                // deliveryPoint.options.set('iconColor', 'black');
            });

            deliveryPoint.events.add('dragend', () => {
                var coords = deliveryPoint.geometry.getCoordinates();
                this.setLocation(coords);
            });

            this.getRestorans().then(() => {
                this.drawAreas(map);

                map.events.add('click', e => {
                    this.setLocation(e.get('coords'));
                })
                map.geoObjects.events.add('click', e => {
                    this.setLocation(e.get('coords'));
                })

                window.ymaps.geolocation.get({mapStateAutoApply: true}).then(r => {
                    var coords =  r.geoObjects.get(0).geometry.getCoordinates();
                    this.setLocation(coords);
                    this.proccessing = false;
                }).catch(()=>{
                    this.proccessing = false;
                })
            })
        },
        clearAddress(){
            this.address_error = 'Поиск..';
            this.address.full = '';
            this.address.room = '';
            this.address.podjezd = '';
            this.address.domofon = '';
            this.address.etaz = '';
            this.address.comment = '';

            this.address.id = 0;
            this.address.short = '';
            this.address.long = '';
            this.address.house = '';
            this.address.street = '';
            this.address.lat = 0;
            this.address.lon = 0;

            this.delivery_coords = null;
            this.zone_id = null;
            this.restoran_id = null;
        },
        drawAreas(map){
            var zonePolygons = [];
            this.$store.state.restorans.forEach(restoran => {
                if(!restoran.deliveryCourier) return;

                restoran.deliveryZone.forEach(zone => {
                    if(!zone.active) return;

                    var jsonPolygon = JSON.parse(zone.polygon);

                    var polygon = new window.ymaps.GeoObject({
                        geometry: {
                            type: "Polygon",
                            coordinates: jsonPolygon,
                        },
                        properties: {
                            zone_id: zone.id,
                            restoran_id: restoran.id
                        }
                    }, {
                        fillColor: this.formatColor(zone.color),
                        strokeColor: this.formatColor(zone.color),
                        strokeWidth: 2
                    });

                    zonePolygons.push(polygon);
                    // map.geoObjects.add(polygon);
                })
            })
            zones = window.ymaps.geoQuery(zonePolygons).addToMap(map);
            // this.findMyLocation()
        },
        setLocation(coords){
            this.clearAddress();
            var polygon = zones.searchContaining(coords).get(0);
            
            if(polygon){
                this.delivery_coords = coords;
                this.address.lat = coords[0];
                this.address.lon = coords[1];
                this.zone_id = polygon.properties.get('zone_id');
                this.restoran_id = polygon.properties.get('restoran_id');
                this.address_error = null;
            }else{
                this.address_error = 'По данному адресу доставка невозможна';
            }
            
            deliveryPoint.geometry.setCoordinates(coords);

            this.map.setCenter(coords)
            this.map.setZoom(18)

            window.ymaps.geocode(coords).then(res => {
                var firstGeoObject = res.geoObjects.get(0);

                // customControl._$content.innerText = firstGeoObject.getAddressLine()

                this.address.full = firstGeoObject.getAddressLine() ? firstGeoObject.getAddressLine() : '';
                this.address.long = firstGeoObject.getAddressLine() ? firstGeoObject.getAddressLine() : '';
                this.address.street = firstGeoObject.getThoroughfare() ? firstGeoObject.getThoroughfare() : '';
                this.address.house = firstGeoObject.getPremiseNumber() ? firstGeoObject.getPremiseNumber() : '';
                // deliveryPoint.properties
                //     .set({
                //         iconCaption: [
                //             firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                //             firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                //         ].filter(Boolean).join(', '),
                //         // В качестве контента балуна задаем строку с адресом объекта.
                //         balloonContent: firstGeoObject.getAddressLine()
                //     });
            });
        }
    }
}
</script>

<style>
.page-delivery-map .ymap-container {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
}

.address-info {
    position: fixed;
    bottom: 5px;
    left: 5px;
    right: 5px;
}

.address-full {
    /* background: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #aaa;
    text-align: center;
    box-shadow: 0px 1px 5px #aaa;
    line-height: 1.2; */
    text-align: center;
    font-size: 28px;
    background: #ffffffaa;
    padding: 10px;
}

.address-info-top {
    position: fixed;
    bottom: 160px;
    left: 0px;
    right: 0px;
}

.customControl {
    background: #f00;
}

.address-error {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 5px #aaa;
}

.address-periods {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 5px #aaa;
    margin: 0 0 5px 0;
}
</style>