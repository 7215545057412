<template>
    <div class="page-payment">
        <TopBar :title="title" :showBack="true" backPath="/" />
        <div class="scrollview" style="padding:10px 10px 70px 10px">
            <div class="text-gray mb-15px">
                <div v-show="$store.state.kiosk">
                    Стол: #{{$store.state.tableId}}
                </div>
                {{'Сумма заказа: ' + orderSum.toFixed(2) + ' руб.'}}

            </div>
            <div v-show="wait">
                Обработка платежа. Ждём подтверждения транзакции Вашим банком.
            </div>
            <div class="mb-5px" v-show="wait == false && !$store.state.kiosk" >
                Способ оплаты:
            </div>
            <select class="input-text mb-15px" v-show="wait==false && !$store.state.kiosk" v-model="paysystemActive">
                <option :value="item.value" v-for="(item,index) in paySystems" :key="index">{{item.name}}</option>
            </select>
            <!-- <div class="button-dropdown mb-15px" v-show="wait==false" @tap="showModalSelect">
                <span>
                    {{paymentTypes[paymentType]}}
                </span>
                <img src="../assets/shev_bottom.png" alt="">
            </div> -->
            <div v-show="sbpDefaultLink.length > 0 && $store.state.kiosk" class="sbp-qr-code">
                <div class="mb-20px">
                    Для оплаты заказа отсканируйте QR код через приложение вашего банка
                </div>
                <QrcodeVue :value="sbpDefaultLink" size="400" />
                <img src="../assets/sbp-logo.jpeg" alt="" height="100px">
            </div>
        </div>

        <div v-show="wait == false && !$store.state.kiosk" class="btn-wrap">
            <div class="btn btn-big btn-primary" v-show="paysystemActive=='card'" @click="payCard">
                Оплатить картой
            </div>
            <div class="btn-pay " v-show="!!$store.state.restoran.sbp && paysystemActive == 'sbp'" @click="paySbp">
                <img src="../assets/sbp.png"/>
            </div>
        </div>

        <div v-show="sbpVariants.length > 0 && !$store.state.kiosk" class="sbp-variants-wrap">
                <div class="sbp-variants-fill" @click="closeSbpVariants"></div>
                <div class="sbp-variants-title">
                    <div style="font-size:20px;margin:0 15px 10px 15px">
                        Выберите свой банк
                    </div>
                    <div style="font-size:14px;margin:0 15px 10px 15px">
                        Для завершения оплаты откроется приложение выбранного банка
                    </div>
                </div>
                <div class="sbp-variants-list">
                    <a class="btn-pay" style="margin:0 10px 10px 10px;background:#0071E5;"  v-for="(item,key) in sbpVariants" :key="key" :href="item.link">
                        <img :src="item.logo" v-if="item.logo" style="max-width:30px;height:auto"/>
                        <span>
                            {{item.name}}
                        </span>
                    </a>
                </div>
                <div class="sbp-variants-default">
                    <a class="btn-pay" style="margin:10px;" :href="sbpDefaultLink">
                        <span>
                            Открыть приложение по умолчанию
                        </span>
                    </a>
                </div>
            </div>
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import TopBar from '/src/components/TopBar';
import QrcodeVue from 'qrcode.vue';

export default {
    props: ['order','orderNum','orderSum','orderId','paysystems','bindings','paysystem','card','navback'],
    components: {
        TopBar,
        QrcodeVue
    },

    data(){
        return {
            paymentType:0,
            paySystems: [
                {
                    value: 'sbp',
                    name: 'СБП'
                },
                {
                    value: 'card',
                    name: 'Картой онлайн'
                },
            ],
            //paymentTypes:['Картой через приложение','ApplePay'],
            paymentTypes:[
                'СБП'
            ],
            loading: false,
            wait: false,
            googlePaySupport: false,
            applePaySupport: false,
            paymentClickable: true,
            sbpkey: 0,
            sbpVariants: [],
            sbpDefaultLink: '',
            paymentInterval: false,
            paysystemActive: this.paysystem,
            paid: false
        }
    },
    computed: {
        title(){
            return 'Оплата заказа ' + this.orderNum
        }
    },
    created(){
        if(this.paysystem){
            switch(this.paysystem)
            {
                case 'sbp':
                    setTimeout(() => {
                        this.paySbp();
                    }, 500);
                break;
                case 'card':
                    setTimeout(() => {
                        this.payCard();
                    }, 500);
                break;
            }
        }else{
            this.paysystemActive = this.paySystems[0].value;
        }
    },
    beforeDestroy(){
        if(this.paymentInterval){
            clearTimeout(this.paymentInterval);
            this.paymentInterval = false;
        }
    },
    methods: {
        payCard(){
            if(this.loading) return;
            this.loading = true;
            fetch(this.$serverUrl + '/api/v1/order/payment/start',{
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    token: this.$store.state.user.password,
                    fs_uid: this.$store.state.restoran.fs_uid,
                    order_id: this.orderId,
                    binding_id: this.card
                })
            })
            .then(r => r.json())
            .then(result => {
                if(result.errorMessage)
                {
                    this.showDialog({
                        title:'Ошибка',
                        message:result.errorMessage,
                        items: ['Ok']
                    })
                    this.loading = false;
                }
                else
                {
                    window.location.href = result.formUrl;
                    // this.paymentModal(result)
                }
            }).catch(e=>{
                this.loading = false;
                this.showDialog({
                    title:'Ошибка',
                    message:'Ошибка инициализации платежа',
                    items: ['Ok']
                })
                console.log(e)
            })
        },
        closeSbpVariants(){
            this.sbpVariants.splice(0);

            this.paymentClickable = true;
        },
        onTapSbpVariant(item){
            console.log('onTapSbpVariant')
            var x = null
            try{
                window.location.href = item.link;
            }catch(err){
                console.log('open err',err)
                window.location.href = this.sbpDefaultLink
            }finally {
                console.log('finally',x)
                if(!x){
                    console.log('finally !x')
                    window.location.href = this.sbpDefaultLink
                }
            }
            this.sbpVariants.splice(0);
            this.sbpDefaultLink = '';
            
            this.loading = true;
            this.wait = true;
            this.paymentInterval = setTimeout(()=>{
                this.checkPayment();
            }, 5000);
        },
        checkPayment(){
            if(this.paymentInterval){
                clearTimeout(this.paymentInterval);
                this.paymentInterval = false;
            }

            fetch(this.$serverUrl + '/api/v1/order/payment/check/'+this.orderId)
            .then(r=>r.json())
            .then(r=>{
                console.log('checkpayment',r)
                if(r.status == 'ok'){
                    this.loading = false;
                    this.paid = true;
                    this.$router.push('Thanks');
                    // this.$navigateTo(this.$routes.OrderSuccess,{
                    //     clearHistory: true,
                    //     props: {
                    //         order: this.order,
                    //         clear: true
                    //     }
                    // })
                }
                else if(r.status == 'fail'){
                    this.loading = false;
                    this.paymentClickable = true;
                    this.wait = false;
                    alert('Ошибка проведения платежа.')
                }
                else {
                    this.paymentInterval = setTimeout(()=>{
                        this.checkPayment();
                    },5000)
                }
            })
        },
        onTapSbpDefault(){
            window.open(this.sbpDefaultLink, '_blank').focus();
            this.sbpVariants.splice(0);
            this.sbpDefaultLink = '';
            
            this.loading = true;
            this.wait = true;
            this.paymentInterval = setTimeout(()=>{
                this.checkPayment();
            }, 5000);
        },
        showModalSelect(){
            
        },
        paySbp(){
            if(!this.paymentClickable) return;
            if(this.loading == true) return;

            this.paymentClickable = false;
            this.loading = true;
            this.sbpVariants.splice(0);
            this.sbpDefaultLink = '';
            fetch(this.$serverUrl + '/api/v1/order/payment/sbp',{
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: this.$store.state.user.id,
                    token: this.$store.state.user.password,
                    fs_uid: this.$store.state.restoran.fs_uid,
                    order_id: this.orderId
                })
            })
            .then(r => r.json())
            .then(result => {
                if(result.errorMessage)
                {
                    this.showDialog({
                        title:'Ошибка',
                        message:result.errorMessage,
                        items: ['Ok']
                    })
                    this.paymentClickable = true;
                    this.loading = false;
                }
                else if(!!result.variants && result.variants.length > 0)
                {
                    this.loading = false;

                    result.variants.forEach(el => {
                        this.sbpVariants.push(el)
                    })

                    this.sbpDefaultLink = result.paymentUrl;

                    if(this.$store.state.kiosk){
                        this.wait = true;
                        this.paymentInterval = setTimeout(()=>{
                            this.checkPayment();
                        }, 5000);
                    }
                }
                else if(result.paymentUrl)
                {
                    window.open(result.paymentUrl, '_blank').focus();
                    //
                    // openUrl(result.paymentUrl);!!!!!
                    this.wait = true;
                    this.paymentInterval = setTimeout(()=>{
                        this.checkPayment();
                    }, 5000);
                }
                else
                {
                    this.wait = true;
                    this.paymentInterval = setTimeout(()=>{
                        this.checkPayment();
                    }, 5000);
                }
            }).catch(e=>{
                this.paymentClickable = true;
                this.loading = false;
                this.showDialog({
                    title:'Ошибка',
                    message:'Ошибка инициализации платежа',
                    items: ['Ok']
                })
                console.log(e)
            })
        }
    }
}
</script>

<style scoped>
.btn-wrap {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.sbp-variants-wrap {
    background: #00000055;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.sbp-variants-title {
    background:#fff;padding:10px 0 0 0;border-radius:10px 10px 0 0;
overflow: auto;
}

.sbp-variants-list {
    background-color:#fff;
    height:300px;
    overflow: auto;
}

.sbp-variants-default {
    background-color:#fff;
    overflow: auto;
}

.sbp-variants-fill {
    flex-grow: 1;
}

.sbp-qr-code {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>