<template>
    <div class="card-product" @click="tapDetail">
        <div class="card-product-img" :style="{'background-image':'url('+(item.thumb?item.thumb:'/src/assets/placeholder.png')+')'}">
            <div v-show="!item.allowed" 
                class="text-stoplist">
                <span>
                    {{$store.state.settings.text_stoplist}}
                </span>
            </div>
        </div>
        <div class="card-product-info">
            <span class="font-europec-bold card-product-title">
                {{item.name}}
            </span>
            <div class="card-product-about">
                <div class="card-product-about-info">
                    <span class="font-europec-bold card-product-weight" v-if="item.weight">
                        {{item.weight}}
                    </span>
                    <span class="font-europec card-product-sostav" v-if="item.sostav">
                        {{item.sostav}}
                    </span>
                    <div class="card-product-price">
                        <span class="font-europeext-bold card-product-price-num">
                            {{item.price}}
                        </span>
                        <span class="card-product-price-sym">
                            ₽
                        </span>
                    </div>
                </div>
                <div class="card-product-qnt">
                    <span class="card-product-inc" @click="cntMinus"  :class="{'bg-grey':!item.allowed}">
                        <i class="fas fa-minus"></i>
                    </span>
                    <span class="font-europec-bold card-product-cnt text-center">
                        {{qnt}}
                    </span>
                    <span  class="card-product-inc" @click="cntPlus"
                        :class="{'bg-grey':!item.allowed}">
                        <i class="fas fa-plus"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],

    data(){
        return {
            cnt: 0,
            stoptap: false
        }
    },
    computed: {
        qnt() {
            const cartItem = this.$store.state.cart.filter(item => item.item.id == this.item.id)

            var cnt = 0;

            cartItem.forEach(cart => {
                cnt += cart.cnt;
            })

            return cnt>0?cnt:'0';
        }
    },
    methods: {
        tapDetail(){
            setTimeout(()=>{
                if(!this.stoptap)
                // this.goto(this.$routes.Product,{item: this.item});
                // this.$router.push('/product/'+this.item.id);
                this.$emit('onTap', this.item)
            },100)
        },
        cntMinus(){
            this.stoptap = true;
            setTimeout(()=>{
                this.stoptap = false;
            },500)
            this.$store.commit('cartCnt',{
                item: this.item,
                cnt: -1,
                spec: {
                    specid: ''
                }
            })
        },
        cntPlus(){
            if(!this.item.allowed) return;
            this.stoptap = true;
            setTimeout(()=>{
                this.stoptap = false;
            },500)

            if(this.item.is_locked)
            {
                this.checkLockCode().then(res => {
                    if(res){
                        if(this.item.spec.length != 0)
                        {
                            this.showModalSpec(this.item)
                        }
                        else
                        {
                            this.$store.commit('addToCart',{
                                item: this.item,
                                cnt: 1
                            })
                        }
                    }
                })
            }
            else
            {
                if(this.item.spec.length != 0)
                {
                    this.showModalSpec(this.item)
                }
                else
                {
                    this.$store.commit('addToCart',{
                        item: this.item,
                        cnt: 1
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.card-product {
    display: flex;
    padding: 5px 10px 5px 5px;
}

.card-product-img {
    width: 130px;
    height: 100px;
    background-position: center;
    background-size: cover;
    flex: 0 0 130px;

    border-radius:10px;
    border: 2px solid #d9d8d9;
}

.card-product-info {
    flex: 1 1 100%;
    margin: 0 0 0 10px;
}

.card-product-qnt {
    flex: 1 0 auto;
}

.card-product-title
{
    color: #01315d;
    font-size:18px;
    display: block;
}
.card-product-weight
{
    color: #01315d;
    font-size:14px;
    display: block;
}

.card-product-sostav
{
    color: #01315d;
    font-size:12px;
}

.card-product-about
{
    display: flex;
}

.card-product-about-info
{
    flex: 1 1 100%;
}

.card-product-price
{
    background: #01315d;
    border-radius: 5px;
    vertical-align: center;
    padding: 2px 7px;
    margin: 5px 0 0 0;
    display: inline-block;
}

.card-product-price-num
{
    color: #fff;
    font-size: 18px;

    margin: 5px 0 0 0;
    display: inline-block;
}

.card-product-price-sym
{
    color:#fff;
    font-size: 16px;
}


.card-product-inc
{
    width:30px;
    height:30px;
    border-radius: 15px;
    background-color: #ccc;
    border: 1px solid #c4c1c1;
    display: inline-block;
    text-align: center;
    color: #fff;
    line-height: 30px;
}

.card-product-cnt
{
    display: inline-block;
    color: #181818;
    width: 30px;
    line-height: 30px;
    text-align: center;
}

.text-stoplist 
{
    background: #ffffff99;
    width: 130px;
    height: 100px;
    border-radius: 10px;
    position: relative;
}

.text-stoplist span
{
    display: block;
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin: -15px 0 0 0;
    transform: rotate(-45deg);
}
</style>