<template>
    <div class="page-about-delivery">
        <TopBar :showMenu="true" title="Оферта" />
        <iframe :src="$assetsUrl+'/oferta.php'" frameborder="0" class="frame-one"></iframe>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
export default {
    components: {TopBar}
}
</script>

<style scoped>
.frame-one {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 60px);
}
</style>