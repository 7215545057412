<template>
    <div>
        <span  class="v-title">Выбрать время:</span>
        <div class="checkbox-item" style="margin:5px 10px 20px 10px" @click="showTimeDialog=true">
            <img src="../assets/icon-time_gotov-0.png" v-show="!value" />
            <img src="../assets/icon-time_gotov-1.png" v-show="value" />
            <span>
                {{value}}
            </span>
            <img src="../assets/icon-check-blue.png" v-show="value" />
        </div>
            <v-dialog v-model="showTimeDialog">
                <div class="modal-select">
                    <div class="modal-info">
                        <div class="modal-title">
                            Выберите время
                        </div>
                    </div>
                    <div class="modal-scroll-list">
                        <div v-for="(item, index) in items" :key="index"
                            class="modal-scroll-item" @click="onSelect(item)"
                            :class="{active: item.value == value}">
                            {{ item.text }}
                        </div>
                    </div>
                    <div class="button active last" @click="onSubmit">Ok</div>
                </div>
            </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['items','value'],
    data(){
        return {
            showTimeDialog: true
        }
    },
    computed: {
        currentItem: {
            set(v){
                this.$emit('input', this.items[v].value)
            },
            get(){
                const index = this.items.findIndex(item => item.value == this.value);
                if(index < 0){
                    if(this.items.length > 0){
                        this.$emit('input', this.items[0].value)
                    }
                    return 0;
                }else{
                    return index;
                }
            },
        },
        variants(){
            return this.items.map((item)=>item.text);
        }
    },
    created(){
        if(!this.value){
            this.$emit('input', this.items[0].value)
        }
    },
    methods: {
        onSelect(item){
            this.$emit('input', item.value)
        },
        onSubmit(){
            this.showTimeDialog = false;
        }
    }
}
</script>


<style scoped>
.v-title {
    text-transform: uppercase;
    font-size: 12px;
    color: #8A90AF;
    font-family:sans-serif;
    margin: 0 26px 0;
}
</style>