<template>
    <div>
        <div class="mb-10px" >
            Дата доставки
        </div>
        <div class="checkbox-item mb-10px"  @click="onTapDate">
            <img src="../assets/icon-date-0.png" />
            <span>
                {{!value.length || value == currentDate ? 'Сегодня' : value}}
            </span>
        </div>
        <v-dialog v-model="showDateDialog">
            <div class="modal-select">
                <div class="modal-info">
                    <div class="modal-title">
                        Выберите дату
                    </div>
                </div>
                <div class="modal-scroll-list">
                    <div v-for="(dateItem, index) in dateVariants" :key="index"
                        class="modal-scroll-item" @click="onSelectDate(dateItem)"
                        :class="{active: dateItem == dateSelected}">
                        {{ dateItem }}
                    </div>
                </div>
                <div class="button active last" @click="onSubmitDate">Ok</div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['value','maxDays'],
    data(){
        return {
            showDateDialog: false,
            dateSelected: ''
        }
    },
    computed: {
        currentDate(){
            return this.dateFormat(new Date());
        },
        dateVariants(){
            var result = [];
            var date = new Date();

            for(var i = 0; i < (this.maxDays?this.maxDays:this.$store.state.restoran.deliveryDays); i++){
                date.setDate(date.getDate() + (i?1:0));
                result.push(this.dateFormat(date));
            }

            return result;
        }
    },
    created(){
        if(!this.value){
            this.$emit('input', this.dateFormat(new Date()));
        }
    },
    methods: {
        onSelectDate(date){
            this.dateSelected = date;
        },
        onSubmitDate(){
            this.$emit('input', this.dateSelected);
            this.showDateDialog = false;
        },
        onTapDate()
        {
            this.dateSelected = this.value;
            this.showDateDialog = true;
        }
    }
}
</script>