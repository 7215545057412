<template>
    <div>
        <div class="key-pad">
            <div class="key-pad-row">
                <div class="key-pad-item" @click="onTap(1)">1</div>
                <div class="key-pad-item" @click="onTap(2)">2</div>
                <div class="key-pad-item" @click="onTap(3)">3</div>
            </div>
            <div class="key-pad-row">
                <div class="key-pad-item" @click="onTap(4)">4</div>
                <div class="key-pad-item" @click="onTap(5)">5</div>
                <div class="key-pad-item" @click="onTap(6)">6</div>
            </div>
            <div class="key-pad-row">
                <div class="key-pad-item" @click="onTap(7)">7</div>
                <div class="key-pad-item" @click="onTap(8)">8</div>
                <div class="key-pad-item" @click="onTap(9)">9</div>
            </div>
            <div class="key-pad-row">
                <div class="key-pad-item btn-danger" @click="onTap('cancel')"><i class="fas fa-times"></i></div>
                <div class="key-pad-item" @click="onTap(0)">0</div>
                <div class="key-pad-item btn-success" @click="onTap('ok')"><i class="fas fa-check"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value'],
    methods: {
        onTap(v){
            switch(v){
                case 'ok':
                    this.$emit('submit')
                    break;
                case 'cancel':
                    this.$emit('input','')
                    break;
                default:
                    this.$emit('input',this.value+''+v)
                    break;
            }
        },
    }
}
</script>

<style scoped>
.key-pad {
    display: flex;
    flex-direction: column;
    width: 210px;
    margin: 0 auto;
}
.key-pad-row {
    display: flex;
    flex-direction: row;
}
.key-pad-item {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 20px; */
    height: 50px;
    background: transparent;
    border: 1px solid #fff;
    flex: 1 1 100%;
    /* border-right-color: #eee; */
    /* border-bottom-color: #eee; */
    margin: 2px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.btn-danger {
    background: #ff5252;
}

.btn-success {
    background: #4caf50;
}
</style>