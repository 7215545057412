<template>
    <div class="page-product">
        <div class="product-photo" :style="{'background-image':'url('+(item.img?item.img:'/src/assets/placeholder.png')+')'}"></div>
        <div class="product-btn-back" @click="onTapBack">
            <i class="fas fa-chevron-left"></i>
        </div>

        <div class="product-btn-star" @click="onStar" v-show="!$store.state.kiosk">
            <i class="fas fa-heart" v-show="isFavorite == 2" style="font-size:26px;color:#d50000;"></i>
            <i class="far fa-heart" v-show="isFavorite == 1" style="font-size:26px;color:#ccc;"></i>
        </div>
        <div class="product-info">
            <div class="cook-time-wrap" v-if="cookTime"> 
                <div class="cook-time"> 
                    <span class="cook-time-label">Приготовим примерно за: </span>
                    <span class="cook-time-value">{{cookTime}}</span>
                </div>
            </div>
            <div class="font-europec-bold page-product-title">
                {{item.name}}
            </div>
            <div class="font-europec-bold page-product-weight" v-if="item.weight" >
                {{item.weight}}
            </div>
        </div>
        <div style="margin:0 10px 10px 10px;display:flex;flex-direction:row;" class="product-sostav-hr">
            <div >
                <div class="font-europec" style="color:#01315d;font-size:16px;margin:0 10px 0 0;">
                    СОСТАВ:
                </div>
                <div>
                    <div class="product-sostav font-europec">
                        {{item.more_ingredients}}
                    </div>
                </div>
            </div>

                    <div class="card-product-price">
                        <span class="font-europeext-bold card-product-price-num">{{item.price}}</span>
                        <span class="card-product-price-sym"> ₽</span>
                    </div>
        </div>


                <!-- <SpecToggler :options="item.spec" v-model="specid" v-if="item.spec.length" /> -->
                <SpecTogglerMulti :options="item.spec" v-model="specs" v-if="item.spec.length" style="margin:0 10px" />

            <div class="item-add-tocart">
                
                <div class="product-price-row">
                    <div>
                        {{totalFormatted}} 
                    </div>
                    <div class="card-product-cart">
                        <div class="card-product-inc" @click="onDec"  :class="{'bg-grey':!item.allowed}">
                            <i class="fas fa-minus"></i>
                        </div>
                        <div class="font-europec-bold card-product-cnt text-center">
                            {{cnt}}
                        </div>
                        <div class="card-product-inc" @click="onInc" :class="{'bg-grey':!item.allowed}">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>
                </div>
                <div class="btn-product-add2cart" @click="addToCart">Добавить в корзину</div>
            </div>

        <CartButton />
    </div>
</template>

<script>
// import SpecToggler from '../components/SpecToggler';
import SpecTogglerMulti from '../components/SpecTogglerMulti';
import CartButton from '/src/components/CartButton';

export default {
    components: {
        // SpecToggler,
        SpecTogglerMulti,
        CartButton
    },
    props: ['item'],
    data() {
        return {
            cnt: 1,
            specid: '',
            specs: [],
            cookTime: '',
            proccessing: false
        };
    },
    computed:{
        totalFormatted(){
            var sum = this.item.price * this.cnt;

            this.specs.forEach(spec => {
                sum += spec.price * spec.cnt * this.cnt;
            })

            return `ИТОГО: ${sum} ₽`;
        },
        qnt() {
            const cartItem = this.$store.state.cart.filter(item => {
                // item.item.id == this.item.id && item.spec.specid == this.specid
                if(item.item.id == this.item.id){
                    console.log('qnt', item.spec.specid, this.specid, item.spec.specid == this.specid)
                }
                return item.item.id == this.item.id && item.spec.specid == this.specid;
            })

            var cnt = 0;

            cartItem.forEach(cart => {
                cnt += cart.cnt;
            })

            return cnt>0?cnt:'0';
        },
        price(){
            var price = this.item.price;
            if(this.item.spec.length && this.specid){
                var spec = this.item.spec.filter(row => row.specid==this.specid);
                price += spec[0].price;
            }
            
            return price;
        },
        isFavorite(){
            const index = this.$store.state.favorites.findIndex(row=>row.id==this.item.id);
            if(index >= 0){
                return 2;
            }
            else
            {
                return 1;
            }
        },
    },
    created(){
        if(this.item.allowed){
            this.loadData()
        }
    },
    methods: {

            onDec(){
                if(this.cnt > 1){
                    this.cnt -= 1;
                }
            },
            onInc(){
                this.cnt += 1;
            },
            // addToCart(){
            //     console.log(this.item)
            //     if(this.item.spec.length != 0)
            //     {
            //         this.showModalSpec(this.item)
            //     }
            //     else
            //     {
            //         this.$store.commit('addToCart',{item:this.item,cnt:this.cnt})
            //     }
            // },

            addToCart(){
                if(!this.item.allowed) return;

                if(this.item.is_locked)
                {
                    this.checkLockCode().then(res => {
                        if(res){
                            this.addToCartUnlocked();
                        }
                    })
                }else{
                    this.addToCartUnlocked();
                }
            },
            addToCartUnlocked(){
                this.$store.commit('addToCart_v2',{
                    item: this.item,
                    cnt: this.cnt,
                    specs: this.specs
                })
            },

        loadData(){
            if(this.proccessing) return;
            this.proccessing = true;

            this.cookTime = '';

            this.request({
                action: 'zagruzkaLoadCookTime',
                fields: {
                    fs_uid: this.$store.state.restoran.fs_uid,
                    product_id: this.item.id,
                    orderType: this.$store.state.orderType,
                    delivery_datetime_props : this.$store.state.deliveryDateTimeProps
                }
            }).then(r => {
                if(r.cookTime){
                    this.cookTime = r.cookTime;
                }
                this.proccessing = false;
            }).catch(() => {
                this.proccessing = false;
            })
        },
        onStar(){
            this.$store.commit('toggleFavorite',this.item);
        },
        onTapBack(){
            this.$emit('close')
        },
        cntMinus(){
            this.$store.commit('cartCnt',{
                item: this.item,
                cnt: -1,
                spec: {
                    specid: this.specid
                }
            })
        },
        cntPlus(){
            if(!this.item.allowed) return;

            if(this.item.is_locked)
            {
                this.checkLockCode().then(res => {
                    if(res){
                        if(this.item.spec.length != 0)
                        {
                            var spec = this.item.spec.filter(row => row.specid == this.specid);
                            // this.showModalSpec(this.item)
                            this.$store.commit('addToCartSpec', {
                                item: this.item,
                                cnt: 1,
                                spec: spec[0]
                            })
                        }
                        else
                        {
                            this.$store.commit('addToCart',{
                                item: this.item,
                                cnt: 1
                            })
                        }
                    }
                })
            }
            else
            {
                if(this.item.spec.length != 0)
                {
                    var spec = this.item.spec.filter(row => row.specid == this.specid);
                    // this.showModalSpec(this.item)
                    this.$store.commit('addToCartSpec', {
                        item: this.item,
                        cnt: 1,
                        spec: spec[0]
                    })
                }
                else
                {
                    this.$store.commit('addToCart',{
                        item: this.item,
                        cnt: 1
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.page-product {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 0 0 70px 0;
    overflow: auto;
}

.product-photo {
    background-position: center;
    background-size: cover;
}

.product-photo:before {
    display: block;
    content: '';
    padding-top: 100%;
}

.product-btn-back {
    width:50px;
    height:50px;
    border-radius:25px;
    margin:10px;
    background-color:#eeeeee55;
    font-size:26px;
    color:#0071E5;
    position: fixed;
    top: 0;
    left: 0;
    line-height: 50px;
    text-align: center;
}

.product-btn-back i {
    line-height: 50px;
}

.product-info {
    margin:10px 10px 15px 10px;
}

.page-product-title
{
    color: #01315d;
    font-size:24px;
}

.page-product-weight
{
    color: #01315d;
    font-size:16;
}

.card-product-price
{
    background: #01315d;
    border-radius: 5px;
    vertical-align: center;
    padding: 2px 7px;
    margin: 0;
    align-self: start;
    white-space: nowrap;
}

.card-product-price-num
{
    color: #fff;
    font-size: 18px;


    margin: 5px 0 0 0;
    display: inline-block;
}

.card-product-price-sym
{
    color:#fff;
    font-size: 16px;
}

.card-product-inc
{
    width:30px;
    height:30px;
    border-radius: 15px;
    background-color: #ccc;
    border: 1px solid #c4c1c1;
}

.card-product-cnt
{
    width: 30px;
    text-align: center;
    color: #181818;
}

.text-rotated {
    /*transform: rotate(-45);*/
    color:#000;
    font-size: 24px;
}

.text-rotated Label
{
    transform: rotate(-45);
    text-align: center;
}

.product-sostav {
    color:#01315d;font-size:16px;margin:0 10px 10px 0;
    padding: 0 0 2px 0;
}

.product-sostav-hr {
    border-bottom: 2px solid #2f2d2e;
    margin: 0 10px 0 0;
}

.product-price-row {
    margin: 15px 10px 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-product-cart {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-product-inc {
    color: #fff;
    text-align: center;
}

.card-product-inc i {
    line-height: 30px;
}

.product-btn-star {
    width:50px;
    height:50px;
    border-radius:25px;
    margin:10px;
    background-color:#ffffff;
    position: fixed;
    top: 0;
    right: 0;
    text-align: center;
}

.product-btn-star i {
    line-height: 50px;
}

.cook-time {
    background: #fff;
    padding: 6px 10px;
    border-radius: 8px;
    display: inline-block;
    width: auto;
}

.cook-time-wrap {
    position: relative;
    z-index: +1;
    margin: -20px 0 20px 0px;
}

.cook-time-label {
    color:#000;
    font-size:12px; 
}

.cook-time-value {
    color:#219653;
    font-size:12px;
}


.item-add-tocart {
    background: #fff;
    padding: 10px;
}

.btn-product-add2cart {
    background: #0071E5;
    color: #fff;
    text-align: center;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 20px;
    margin: 10px 0 0 0;
}
</style>