import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import Home from '../views/Home.vue'
// import Main from '../pages/Main'
import Auth from '../pages/Auth'
import Thanks from '../pages/Thanks'
import OrderType from '../pages/OrderType'
import SelfFrom from '../pages/SelfFrom'
import MenuList from '../pages/MenuList'
import Cart from '../pages/Cart'
import Payment from '../pages/Payment'
import Feedback from '../pages/Feedback'
import Product from '../pages/Product'
import DeliveryTo from '../pages/DeliveryTo'
import OrderList from '../pages/OrderList'
import OrderDetail from '../pages/OrderDetail'
import Profile from '../pages/Profile'
import AddressList from '../pages/AddressList'
import AddressDetail from '../pages/AddressDetail'
import RateList from '../pages/RateList'
import AboutRestoran from '../pages/AboutRestoran'
import AboutDelivery from '../pages/AboutDelivery'
import AboutOferta from '../pages/AboutOferta'
import Liked from '../pages/Liked'
import DeliveryTime from '../pages/DeliveryTime'
import Predzakaz from '../pages/Predzakaz'
import Delayedzakaz from '../pages/Delayedzakaz'
import Booking from '../pages/Booking'
import BookingStep2 from '../pages/BookingStep2';
import BookingResult from '../pages/BookingResult';
import Kiosk from '../pages/Kiosk';
import Zagruzka from '../pages/Zagruzka';
import ZagruzkaRestList from '../pages/ZagruzkaRestList';
import ZagruzkaDetail from '../pages/ZagruzkaDetail';
import RestMap from '../pages/RestMap';

Vue.use(VueRouter)

const routes = [
  {
    path: '/rest_map',
    name: 'RestMap',
    component: RestMap
  },
  {
    path: '/zagruzka',
    name: 'Zagruzka',
    component: Zagruzka
  },
  {
    path: '/zagruzka/rest_list',
    name: 'ZagruzkaRestList',
    component: ZagruzkaRestList
  },
  {
    path: '/zagruzka/detail/:fs_uid/:time?',
    name: 'ZagruzkaDetail',
    component: ZagruzkaDetail
  },
  {
    path: '/kiosk/:id',
    name: 'Kiosk',
    component: Kiosk
  },
  {
    path: '/booking_result',
    name: 'BookingResult',
    component: BookingResult,
    props: true
  },
  {
    path: '/booking/step_2',
    name: 'BookingStep2',
    component: BookingStep2,
    props: true
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/predzakaz',
    name: 'Predzakaz',
    component: Predzakaz
  },
  {
    path: '/delayedzakaz',
    name: 'Delayedzakaz',
    component: Delayedzakaz
  },
  {
    path: '/delivery_time',
    name: 'DeliveryTime',
    component: DeliveryTime
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks
  },
  {
    path: '/liked',
    name: 'Liked',
    component: Liked
  },
  {
    path: '/about_oferta',
    name: 'AboutOferta',
    component: AboutOferta
  },
  {
    path: '/about_delivery',
    name: 'AboutDelivery',
    component: AboutDelivery
  },
  {
    path: '/about_restoran/:id',
    name: 'AboutRestoran',
    component: AboutRestoran
  },
  {
    path: '/rate_list',
    name: 'RateList',
    component: RateList
  },
  {
    path: '/address_list/:id',
    name: 'AddressDetail',
    component: AddressDetail
  },
  {
    path: '/address_list',
    name: 'AddressList',
    component: AddressList
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/order_detail/:id',
    name: 'OrderDetail',
    component: OrderDetail
  },
  {
    path: '/order_list',
    name: 'OrderList',
    component: OrderList
  },
  {
    path: '/delivery_to',
    name: 'DeliveryTo',
    component: DeliveryTo
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    props: true
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/menu_list',
    name: 'MenuList',
    component: MenuList
  },
  {
    path: '/menu/:restoran_id/:order_type',
    name: 'RestoranMenu',
    component: MenuList
  },
  {
    path: '/',
    name: 'OrderType',
    component: OrderType
  },
  {
    path: '/self_from',
    name: 'SelfFrom',
    component: SelfFrom
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  if(!store.state.loaded){
    store.commit('loadState', to.params)
  }

  if(to.name == 'Kiosk'){
    store.state.kiosk = true;
    store.state.restoran = {fs_uid: to.params.id};
    store.commit('logout');
    next('/');
  }

  if(to.name != 'Auth'){
    if(store.state.user.authorized || store.state.kiosk){
      if(to.name == 'Payment'){
        if(!to.params.orderNum){
          next('Thanks');
        }
      }
      if(to.name == 'BookingStep2'){
        if(!to.params.periodfrom){
          next('Booking');
        }
      }
      if(to.name == 'BookingResult'){
        if(!to.params.booking){
          next('/');
        }
      }
      next();
    }else{
      next('Auth');
    }
  }else{
    if(store.state.user.authorized){
      next('OrderType')
    }
  }
  next();
})

export default router
