<template>
    <div class="input-row" @click="onTap">
        <label>{{displayValue}}</label>
        <i class="far fa-calendar-alt"></i>
    </div>
</template>

<script>
export default {
    props: ['value'],
    created(){
        this.getDefaultValue();
    },
    computed: {
        displayValue(){
            return this.dateFormatFull(new Date(this.value));
        }
    },
    methods: {
        getDefaultValue(){
            if(!this.value){
                var date = new Date();
                var tm = date.getTime() - ((date.getHours()*60 + date.getMinutes())*60 + date.getSeconds())*1000 - date.getMilliseconds();
                this.$emit('input', tm);
            }
        },
        onTap(){
            var date = new Date();
            var wday= date.getDay();
            date = date.getTime() - ((date.getHours()*60 + date.getMinutes())*60 + date.getSeconds())*1000 - date.getMilliseconds();

            var items = [];
            if(wday == 0)
                wday = 7;

            var active = 0;
            
            for(var i=0; i<=7-wday; i++)
            {
                if(this.value == date+86400000*i){
                    active = i;
                }
                items.push({
                    name: this.dateFormatFull(new Date(date+86400000*i)),
                    value: date+86400000*i
                })
            }

            this.showDialog({
                items,
                active: active,
                title: 'Выберите дату'
            })
            .then(r => {
                if(r!==null){
                    this.$emit('input',items[r].value)
                }
            })
        }
    }
}
</script>

<style scoped>

</style>