<template>
    <v-dialog v-model="visibleC" content-class="bottom-dialog"
            transition="dialog-bottom-transition">
        <div class="modal-window">
            <div class="text-t1 text-center mb-5px">
                {{'Заказ №'+order.id+' / '+order.time}}
            </div>
            <div class="text-s1 text-center mb-15px">
                {{item.name}}
            </div>

            <div class="text-center" style="font-size:18px;color:#6C84A0;">
                Оцените вкус:
            </div>
            <StarRating class="mb-10px" v-model="rate_taste" align="center" />
            <div class="text-center" style="font-size:18px;color:#6C84A0;">
                Оцените свежесть:
            </div>
            <StarRating class="mb-10px" v-model="rate_fresh" align="center" />
            <div class="text-center" style="font-size:18px;color:#6C84A0;">
                Оцените скорость персонала:
            </div>
            <StarRating class="mb-10px" v-model="rate_speed" align="center" />
            <RatingTypeSelect v-if="!!types && rate_taste<5||rate_fresh<5||rate_speed<5" :types="types" v-model="type" key="RatingTypeSelect" />
            <!-- <textarea placeholder="Комментарий" class="input-text mb-15px" style="height:100px;padding:10px" v-model="comment"></textarea> -->
            <div class="btn btn-primary mb-15px" @click="onSubmit">Оставить оценку</div>
        </div>
        <v-overlay :value="proccessing">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
import StarRating from '../components/StarRating.vue';
import RatingTypeSelect from '../components/RatingTypeSelect.vue';
export default {
    props: ['visible','order','item'],
    components: {
        StarRating,
        RatingTypeSelect
    },
    data(){
        return {
            rate_taste: 5,
            rate_fresh: 5,
            rate_speed: 5,
            comment: '',
            photos: [],
            proccessing: false,
            types: null,
            type: null
        }
    },
    computed:{
        visibleC:{
            set(v){
                if(!v){
                    this.$emit('hide');
                }
            },
            get(){
                return this.visible
            }
        }
    },
    created(){
        this.loadTypes()
    },
    methods: {
        loadTypes(){
            if(this.types != null) return;
            
            this.request({
                action: 'rateGetTypes'
            }).then(r => {
                if(r && r.sections && r.items)
                {
                    this.types = r;
                }
            })
        },
        onSubmit(){
            if(this.proccessing) return;
            this.proccessing = true;
            fetch(this.$serverUrl + '/api/v1/rate/add',{
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    order_id: this.order.id,
                    user_id: this.$store.state.user.id,
                    token: this.$store.state.user.password,
                    item_id: this.item.id,
                    spec_id: this.item.spec.id,
                    taste: this.rate_taste,
                    fresh: this.rate_fresh,
                    speed: this.rate_speed,
                    comment: this.comment,
                    type: this.type,
                    //photos: this.photos
                })
            })
            .then(r => r.json())
            .then(result => {
                this.proccessing = false;
                if(result.error)
                {
                    console.log(result.error.text);
                    this.showDialog({ 
                        title: 'Ошибка',
                        message: 'Что-то пошло не так',
                        items: ['Ok']
                    })
                }
                else
                {
                    this.$emit('input',{
                        success: true,
                        rate_taste: this.rate_taste,
                        rate_fresh: this.rate_fresh,
                        rate_speed: this.rate_speed,
                        comment: this.comment,
                        photos: this.photos
                    });
                }
            }).catch(err => {
                this.proccessing = false;
                console.log(err)
                this.showDialog({
                    title: 'Ошибка',
                    message: 'Что-то пошло не так',
                    items: ['Ok']
                })
            })
        }
    }
}
</script>

<style scoped>
.modal-window {
    background: #fff;
    padding: 10px;
}

</style>