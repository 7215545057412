<template>
    <div>
        <div class="input-title">
            {{title}}
        </div>
        <div class="period-item-list">
            <div v-for="(item, index) in items" :key="index" class="period-item" @click="setItem(item)" :class="{checked:item.value==value}">
                <span>{{item.name}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['items','title','value'],
    methods: {
        setItem(item){
            this.$emit('input', item.value)
        }
    }
}
</script>

<style scoped>
.input-title
{
    color: #8A90AF;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 15px 5px;
}

.period-item-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 5px 10px;
}

.period-item {
    flex: 0 1 25%;
}

.period-item span {
    display: block;
    background: #F1F4FD;
    color: #33404D;
    margin: 0 5px 10px;
    height: 45px;
    border-radius: 45px;
    text-align: center;
    font-size: 14px;
    line-height: 45px;
}


.period-item.checked span
{
    background-color: #8A90AF;
    color:#ffffff;
}
</style>