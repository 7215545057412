var loadState = function(state, params){
    var res = localStorage.getItem('state');
    if(res){
        const resObj = JSON.parse(res);
        if(resObj.kiosk)
            state.kiosk = resObj.kiosk
        if(resObj.user)
            state.user = resObj.user;
        if(resObj.cart)
            state.cart = resObj.cart;
        if(resObj.address)
            state.address = resObj.address;
        if(resObj.favorites)
            state.favorites = resObj.favorites;
        if(resObj.favorite_restorans)
            state.favorite_restorans = resObj.favorite_restorans;
        if(resObj.checkout)
            state.checkout = resObj.checkout;
        if(resObj.orderType)
            state.orderType = resObj.orderType;
        if(resObj.restoran)
            state.restoran = resObj.restoran;
        if(resObj.lockCode)
            state.lockCode = resObj.lockCode;
    }

    if(params){
        if(params.restoran_id){
            if(params.restoran_id != state.restoran.id){
                // console.log('loadRestoran', params.restoran_id)
            }
        }
    }

    return true;
}

export {
    loadState
}