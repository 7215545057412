<template>
    <div class="page-feedback">
        <TopBar :showMenu="true" title="Обратная свзяь" />

        <div class="scrollview" style="padding:10px 10px 70px 10px">
            <input type="text" class="input-text mb-10px" placeholder="Имя" v-model="name" />
            <InputPhone v-model="phone" class="mb-10px" :input-text="true" />
            <RatingTypeSelect v-if="types" :types="types" v-model="type" key="RatingTypeSelect" />
            <textarea placeholder="Сообщение" class="input-text mb-10px" style="height:200px;padding:10px;" v-model="message" />
            
            <div class="btn btn-primary" @click="onSubmit">
                Отправить
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue'
import InputPhone from '../components/InputPhone';
import RatingTypeSelect from '../components/RatingTypeSelect';

export default {
    components: {TopBar, InputPhone, RatingTypeSelect},
    data() {
        return {
            message:'',
            name:this.$store.state.user.name,
            phone:this.$store.state.user.phone,
            types: null,
            type: null
        };
    },
    created(){
        this.loadTypes();
    },
    methods: {
        loadTypes(){
            if(this.types != null) return;
            
            this.request({
                action: 'rateGetTypes'
            }).then(r => {
                if(r && r.sections && r.items)
                {
                    this.types = r;
                }
            })
        },
        onSubmit(){
            if(this.message.length && this.phone.length == 11){
                fetch(this.$serverUrl + '/api/v1/feedback/add',{
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name:this.name, 
                        phone: this.phone, 
                        message:this.message,
                        restoran:this.$store.state.restoran.fs_uid,
                        type: this.type
                    })
                })
                .then(() => {
                    this.message = '';
                    this.showDialog({
                        title: 'Спасибо',
                        message: 'Ваше сообщение успешно отправлено!',
                        items: ['Ок']
                    })
                })
            }
            else
            {
                this.showDialog({
                    title: 'Внимание',
                    message: 'Не заполнены поля Телефон или Сообщение!',
                    items: ['Ок']
                })
            }
        }
    }
}
</script>