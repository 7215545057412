<template>
    <div class="page-liked">
        <TopBar :showMenu="true" title="Любимое" />
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
export default {
    components: {TopBar}
}
</script>