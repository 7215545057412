<template>
    <div class="page-thanks">
        <TopBar title="" :showMenu="true"/>
        <div class="thanks-bloc">
            <div class="thanks-icon">
                <i class="fas fa-utensils"></i>
            </div>
            <div class="thanks-text">
                Спасибо за заказ!<br>
                <br>
                <a href="/" class="btn button">На главную</a>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from '../components/TopBar.vue';
export default {
    components: {TopBar},

}
</script>

<style scoped lang="scss">
@import '/src/assets/styles/app.scss';
.page-thanks {
    @extend .bg-inversed;

    height: 100%;
}

.thanks-bloc {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.thanks-text {
    font-size: 30px;
    color: #fff;
}

.thanks-icon {
    font-size: 100px;
    color: #fff;
    margin: 0 0 10px 0;
}
</style>