<template>
    <div>
        <TopBar :showMenu="true" :title="restoran.name" />
        <div v-if="restoran.id" class="about-info scrollview">
            <div class="about-restoran-row">
                <img src="../assets/icon-address-blue.png"/>
                <div>
                    {{restoran.address}}
                </div>
            </div>
            <a class="about-restoran-row" v-if="restoran.phone" :href="'tel:'+restoran.phone">
                <img src="../assets/icon-phone-blue.png"/>
                <div>
                    {{restoran.phone}}
                </div>
            </a>
            <a class="about-restoran-row" v-if="restoran.email" :href="'mailto:'+restoran.email">
                <img src="../assets/icon-mail-blue.png"/>
                <div>
                    {{restoran.email}}
                </div>
            </a>
            <div class="about-restoran-row">
                <img src="../assets/icon-time-blue.png"/>
                <div style="white-space: pre-line;">
                    {{workTime}}
                </div>
            </div>
            <div class="about-restoran-row" v-if="restoran.seating">
                <img src="../assets/restaurant.png"/>
                <div style="white-space: pre-line;">
                    {{restoran.seating}}
                </div>
            </div>
            <yandex-map :coords="[restoran.loc[0],restoran.loc[1]]"
                :zoom="10" @map-was-initialized="onMapInit" style="height:300px;"></yandex-map>
        </div>
    </div>
</template>

<script>
const wDays = ['','Пн','Вт','Ср','Чт','Пт','Сб','Вс'];
import TopBar from '../components/TopBar.vue';
export default {
    components: {TopBar},
    data(){
        return {
            restoran: {name:''}
        }
    },
    created(){
        this.getRestorans().then(() => {
            var rest = this.$store.state.restorans.filter(item => item.id == this.$route.params.id)[0];

            if(rest.location.length)
            {
                rest.loc = JSON.parse(rest.location);
            }

            this.restoran = rest;
        })
    },
    computed: {
        workTime(){
            var time = '';
            
            this.restoran.shedule.forEach(item => {
                time += wDays[item.day];
                time += ': ' + item.from[0] + ':' + item.from[1] + ' - ' + item.to[0] + ':' + item.to[1] + '\n';
            })
            return time;
        }
    },
    methods: {
        onMapInit(map){
            this.restoran.deliveryZone.forEach(zone => {
                if(!zone.active) return;

                var jsonPolygon = JSON.parse(zone.polygon);

                var polygon = new window.ymaps.GeoObject({
                    geometry: {
                        type: "Polygon",
                        coordinates: jsonPolygon,
                    }
                }, {
                    fillColor: this.formatColor(zone.color),
                    strokeColor: this.formatColor(zone.color),
                    // opacity: 0.5,
                    strokeWidth: 2
                });

                map.geoObjects.add(polygon)
            });

            if(this.restoran.loc){
                map.geoObjects.add(new window.ymaps.Placemark(this.restoran.loc));
            }
        }
    }
}
</script>

<style scoped>
.about-info {
    margin: 10px 0 0 0;
}

.about-restoran-row {
    display: flex;
    flex-direction: row;
    margin: 0 20px 15px;
    align-items: self-start;
}

.about-restoran-row img {
    width: 20px;
    margin: 0 20px 0 0;
}
</style>