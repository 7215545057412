<template>
    <div class="category-title">
        <div>
            <img :src="item.img" class="category-title-img" />
        </div>
        <span class="font-europec-bold category-title-text">
            {{item.name}}
        </span>
    </div>
</template>

<script>
export default {
    props: ['item']
}
</script>

<style scoped>
.category-title
{
    background: linear-gradient(to bottom, #fa6a1c, #f42d0c);
    margin: 5px;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid #cecfd0;

    

        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.category-title-img
{
    height: 40px;
    vertical-align: center;
    margin:0 10px 0 0;
}

.category-title-text
{
    font-size: 21px;
    color: #fff;
    vertical-align: center;
}
</style>