<template>
    <div>
        <div class="mb-10px" >
            {{title}}
        </div>
        <div class="checkbox-item mb-10px" v-show="allowGotov" @click="onClickGotov">
            <img src="../assets/icon-time_gotov-0.png" v-show="value" />
            <img src="../assets/icon-time_gotov-1.png" v-show="!value" />
            <span>
                По готовности
            </span>
            <img src="../assets/icon-check-blue.png" v-show="!value" />
        </div>
        <div class="checkbox-item mb-10px" @click="onClickTime">
            <img src="../assets/icon-time_gotov-0.png" v-show="!value" />
            <img src="../assets/icon-time_gotov-1.png" v-show="value" />
            <span>
                Ко времени {{value}}
            </span>
            <img src="../assets/icon-check-blue.png" v-show="value" />
        </div>
        <v-dialog v-model="showTimeDialog">
            <div class="modal-select">
                <div class="modal-info">
                    <div class="modal-title">
                        Выберите время
                    </div>
                </div>
                <div class="modal-scroll-list">
                    <div v-for="(item, index) in variants" :key="index"
                        class="modal-scroll-item" @click="onSelect(item)"
                        :class="{active: item == selected}">
                        {{ item }}
                    </div>
                </div>
                <div class="button active last" @click="onSubmit">Ok</div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['value','title','allowGotov','today'],
    data(){
        return {
            showTimeDialog: false,
            selected: ''
        }
    },
    computed: {
        variants(){
            var result = [];

            var date = new Date();
            var min = date.getHours()*60+date.getMinutes();
            if(min < 9*60 || !this.today){
                min = 9*60;
            }

            for(var i = min+30; i<= 23*60; i+=15){
                var o = i%60;
                var m = (i-o)/60;
                if(o<10){
                    o = '0'+o;
                }
                if(m<10){
                    m = '0'+m;
                }
                result.push(m+':'+o);
            }

            return result;
        }
    },
    methods: {
        onClickTime(){
            this.showTimeDialog = true;
            // this.$emit('input', '12:00');
        },
        onClickGotov(){
            this.$emit('input', '');
        },
        onSelect(item){
            this.selected = item;
        },
        onSubmit(){
            this.$emit('input', this.selected)
            this.showTimeDialog = false;
        }
    }
}
</script>